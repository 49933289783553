import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Table, Dropdown, DropdownButton, Form, Col, Modal, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faGavel, faEye, faSearch, faFilter, faDownload, faEllipsisV, faCoins, faCalendar, faCalendarAlt, faMap } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import { get, post } from '../../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../../controllers/user.controller';
import { timeSince } from '../../../tools/tools';
import { triggerBase64Download } from 'common-base64-downloader-react';
import { params } from '../../../tools/constants';
import { GoogleMap, useLoadScript, Marker, Polyline } from "@react-google-maps/api"

export default function CourtFiles() {

    const PAGE_TITLE = "Hoja de ruta";
    const PAGE_ICON = faMap;
    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableRows, setTableRows] = React.useState([]);
    const [today, setToday] = React.useState('');

    const [roadmapIndicators, setRoadmapIndicators] = React.useState([]);

    const [mapCenter, setMapCenter] = React.useState({ latitude: 0, longitude: 0 });
    const [mapCoordinates, setMapCoordinates] = React.useState([]);
    const [mapLines, setMapLines] = React.useState([]);

    const [orderTypes, setOrderTypes] = React.useState(["Ordenar por cliente (A -> Z)", "Ordenar por cliente (Z -> A)", "Ordenar por encargado", "Ordenar por fecha"]);
    const [selectedOrderType, setSelectedOrderType] = React.useState("Ordenar por fecha");
    const [selectedOnlyMine, setSelectedOnlyMine] = React.useState(false);

    const [showFilterModal, setShowFilterModal] = React.useState(false);
    const handleCloseFilterModal = () => { setShowFilterModal(false); };
    const handleShowFilterModal = () => {
        setShowFilterModal(true);
    };

    const [showMapModal, setShowMapModal] = React.useState(false);
    const handleCloseMapModal = () => { setShowMapModal(false); };
    const handleShowMapModal = () => {
        setShowMapModal(true);
    };

    const handleOrderType = (event) => {
        setSelectedOrderType(event.target.value);
    };

    const handleOnlyMine = (event) => {
        setSelectedOnlyMine(event.target.checked)
    };

    const { isLoaded } = useLoadScript(
        {
            googleMapsApiKey: "AIzaSyB3jIu7YBXCLamtTDgIJ816A-_3IyZqp1E",
        }
    );

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `Plagamix - ${PAGE_TITLE}`;

        getRoadmap();
        getIndicators();
        getTodayDate();
        setTableColumns(['Carátula', 'Cliente', '', 'Estado', 'Encargado', 'Inicio', 'Inactividad', 'Acciones']);
    }, []);

    const getIndicators = async function () {
        const req = await get(`/appointments/roadmap/indicators`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setRoadmapIndicators(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getTodayDate = async function () {
        const req = await get(`/others/today`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setToday(res.today);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getRoadmap = async function () {
        const req = await get(`/appointments/roadmap`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            processAppointments(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
            console.log(res)
        }
    }

    const processAppointments = (data) => {
        let result = [];
        let coor = [];
        let lines = [];
        let pLat = 0;
        let pLng = 0;

        let users;

        for (let a of data) {
            users = [];

            for (let u of a.AppointmentEmployees) {
                users.push(`${u.User.name} ${u.User.surname}`)
            }

            result.push({
                type: a.AppointmentType.name,
                icon: a.AppointmentType.icon,
                day: (moment(a.dateStart).format('dddd DD MMM')).toString().toUpperCase().replace('.', ''),
                hour: `${moment(a.dateStart).format('HH:mm')} a ${moment(a.dateStart).add(a.duration, 'minute').format('HH:mm')}`,
                duration: a.duration,
                customerName: a.Customer.name,
                customerAddress: a.Customer.address,
                customerCity: a.Customer.city,
                status: <Badge bg='' className='me-2 content-table status' style={{ background: `${a.Status.color}` }} >{a.Status.name}</Badge>,
                statusMobile: <Badge bg='' className='' style={{ background: `${a.Status.color}` }} >{a.Status.name}</Badge>,
                users: users,
                action: <Link to={`/citas/detalles/?id=${a.id}`}><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye}></FontAwesomeIcon></Button></Link>
            })

            if (a.Customer.latitude) {
                coor.push({
                    latitude: parseFloat(a.Customer.latitude),
                    longitude: parseFloat(a.Customer.longitude)
                })
                pLat += parseFloat(a.Customer.latitude);
                pLng += parseFloat(a.Customer.longitude);
            }



        }

        setMapCoordinates(coor)
        setMapCenter({ latitude: pLat / coor.length, longitude: pLng / coor.length })
        for (let l = 0; l < coor.length; l++) {
            /* if (l != (coor.length - 1)) {
                 lines.push({ lat: coor[l].latitude, lng: coor[l].longitude })
             }*/

            lines.push({ lat: coor[l].latitude, lng: coor[l].longitude })
        }
        setMapLines(lines)

        setTableRows(result)
    }

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }} className='d-none d-sm-block'>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 45, fontWeight: 300 }}>{PAGE_TITLE}</p>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>

                    <div className='d-block d-sm-none'>
                        <Row style={{ paddingTop: 10, height: 200 }} >
                            <Col xs={8} className='align-center-vertically-v2'>
                                <p className='m-0' style={{ color: '#A0A0A0', fontSize: 35, fontWeight: 300 }}>{PAGE_TITLE}</p>
                            </Col>
                            <Col xs={4} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 70, color: "A0A0A0" }} />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>


                        <Row className='content-options content'>
                            <Col className='d-none d-sm-block align-center-vertically-v2 ps-4'>
                                {today}
                            </Col>
                            <Col className='d-none d-sm-block align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>
                                {tableRows.length > 0 ?
                                    <Button variant="success" onClick={handleShowMapModal}><FontAwesomeIcon icon={faMap} style={{ marginRight: 8 }} />Ver mapa</Button>
                                    :
                                    <div></div>
                                }
                            </Col>

                            <Col xs={8} className='d-block d-sm-none align-center-vertically-v2 ps-4'>
                                {today}
                            </Col>
                            <Col xs={4} className='d-block d-sm-none align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>
                                {tableRows.length > 0 ?
                                    <Button variant="success" onClick={handleShowMapModal}><FontAwesomeIcon icon={faMap} /></Button>
                                    :
                                    <div></div>
                                }
                            </Col>
                        </Row>

                        <Container>
                            <Row className='mb-3'>
                                {roadmapIndicators.map((ind, i) => (
                                    <Col md={3} sm={12} className='mt-3'>
                                        <Card className={`statusCard ${ind.color} administration`}>
                                            <Card.Body>
                                                <Card.Title>{ind.name}</Card.Title>
                                                <Card.Text>
                                                    <p className='m-0' >
                                                        <span style={{ fontSize: 28, fontStyle: 'bold' }}>{ind.value}</span>
                                                        <span className='ms-1' style={{ fontSize: 13, color: 'gray' }}>{ind.text}</span>
                                                    </p>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Container>

                        {tableRows.length > 0 ?
                            <Container className='pt-3'>
                                {tableRows.map((r, i) => (
                                    <Row className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                        <Col className='d-none d-sm-block' xs={1}>
                                            <img src={`${params().dataUri}${r.icon}`} width='70'></img>
                                        </Col>
                                        <Col className='d-block d-sm-none' xs={10}>
                                            <div>
                                            <p className='m-0'></p>
                                                <p className='m-0' style={{ fontSize: 12 }}>{r.statusMobile} {r.type}</p>
                                                <p className='m-0' style={{ fontSize: 12 }}>{r.day} - {r.hour}</p>
                                                <p className='m-0'>{r.customerName}</p>
                                                <p className='m-0' style={{ fontSize: 12 }}>{r.customerAddress} - {r.customerCity}</p>
                                            </div>
                                        </Col>
                                        <Col xs={4} className='d-none d-sm-block align-center-vertically-v2'>
                                            <div>
                                                <p className='m-0' style={{ fontSize: 12 }}>{r.type}</p>
                                                <p className='m-0'>{r.customerName}</p>
                                                <p className='m-0' style={{ fontSize: 12 }}>{r.customerAddress} - {r.customerCity}</p>
                                            </div>

                                        </Col>
                                        <Col xs={2} className='d-none d-sm-block align-center-vertically-v2 middle'>
                                            <div>
                                                <p className='m-0'>{r.day}</p>
                                                <p className='m-0'>{r.hour}</p>
                                            </div>
                                        </Col>
                                        <Col xs={2} className='d-none d-sm-block align-center-vertically-v2 middle'>
                                            <div className='middle'>
                                                <p className='m-0'>{r.status}</p>
                                            </div>
                                        </Col>
                                        <Col xs={2} className='d-none d-sm-block align-center-vertically-v2 middle'>
                                            <div>
                                                {r.users.map((u, i) => (
                                                    <p className='m-0'>{u}</p>
                                                ))}
                                            </div>
                                        </Col>
                                        <Col xs={2} md={1} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                            <div>
                                                {r.action}
                                            </div>
                                        </Col>
                                    </Row>
                                ))}
                            </Container>
                            :
                            <Container className='mt-5 mb-5'>

                                <Row style={{ height: 300, textAlign: "center" }}>
                                    <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faCalendarAlt}></FontAwesomeIcon></p>
                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se han encontrado citas</p>
                                </Row>
                            </Container>

                        }

                    </Card>
                </Row>
            </Container>

            <Modal show={showFilterModal} onHide={handleCloseFilterModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Filtros</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Orden</Form.Text>
                        <Form.Select className='cover content rows element' id="" value={selectedOrderType} onChange={(event) => handleOrderType(event)}>
                            {orderTypes.map((p, i) => (
                                <option key={i} id={i} value={p}>{p}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mt-3'>
                        <Form.Text>Mostrar</Form.Text>
                        <Form.Check
                            className='mt-1'
                            type="checkbox"
                            id=""
                            label="Solo mis citas"
                            checked={selectedOnlyMine}
                            onChange={(event) => handleOnlyMine(event)}
                        />
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseFilterModal}>
                        Cerrar
                    </Button>
                    <Button variant="primary">
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showMapModal} size='xl' onHide={handleCloseMapModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Mapa</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <GoogleMap
                        zoom={13}
                        center={{ lat: mapCenter.latitude, lng: mapCenter.longitude }}
                        mapContainerStyle={{ width: '100%', height: '450px' }}>
                        {mapCoordinates.map((m, i) => (
                            <Marker position={{ lat: m.latitude, lng: m.longitude }} />
                        ))}
                        <Polyline
                            path={mapLines}
                            strokeColor="#EA4336"
                            strokeOpacity={0.8}
                            strokeWeight={2} />

                    </GoogleMap>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseMapModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}