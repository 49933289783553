import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Table, Dropdown, Badge, Modal, Form, ListGroup, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faCoins, faEye, faIndustry, faPlusCircle, faSchool, faSearch, faAddressBook, faTrash, faPen, faUser, faCheck, faIdCard, faPhoneAlt, faEnvelope, faMapMarkerAlt, faInfoCircle, faAddressCard, faEllipsisV, faDownload, faFilePdf, faQuestion, faFile, faCalendar, faCalendarAlt, faShare } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import SubNavBarC from "../navbar/SubNavbarCustomers";
import SubNavBarD from "../navbar/SubNavbarDefendants";
import { get, post, del, put } from '../../../controllers/endpoints.controller';
import { getUserRole, getUserToken, logout } from '../../../controllers/user.controller';
import { pn } from '../../../tools/chains';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { timeSince, convert2Base64, dueWithBadge, downloadFile } from '../../../tools/tools';
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api"
import { params } from '../../../tools/constants';
import PDFViewer from 'mgr-pdf-viewer-react';


export default function BusinessDetails(props) {

    const PAGE_TITLE = "Cliente";
    const PAGE_ICON = faIndustry;
    const [provinces, setProvinces] = React.useState([]);
    const [selectedProvince, setSelectedProvince] = React.useState("");
    const [color, setColor] = React.useState("");


    const [name, setName] = React.useState("");

    const [bills, setBills] = React.useState([]);
    const [taxConditions, setTaxConditions] = React.useState([]);

    const [map, setMap] = React.useState(false);
    const [mapLat, setMapLat] = React.useState(null);
    const [mapLng, setMapLng] = React.useState(null);
    const [error, setError] = React.useState(<div></div>);
    const [currentAccount, setCurrentAccount] = React.useState([]);
    const [currentAccountCol, setCurrentAccountCol] = React.useState([]);
    const [balance, setBalance] = React.useState(<div></div>);

    const [appointments, setAppointments] = React.useState([]);
    const [certificates, setCertificates] = React.useState([]);

    const [businessContacts, setBusinessContacts] = React.useState([]);
    const [tableColumns, setTableColumns] = React.useState([]);
    const [showNewContact, setShowNewContact] = React.useState(false);
    const [showNewContactData, setShowNewContactData] = React.useState(false);
    const [showSureDelete, setShowSureDelete] = React.useState(false);
    const [showSureEditCustomer, setShowSureEditCustomer] = React.useState(false);
    const [newContactName, setNewContactName] = React.useState('');
    const [currentContact, setCurrentContact] = React.useState('');
    const [currentContactName, setCurrentContactName] = React.useState('');
    const [currentContactType, setCurrentContactType] = React.useState('Seleccionar...');
    const [newContactData, setNewContactData] = React.useState('');
    const [isEditing, setIsEditing] = React.useState(false);
    const [customerBalanceCol, setCustomerBalanceCol] = React.useState([]);
    const [customerBalance, setCustomerBalance] = React.useState([]);
    const [customerBalanceIndicators, setCustomerBalanceIndicators] = React.useState([]);

    const [showDeleteOperationModal, setShowDeleteOperationModal] = React.useState(false);
    const [deleteOperationId, setDeleteOperationId] = React.useState(0);
    const handleCloseDeleteOperationModal = () => { setShowDeleteOperationModal(false); };
    const handleShowDeleteOperationModal = (id) => {
        setDeleteOperationId(id)
        setShowDeleteOperationModal(true);
    };

    const [showDeleteCustomerBalanceModal, setShowDeleteCustomerBalanceModal] = React.useState(false);
    const [selectedCustomerBalanceId, setSelectedCustomerBalanceId] = React.useState("0");
    const handleCloseDeleteCustomerBalanceModal = () => { setShowDeleteCustomerBalanceModal(false); };
    const handleShowDeleteCustomerBalanceModal = (id) => {
        setSelectedCustomerBalanceId(id)
        setShowDeleteCustomerBalanceModal(true);
    }

    const [newOperationDate, setNewOperationDate] = React.useState(new Date());
    const [newOperationFile, setNewOperationFile] = React.useState(null);
    const [showNewOperationModal, setShowNewOperationModal] = React.useState(false);
    const handleCloseNewOperationModal = () => { setShowNewOperationModal(false); };
    const handleShowNewOperationModal = () => {
        setError()
        setShowNewOperationModal(true);
    };

    const handleIsEditing = () => {
        setIsEditing(!isEditing)
    };

    const handleCloseNewContact = () => setShowNewContact(false);
    const handleShowNewContact = () => setShowNewContact(true);
    const handleCloseNewContactData = () => setShowNewContactData(false);
    const handleShowNewContactData = (contactId) => {
        setCurrentContact(contactId);
        setShowNewContactData(true);
    };
    const handleCloseSureDelete = () => setShowSureDelete(false);
    const handleShowSureDelete = (name, id) => {
        setCurrentContactName(name);
        setCurrentContact(id);
        setShowSureDelete(true);
    };

    const [certificateId, setCertificateId] = React.useState(null);
    const [showDeleteCertificateModal, setShowDeleteCertificateModal] = React.useState(false);
    const handleCloseDeleteCertificateModal = () => setShowDeleteCertificateModal(false);
    const handleShowDeleteCertificateModal = (id) => {
        setCertificateId(id)
        setShowDeleteCertificateModal(true)
    };

    const [showDeleteBusinessModal, setShowDeleteBusinessModal] = React.useState(false);
    const handleCloseDeleteBusinessModal = () => { setShowDeleteBusinessModal(false); };
    const handleShowDeleteBusinessModal = () => {
        setShowDeleteBusinessModal(true);
    }
    const handleCloseSureEditCustomer = () => setShowSureEditCustomer(false);
    const handleShowSureEditCustomer = () => setShowSureEditCustomer(true);


    const [showInflationCalculator, setShowInflationCalculator] = React.useState(false);
    const handleCloseInflationCalculator = () => { setShowInflationCalculator(false); };
    const handleShowInflationCalculator = () => {
        setShowInflationCalculator(true);
    }

    const [showNewCertificateModal, setShowNewCertificateModal] = React.useState(false);
    const handleCloseNewCertificateModal = () => { setShowNewCertificateModal(false); };
    const handleShowNewCertificateModal = () => {
        setShowNewCertificateModal(true);
    };
    const [newCertificateDate, setNewCertificateDate] = React.useState(new Date());
    const [newCertificateDateExpiration, setNewCertificateDateExpiration] = React.useState(new Date());
    const [newCertificateFile, setNewCertificateFile] = React.useState(null);
    const handleNewCertificateFile = async (event) => {
        const file = event.target.files[0]
        setNewCertificateFile(file)
    }

    const handleNewCertificateDate = (event) => {
        setNewCertificateDate(event);
    };

    const handleNewCertificateDateExpiration = (event) => {
        setNewCertificateDateExpiration(event);
    };

    const handleNewContactName = (event) => {
        setNewContactName(event.target.value);
    };

    const handleNewContactData = (event) => {
        setNewContactData(event.target.value);
    };

    const handleNewOperationDate = (event) => {
        setNewOperationDate(event);
    };

    const handleNewOperationFile = async (event) => {
        const file = event.target.files[0]
        setNewOperationFile(file)
    }

    const [showViewFileModal, setShowViewFileModal] = React.useState(false);
    const [actualViewFile, setActualViewFile] = React.useState("");
    const handleCloseViewFileModal = () => { setShowViewFileModal(false); };
    const handleShowViewFileModal = () => {
        setShowViewFileModal(true);
    };

    const [selectedContact, setSelectedContact] = React.useState({ id: 0, name: "", data: [] });
    const [showEditContactModal, setShowEditContactModal] = React.useState(false);
    const handleCloseEditContactModal = () => { setShowEditContactModal(false); };
    const handleShowEditContactModal = (id, name, data) => {
        setSelectedContact({ id: id, name: name, data: data })
        setShowEditContactModal(true);
    };

    const { isLoaded } = useLoadScript(
        {
            googleMapsApiKey: "AIzaSyB3jIu7YBXCLamtTDgIJ816A-_3IyZqp1E",
        }
    );

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `Plagamix - ${PAGE_TITLE}`;


        setupTable();
        getData();
        getContacts();
        getCurrentAccount();
        getCurrentAccountBalance();

    }, []);

    const getData = async function () {
        await getProvinces();
        await getTaxConditions();
        await getCustomer();
        getBills();
    }

    const getProvinces = async function () {
        const req = await get('/others/provinces', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setProvinces(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getTaxConditions = async function () {
        const req = await get('/administration/taxConditions', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setTaxConditions(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getParamId = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('id')) {
            return params.get('id');
        } else {
            return -1;
        }
    }

    const getContacts = async function () {
        const req = await get(`/customers/contacts/byCustomerId/${getParamId()}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processContacts(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getBills = async function () {
        const req = await get(`/administration/bills/byCustomerId/${getParamId()}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processBills(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processBills = (data) => {
        let result = [];

        for (let d of data) {
            d.avatar = `${params().dataUri}${d.User.avatar}`;
            d.userName = `${d.User.name} ${d.User.surname}`;

            if (d.date) {
                d.day = (moment(d.date).format('DD MMM YYYY')).toString().toUpperCase().replace('.', '')
            } else {
                d.day = '';
            }

            if (d.error) {
                d.status = <Badge bg='danger'>{d.error}</Badge>
            } else {
                if (d.cae) {
                    d.status = <Badge bg='success'>Emitida</Badge>
                } else {
                    d.status = <Badge bg='warning'>Pendiente AFIP</Badge>
                }
            }

            if (d.File) {
                d.action = (
                    <Dropdown>
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                            <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => viewFile(d.fileId, d.File.name)}><FontAwesomeIcon icon={faEye} style={{ marginRight: 10, marginLeft: 2 }} />Ver archivo</Dropdown.Item>
                            <Dropdown.Item onClick={() => downloadFile(d.fileId, d.File.name)}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 10, marginLeft: 2 }} />Descargar archivo</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )
            } else {
                d.action = <div></div>;
            }

            result.push(d)
        }

        setBills(result)
    }

    const processContacts = function (data) {
        console.log(data)
        let result = [];
        let aux;
        let icon;
        let cdata = [];

        for (let c of data) {
            aux = null;

            for (let i = 0; i < c.ContactData.length; i++) {
                switch (c.ContactData[i].param) {
                    case "PHON":
                        icon = <FontAwesomeIcon icon={faPhoneAlt}></FontAwesomeIcon>;
                        break;
                    case "WHAT":
                        icon = <FontAwesomeIcon icon={faWhatsapp}></FontAwesomeIcon>;
                        break;
                    case "EMAI":
                        icon = <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>;
                        break;
                    case "ADDR":
                        icon = <FontAwesomeIcon icon={faMapMarkerAlt}></FontAwesomeIcon>;
                        break;
                    case "POSI":
                        icon = <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>;
                        break;
                    case "DNIN":
                        icon = <FontAwesomeIcon icon={faIdCard}></FontAwesomeIcon>;
                        break;
                    case "OTHE":
                        icon = <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>;
                        break;
                    default:
                        break;
                }
                cdata.push({
                    icon: icon,
                    data: c.ContactData[i].data,
                    id: c.ContactData[i].id
                })
            }
            c.data = cdata;
            c.action = (
                <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleShowNewContactData(c.id)}><FontAwesomeIcon icon={faAddressCard} style={{ marginRight: 10, marginLeft: 2 }} />Agregar datos</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleShowEditContactModal(c.id, c.name, c.data)}><FontAwesomeIcon icon={faPen} style={{ marginRight: 10, marginLeft: 2 }} />Modificar</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleShowSureDelete(c.name, c.id)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )

            result.push(c);
        }
        setBusinessContacts(result);
    }

    const getCustomer = async function () {
        const req = await get(`/customers/byId/${getParamId()}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processCustomer(res)
        } else if (req.status === 403) {
            logout();
        } else {
            console.log(res)
            alert(res.message);
        }
    }

    const processCustomer = async function (data) {
        console.log(data)
        setName(data.name);

        document.getElementById('textName').value = data.name;
        document.getElementById('textLegalName').value = data.legalName;
        document.getElementById('selectLegalIdType').value = data.legalIdType;
        document.getElementById('textLegalId').value = data.legalId;
        document.getElementById('selectTaxCondition').value = data.taxConditionId;
        document.getElementById('textAddress').value = data.address;
        document.getElementById('textCity').value = data.city;
        document.getElementById('selectProvince').value = data.province
        document.getElementById('textComment').value = data.comment
        document.getElementById('textPostalCode').value = data.postalCode

        if (data.latitude && data.longitude) {
            setMapLat(parseFloat(data.latitude))
            setMapLng(parseFloat(data.longitude))
            setMap(true)
        }

        let cert = [];
        for (let c of data.Certificates) {
            c.action = (
                <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {c.File ? <div>{c.File.extension == ".pdf" ? <Dropdown.Item onClick={() => viewFile(c.fileId, c.File.name)}><FontAwesomeIcon icon={faEye} style={{ marginRight: 10, marginLeft: 2 }} />Ver archivo</Dropdown.Item> : <div></div>}</div> : <div></div>}
                        {c.fileId ? <Dropdown.Item onClick={() => downloadFile(c.fileId, c.File.name)}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 10, marginLeft: 2 }} />Descargar archivo</Dropdown.Item> : <div></div>}
                        <Dropdown.Item onClick={() => handleShowDeleteCertificateModal(c.id)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )

            cert.push(c)
        }
        setCertificates(cert)
        processAppointments(data.Appointments)

    }

    const processAppointments = (data) => {
        let result = [];

        let users;

        for (let a of data) {
            users = [];

            for (let u of a.AppointmentEmployees) {
                users.push(`${u.User.name} ${u.User.surname}`)
            }

            result.push({
                type: a.AppointmentType.name,
                icon: a.AppointmentType.icon,
                day: (moment(a.dateStart).format('dddd DD MMM')).toString().toUpperCase().replace('.', ''),
                hour: `${moment(a.dateStart).format('hh:mm')} a ${moment(a.dateStart).add(a.duration, 'minute').format('hh:mm')}`,
                duration: a.duration,
                customerName: "",
                customerAddress: "",
                customerCity: "",
                status: <Badge bg='' className='me-2 content-table status' style={{ background: `${a.Status.color}` }} >{a.Status.name}</Badge>,
                statusMobile: <Badge bg='' className='' style={{ background: `${a.Status.color}` }} >{a.Status.name}</Badge>,
                users: users,
                action: <Link to={`/citas/detalles/?id=${a.id}`}><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye}></FontAwesomeIcon></Button></Link>
            })
        }

        setAppointments(result)
    }

    const editCustomer = async () => {
        let body = {
            name: document.getElementById('textName').value,
            legalName: document.getElementById('textLegalName').value,
            legalIdType: parseInt(document.getElementById('selectLegalIdType').value),
            legalId: document.getElementById('textLegalId').value,
            taxConditionId: parseInt(document.getElementById('selectTaxCondition').value),
            address: document.getElementById('textAddress').value,
            city: document.getElementById('textCity').value,
            province: document.getElementById('selectProvince').value,
            comment: document.getElementById('textComment').value
        }

        console.log(body)

        const req = await put(`/customers/byId/${getParamId()}`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const setupTable = function () {
        setCustomerBalanceCol(['Fecha', 'Carátula', 'A transferir', 'Transferido', ''])
    }

    const changeCurrentContactType = function (type) {
        setCurrentContactType(type);
        document.getElementById('currentContactType').innerHTML = type;
    }

    const createContactData = async function () {
        setShowNewContactData(false);

        let param;

        switch (currentContactType) {
            case "Teléfono":
                param = "PHON";
                break;
            case "WhatsApp":
                param = "WHAT";
                break;
            case "Correo electrónico":
                param = "EMAI";
                break;
            case "Dirección":
                param = "ADDR";
                break;
            case "Cargo":
                param = "POSI";
                break;
            case "DNI":
                param = "DNIN";
                break;
            case "Otros":
                param = "OTHE";
                break;
            default:
                break;
        }

        const contact = {
            contactId: currentContact,
            param: param,
            data: newContactData
        };

        const req = await post(`/customers/contacts/data`, getUserToken(), contact);
        const res = await req.json();

        if (req.status === 201) {
            getContacts();
        } else {
            alert(res.message);
        }
    }

    const deleteContact = async function () {
        handleCloseSureDelete();

        const body = {
            contactId: currentContact,
        }
        const req = await del(`/customers/contacts`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const deleteCustomer = async function () {
        const body = {
            customerId: getParamId(),
        }
        const req = await del(`/customers/`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            window.location.href = `/clientes`;
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const deleteCertificate = async function () {
        handleCloseDeleteCertificateModal();
        const body = {
            id: certificateId
        }
        const req = await del(`/appointments/certificates/`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            getData();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const deleteContactData = async function (id) {
        const body = {
            contactDataId: id,
        }
        console.log(body)
        const req = await del(`/customers/contacts/data`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            handleCloseEditContactModal();
            getContacts();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processCustomerBalanceById = (data) => {
        let result = [];
        for (let e of data) {
            e.action = (
                <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleShowDeleteCustomerBalanceModal(e.id, e.description)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )
            if (e.Proceeding) {
                e.name = e.Proceeding.Debt.name
            } else {
                e.name = '---'
            }

            result.push(e)
        }

        setCustomerBalance(result)
    }

    const deleteCustomerBalance = async () => {
        const req = await del(`/administration/balance`, getUserToken(), { id: selectedCustomerBalanceId });
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getCurrentAccountIndicators = async (businessId) => {
        const req = await get(`/administration/balance/${businessId}/indicators`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setCustomerBalanceIndicators(res)
        } else if (req.status === 503) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const createContact = async function () {
        setShowNewContact(false);

        const body = {
            customerId: getParamId(),
            name: newContactName,
        };

        const req = await post(`/customers/contacts/`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            getContacts();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getCurrentAccount = async function () {
        const req = await get(`/administration/currentAccount/byCustomerId/${getParamId()}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processCurrentAccount(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getCurrentAccountBalance = async function () {
        const req = await get(`/administration/balance/byCustomerId/${getParamId()}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processCurrentAccountBalance(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processCurrentAccountBalance = (data) => {
        setBalance(<Badge bg='' className='content-table status' style={{ backgroundColor: `${data.color}` }}>{data.value}</Badge>);
    }

    const processCurrentAccount = (data) => {
        let result = [];

        for (let d of data) {
            d.icon = d.File ? faFile : null;
            d.action = (<Dropdown>
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                    <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {d.File ? <div>{d.File.extension == ".pdf" ? <Dropdown.Item onClick={() => viewFile(d.fileId, d.File.name)}><FontAwesomeIcon icon={faEye} style={{ marginRight: 10, marginLeft: 2 }} />Ver archivo</Dropdown.Item> : <div></div>}</div> : <div></div>}
                    {d.File ? <Dropdown.Item onClick={() => downloadFile(d.fileId, d.File.name)}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 10, marginLeft: 2 }} />Descargar archivo</Dropdown.Item> : <div></div>}
                    <Dropdown.Item onClick={() => handleShowDeleteOperationModal(d.id)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>);
            if (d.PaymentCategory != null) {
                d.category = <Badge bg='' className='content-table status' style={{ backgroundColor: `${d.PaymentCategory.color}` }}>{d.PaymentCategory.name}</Badge>;
            } else {
                d.category = <div></div>
            }

            if (d.PaymentMethod != null) {
                d.method = <Badge bg='' className='content-table status' style={{ backgroundColor: `${d.PaymentMethod.color}` }}>{d.PaymentMethod.name}</Badge>;
            } else {
                d.method = <div></div>
            }


            result.push(d)
        }

        setCurrentAccount(result)

        setCurrentAccountCol(['Fecha', 'Descripción', 'Importe', 'Método', '', '']);
    }

    const createOperation = async function () {
        let file = null;
        let fileName = null;
        let fileExtension = null;
        let fromCustomerId = getParamId();

        if (newOperationFile != null) {
            file = await convert2Base64(newOperationFile);
            const aux = newOperationFile.name.split('.')
            fileExtension = `.${aux[aux.length - 1]}`
            fileName = newOperationFile.name;
        }

        let body = {
            date: newOperationDate,
            description: document.getElementById('newOperationDescription').value,
            debit: 0,
            currentAccount: parseFloat(document.getElementById('newOperationAmount').value.replace(',', '.')),
            credit: null,
            paymentMethodId: null,
            categoryId: null,
            toUserId: null,
            fromCustomerId: fromCustomerId,
            file: file,
            extension: fileExtension,
            executed: false
        }

        const req = await post(`/administration/currentAccount`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const calculateInflation = async function (status) {
        const body = {
            fromMonth: document.getElementById('selectFromMonth').value,
            fromYear: document.getElementById('selectFromYear').value,
            toMonth: document.getElementById('selectToMonth').value,
            toYear: document.getElementById('selectToYear').value,
            amount: document.getElementById('textInitialAmount').value
        }

        const req = await post('/administration/inflation', getUserToken(), body);
        const res = await req.json();

        if (req.status === 200) {
            document.getElementById('textResultAmount').value = res.result;
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const deleteOperation = async function () {
        const body = {
            operationId: deleteOperationId,
        }
        const req = await del(`/administration/currentAccount/`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const createCertificate = async function () {
        let file = null;
        let fileExtension = null;

        if (newCertificateFile != null) {
            file = await convert2Base64(newCertificateFile);
            const aux = newCertificateFile.name.split('.')
            fileExtension = `.${aux[aux.length - 1]}`
        }

        const body = {
            description: document.getElementById('textNewCertificateDescription').value,
            date: newCertificateDate,
            dateExpiration: newCertificateDateExpiration,
            appointmentId: null,
            customerId: getParamId(),
            file: file,
            extension: fileExtension
        }
        const req = await post(`/appointments/certificates`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            handleCloseNewCertificateModal()
            window.location.reload()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
            console.log(res)
        }
    }

    const viewFile = async (fileId, name) => {
        const req = await get(`/files/byIdNoHeader/${fileId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setActualViewFile(res.file)
            handleShowViewFileModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }


    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }} className='d-none d-sm-block'>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 25, fontWeight: 300 }}>{PAGE_TITLE}</p>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 45, fontWeight: 300 }}>{name}</p>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>

                    <div className='d-block d-sm-none'>
                        <Row style={{ paddingTop: 10, height: 200 }} >
                            <Col xs={8} className='align-center-vertically-v2'>
                                <p className='m-0' style={{ color: '#A0A0A0', fontSize: 20, fontWeight: 300 }}>{PAGE_TITLE}</p>
                                <p className='m-0' style={{ color: '#A0A0A0', fontSize: 35, fontWeight: 300 }}>{name}</p>
                            </Col>
                            <Col xs={4} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 70, color: "A0A0A0" }} />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <Row className='content-options content'>
                            <Col className='d-none d-sm-block align-center-vertically-v2 ps-4'>
                                <Link to={'/clientes'} className='link-primary'>
                                    <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver a Clientes
                                </Link>
                            </Col>
                            <Col className='d-none d-sm-block align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>
                                <Button variant="warning" onClick={handleIsEditing}><FontAwesomeIcon icon={faPen} style={{ marginRight: 8 }} />Modificar</Button>
                                <Button className='ms-2' variant="danger" onClick={handleShowDeleteBusinessModal}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 8 }} />Eliminar</Button>
                            </Col>

                            <Col xs={8} className='d-block d-sm-none align-center-vertically-v2 ps-4'>
                                <Link to={'/clientes'} className='link-primary'>
                                    <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver a Clientes
                                </Link>
                            </Col>
                            <Col xs={4} className='d-block d-sm-none align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>
                                <Button variant="warning" onClick={handleIsEditing}><FontAwesomeIcon icon={faPen} /></Button>
                                <Button className='ms-2' variant="danger" onClick={handleShowDeleteBusinessModal}><FontAwesomeIcon icon={faTrash} /></Button>
                            </Col>
                        </Row>

                        {isLoaded ?
                            <div>
                                {map ?
                                    <GoogleMap
                                        zoom={17}
                                        center={{ lat: mapLat, lng: mapLng }}
                                        mapContainerStyle={{ width: '100%', height: '300px' }}>

                                        <Marker position={{ lat: mapLat, lng: mapLng }} />

                                    </GoogleMap>
                                    :
                                    <div></div>
                                }
                            </div>
                            :
                            <div></div>
                        }
                        <Container className='mt-4 mb-4'>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Row className='mt-2'>
                                        <Col md={4} className='d-none d-sm-block align-center-vertically-v2'>
                                            Nombre
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Text className='d-block d-sm-none'>Nombre</Form.Text>
                                                <Form.Control type="text" id="textName" readOnly={!isEditing} placeholder="" />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mt-2'>
                                        <Col md={4} className='d-none d-sm-block align-center-vertically-v2'>
                                            Razón social
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Text className='d-block d-sm-none'>Razón social</Form.Text>
                                                <Form.Control type="text" id="textLegalName" readOnly={!isEditing} placeholder="" />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mt-2'>
                                        <Col md={4} className='d-none d-sm-block align-center-vertically-v2'>
                                            Tipo de documento
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Text className='d-block d-sm-none'>Tipo de documento</Form.Text>
                                                <Form.Select className='cover content rows element' disabled={!isEditing} id="selectLegalIdType">
                                                    <option value="0">Seleccionar...</option>
                                                    <option value={80}>CUIT</option>
                                                    <option value={86}>CUIL</option>
                                                    <option value={96}>DNI</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mt-2'>
                                        <Col md={4} className='d-none d-sm-block align-center-vertically-v2'>
                                            CUIT / CUIL / DNI
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Text className='d-block d-sm-none'>CUIT / CUIL / DNI</Form.Text>
                                                <Form.Control type="text" id="textLegalId" readOnly={!isEditing} placeholder="" />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mt-2'>
                                        <Col md={4} className='d-none d-sm-block align-center-vertically-v2'>
                                            Condición IVA
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Text className='d-block d-sm-none'>Condición IVA</Form.Text>
                                                <Form.Select className='cover content rows element' disabled={!isEditing} id="selectTaxCondition">
                                                    <option value="0">Seleccionar...</option>
                                                    {taxConditions.map((p, i) => (
                                                        <option key={i} id={i} value={p.id}>{p.name}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mt-2'>
                                        <Col md={4} className='d-none d-sm-block align-center-vertically-v2'>
                                            Dirección {map ? <Badge bg='success'><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></Badge> : <Badge bg='danger'><FontAwesomeIcon icon={faQuestion}></FontAwesomeIcon></Badge>}
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Text className='d-block d-sm-none'>Dirección {map ? <Badge bg='success'><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></Badge> : <Badge bg='danger'><FontAwesomeIcon icon={faQuestion}></FontAwesomeIcon></Badge>}</Form.Text>
                                                <Form.Control type="text" id="textAddress" readOnly={!isEditing} placeholder="" />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mt-2'>
                                        <Col md={4} className='d-none d-sm-block align-center-vertically-v2'>
                                            Código postal
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Text className='d-block d-sm-none'>Código postal</Form.Text>
                                                <Form.Control type="text" id="textPostalCode" readOnly placeholder="" />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mt-2'>
                                        <Col md={4} className='d-none d-sm-block align-center-vertically-v2'>
                                            Localidad
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Text className='d-block d-sm-none'>Localidad</Form.Text>
                                                <Form.Control type="text" id="textCity" readOnly={!isEditing} placeholder="" />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mt-2'>
                                        <Col md={4} className='d-none d-sm-block align-center-vertically-v2'>
                                            Provincia
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Text className='d-block d-sm-none'>Provincia</Form.Text>
                                                <Form.Select className='cover content rows element' disabled={!isEditing} id="selectProvince">
                                                    <option value="0">Seleccionar...</option>
                                                    {provinces.map((p, i) => (
                                                        <option key={i} id={i} value={p.name}>{p.name}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col xs={12} md={6}>
                                    <Row className='mt-2'>
                                        <Col md={3} className='d-none d-sm-block align-center-vertically-v2'>
                                            Comentarios
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Text className='d-block d-sm-none'>Comentarios</Form.Text>
                                                <Form.Control as="textarea" id="textComment" readOnly={!isEditing} rows={15} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            {isEditing ?
                                <div className='mt-5' style={{ textAlign: 'right' }}>
                                    <Button variant="success" onClick={editCustomer}><FontAwesomeIcon icon={faCheck} style={{ marginRight: 8 }} />Confirmar cambios</Button>
                                </div>

                                :
                                <div></div>
                            }
                        </Container>
                    </Card>
                </Row>

            </Container>

            <Container>
                <Row>
                    <Card className='cards content mb-5'>
                        <Row className='content-options content'>
                            <Col className='d-none d-sm-block align-center-vertically-v2 ps-4'>
                                Contactos
                            </Col>
                            <Col className='d-none d-sm-block align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>
                                <Button variant="success" onClick={handleShowNewContact}><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nuevo contacto</Button>
                            </Col>

                            <Col xs={8} className='d-block d-sm-none align-center-vertically-v2 ps-4'>
                                Contactos
                            </Col>
                            <Col xs={4} className='d-block d-sm-none align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>
                                <Button variant="success" onClick={handleShowNewContact}><FontAwesomeIcon icon={faPlusCircle} /></Button>
                            </Col>
                        </Row>

                        <Container className='mt-3 mb-3'>
                            {businessContacts.length > 0 ?
                                <div>
                                    {businessContacts.map((r, i) => (
                                        <Row className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                            <Col className='align-center-vertically-v2' xs={3}>
                                                {r.name}
                                            </Col>
                                            <Col className='' xs={7} md={8}>
                                                {r.data.map((d, i) => (
                                                    <Badge bg='secondary' className='content-table status me-2'><span className='me-1'>{d.icon}</span> {d.data}</Badge>
                                                ))}

                                            </Col>
                                            <Col className='align-center-vertically-v2' style={{ textAlign: 'right' }} xs={2} md={1}>
                                                {r.action}
                                            </Col>

                                        </Row>
                                    ))}
                                </div>
                                :
                                <Row style={{ height: 300, textAlign: "center" }}>
                                    <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faAddressBook}></FontAwesomeIcon></p>
                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No hay contactos</p>
                                </Row>
                            }

                        </Container>

                    </Card>
                </Row>

                {getUserRole() == "Administrador" ?

                    <Row>
                        <Card className='cards content mb-5'>


                            <Row className='content-options content'>
                                <Col className='d-none d-sm-block align-center-vertically-v2 ps-4'>
                                    <span>Cuenta corriente</span><span className='ms-2'>{balance}</span>
                                </Col>
                                <Col className='d-none d-sm-block align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>
                                    <Button variant="success" onClick={handleShowInflationCalculator}><FontAwesomeIcon icon={faCoins} style={{ marginRight: 8 }} />Calcular inflación</Button>
                                    <Button variant="danger" onClick={handleShowNewOperationModal} className='ms-2'><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nueva deuda</Button>
                                </Col>

                                <Col xs={8} className='d-block d-sm-none align-center-vertically-v2 ps-4'>
                                    <span>Cuenta corriente</span><span className='ms-2'>{balance}</span>
                                </Col>
                                <Col xs={4} className='d-block d-sm-none align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>
                                    <Button variant="danger" onClick={handleShowNewOperationModal} className='ms-2'><FontAwesomeIcon icon={faPlusCircle} /></Button>
                                </Col>
                            </Row>

                            {
                                currentAccount.length > 0 ?
                                    <div>
                                        <table striped hover className='mb-3 w-100'>
                                            <thead>
                                                <tr>
                                                    {currentAccountCol.map((c, i) => (
                                                        <th key={i} className='content-table head'>{c}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentAccount.map((r, i) => (
                                                    <tr key={i} >
                                                        <td className='content-table rows middle align-middle' style={{ width: 150 }}>{<Moment format="DD/MM/YYYY">{r.date}</Moment>}</td>
                                                        <td className='content-table rows align-middle'>{r.description}</td>
                                                        <td className='content-table rows middle align-middle' style={{ width: 190 }}>{r.debit != null ? `$${pn(r.currentAccount)}` : "---"}</td>
                                                        <td className='content-table rows middle align-middle' style={{ width: 250 }}>{r.method}</td>
                                                        <td className='content-table rows middle align-middle' style={{ width: 40 }}>{r.icon != null ? <FontAwesomeIcon style={{ fontSize: 30 }} icon={r.icon}></FontAwesomeIcon> : ""}</td>
                                                        <td className='content-table rows middle align-middle' style={{ width: 100 }}>{r.action}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                    :

                                    <Row style={{ height: 300, textAlign: "center" }}>
                                        <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faCoins}></FontAwesomeIcon></p>
                                        <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No hay operaciones</p>
                                    </Row>
                            }


                        </Card>
                    </Row>

                    :

                    <div></div>

                }

                <Row>
                    <Card className='cards content mb-5'>

                        <Row className='content-options content'>
                            <Col className='d-none d-sm-block align-center-vertically-v2 ps-4'>
                                Citas
                            </Col>

                            <Col xs={8} className='d-block d-sm-none align-center-vertically-v2 ps-4'>
                                Citas
                            </Col>
                        </Row>

                        <Container className='mt-3 mb-3'>



                            {appointments.length > 0 ?
                                <div>
                                    {appointments.map((r, i) => (
                                        <Row className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                            <Col className='d-none d-sm-block' xs={1}>
                                                <img src={`${params().dataUri}${r.icon}`} width='70'></img>
                                            </Col>
                                            <Col className='d-block d-sm-none' xs={10}>
                                                <div>
                                                    <p className='m-0'></p>
                                                    <p className='m-0' style={{ fontSize: 12 }}>{r.statusMobile}</p>
                                                    <p className='m-0'>{r.type}</p>
                                                    <p className='m-0' style={{ fontSize: 12 }}>{r.day} - {r.hour}</p>
                                                    <p className='m-0'>{r.customerName}</p>
                                                </div>
                                            </Col>
                                            <Col xs={4} className='d-none d-sm-block align-center-vertically-v2'>
                                                <div>
                                                    <p className='m-0'>{r.type}</p>
                                                </div>

                                            </Col>
                                            <Col xs={2} className='d-none d-sm-block align-center-vertically-v2 middle'>
                                                <div>
                                                    <p className='m-0'>{r.day}</p>
                                                    <p className='m-0'>{r.hour}</p>
                                                </div>
                                            </Col>
                                            <Col xs={2} className='d-none d-sm-block align-center-vertically-v2 middle'>
                                                <div className='middle'>
                                                    <p className='m-0'>{r.status}</p>
                                                </div>
                                            </Col>
                                            <Col xs={2} className='d-none d-sm-block align-center-vertically-v2 middle'>
                                                <div>
                                                    {r.users.map((u, i) => (
                                                        <p className='m-0'>{u}</p>
                                                    ))}
                                                </div>
                                            </Col>
                                            <Col xs={2} md={1} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                                <div>
                                                    {r.action}
                                                </div>
                                            </Col>
                                        </Row>
                                    ))}
                                </div>
                                :
                                <Container className='mt-5 mb-5'>

                                    <Row style={{ height: 300, textAlign: "center" }}>
                                        <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faCalendarAlt}></FontAwesomeIcon></p>
                                        <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se han encontrado citas</p>
                                    </Row>
                                </Container>

                            }



                        </Container>


                    </Card>
                </Row>


                <Row>
                    <Card className='cards content mb-5'>


                        <Row className='content-options content'>
                            <Col className='d-none d-sm-block align-center-vertically-v2 ps-4'>
                                Obleas
                            </Col>
                            <Col className='d-none d-sm-block align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>
                                <Button variant="success" onClick={handleShowNewCertificateModal}><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nueva oblea</Button>
                            </Col>

                            <Col xs={8} className='d-block d-sm-none align-center-vertically-v2 ps-4'>
                                Obleas
                            </Col>
                            <Col xs={4} className='d-block d-sm-none align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>
                                <Button variant="success" onClick={handleShowNewCertificateModal}><FontAwesomeIcon icon={faPlusCircle} /></Button>
                            </Col>
                        </Row>

                        <Container className='mt-3 mb-3'>
                            {certificates.length > 0 ?
                                <div>
                                    {certificates.map((c, i) => (
                                        <Row className='p-2 mt-1 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                            <Col xs={1} className='d-none d-sm-block align-center-vertically-v2'>
                                                <FontAwesomeIcon icon={faFile} style={{ fontSize: 30 }}></FontAwesomeIcon>
                                            </Col>
                                            <Col xs={6} className='d-none d-sm-block align-center-vertically-v2'>
                                                <div>
                                                    <p className='m-0'>{c.description}</p>
                                                    <p className='m-0' style={{ fontSize: 12 }}>{moment(c.date).format("DD MMM YYYY").replace(".", "").toUpperCase()} a {moment(c.dateExpiration).format("DD MMM YYYY").replace(".", "").toUpperCase()}</p>
                                                </div>
                                            </Col>

                                            <Col xs={9} className='d-block d-sm-none align-center-vertically-v2'>
                                                <div>
                                                    <p className='m-0' style={{ fontSize: 12 }}>{dueWithBadge(c.dateExpiration, true)}</p>
                                                    <p className='m-0'>{c.description}</p>
                                                    <p className='m-0' style={{ fontSize: 12 }}>{moment(c.date).format("DD MMM YYYY").replace(".", "").toUpperCase()} a {moment(c.dateExpiration).format("DD MMM YYYY").replace(".", "").toUpperCase()}</p>
                                                </div>
                                            </Col>
                                            <Col xs={2} className='d-none d-sm-block align-center-vertically-v2'>
                                                <div style={{ textAlign: 'center' }}>
                                                    <p className='m-0'>{moment(c.dateExpiration).format("DD MMM YYYY").replace(".", "").toUpperCase()}</p>
                                                </div>

                                            </Col>

                                            <Col xs={2} className='d-none d-sm-block align-center-vertically-v2'>
                                                <div style={{ textAlign: 'center' }}>
                                                    <p className='m-0'>{dueWithBadge(c.dateExpiration)}</p>
                                                </div>

                                            </Col>
                                            <Col xs={3} md={1} className='align-center-vertically middle-v2'>
                                                {c.action}
                                            </Col>
                                        </Row>
                                    ))}
                                </div>
                                :
                                <div>
                                    <Row style={{ height: 300, textAlign: "center" }}>
                                        <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faFile}></FontAwesomeIcon></p>
                                        <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se han cargado obleas</p>
                                    </Row>
                                </div>
                            }



                        </Container>


                    </Card>
                </Row>

                <Row>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    Facturas emitidas (últimas 10)
                                </td>
                                <td className='content-options rows right'>
                                </td>
                            </tr>
                        </table>

                        <Container className='pt-3'>
                            {bills.map((b, i) => (
                                <Row className={b.File ? `p-2 mb-3` : `ps-2 pe-2 pt-3 pb-3 mb-3`} style={{ background: '#F0F0F0', borderRadius: 8 }}>

                                    <Col xs={3} className='align-center-vertically'>
                                        <div>
                                            <p className='m-0'><span className='me-2'>{b.status}</span>{b.completeNumber}</p>
                                        </div>

                                    </Col>
                                    <Col xs={2} className='align-center-vertically middle' style={{ textAlign: 'center' }}>
                                        <div>
                                            <p className='m-0'>{b.day}</p>
                                        </div>
                                    </Col>
                                    <Col xs={4} className='align-center-vertically middle'>
                                        <div>

                                        </div>
                                    </Col>


                                    <Col xs={2} className='align-center-vertically middle'>
                                        <div>
                                            <p className='m-0'>${pn(b.totalAmount)}</p>
                                        </div>
                                    </Col>


                                    <Col xs={1} className='align-center-vertically' style={{ textAlign: 'right' }}>
                                        <div>
                                            {b.action}
                                        </div>
                                    </Col>
                                </Row>

                            ))}
                        </Container>



                    </Card>
                </Row>



                <Modal show={showDeleteBusinessModal} onHide={handleCloseDeleteBusinessModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        ¿Desea eliminar al cliente?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDeleteBusinessModal}>
                            Cerrar
                        </Button>
                        <Button variant="danger" onClick={deleteCustomer}>
                            Eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showNewContact} centered onHide={handleCloseNewContact}>
                    <Modal.Header closeButtonNewContact>
                        <Modal.Title>Nuevo contacto</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Nombre y apellido</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Juan Pérez"
                                        autoFocus
                                        onChange={(event) => handleNewContactName(event)}
                                    />
                                </Form.Group>
                            </Row>


                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseNewContact}>
                            Cancelar
                        </Button>
                        <Button variant="success" onClick={createContact}>
                            Confirmar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showNewContactData} centered onHide={handleCloseNewContactData}>
                    <Modal.Header closeButtonNewContact>
                        <Modal.Title>Añadir datos de contacto</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Row>
                                        <Col xs={5}>
                                            <Dropdown className='w-100'>
                                                <Dropdown.Toggle variant="primary" className='w-100' id="dropdown-basic">
                                                    <span id='currentContactType'>Seleccionar...</span>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className='w-100'>
                                                    <Dropdown.Item onClick={() => changeCurrentContactType("Teléfono")}>Teléfono</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => changeCurrentContactType("WhatsApp")}>WhatsApp</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => changeCurrentContactType("Correo electrónico")}>Correo electrónico</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => changeCurrentContactType("Dirección")}>Dirección</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => changeCurrentContactType("DNI")}>DNI</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => changeCurrentContactType("Cargo")}>Cargo</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => changeCurrentContactType("Otro")}>Otro</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Col>

                                        <Col>
                                            <Form.Control
                                                type="text"
                                                placeholder=""
                                                autoFocus
                                                onChange={(event) => handleNewContactData(event)}
                                            />
                                        </Col>
                                    </Row>

                                </Form.Group>
                            </Row>


                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseNewContactData}>
                            Cancelar
                        </Button>
                        <Button variant="danger" onClick={createContactData}>
                            Confirmar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showSureDelete} centered onHide={handleCloseSureDelete}>
                    <Modal.Header closeButtonSureDelete>
                        <Modal.Title>Eliminar contacto</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        ¿Está seguro de eliminar al contacto {currentContactName}?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseSureDelete}>
                            Cancelar
                        </Button>
                        <Button variant="danger" onClick={deleteContact}>
                            Confirmar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showDeleteCustomerBalanceModal} onHide={handleCloseDeleteCustomerBalanceModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        ¿Desea eliminar la operación? Tené en cuenta que no se eliminará el honorario de la cuenta corriente
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDeleteCustomerBalanceModal}>
                            Cerrar
                        </Button>
                        <Button variant="danger" onClick={deleteCustomerBalance}>
                            Eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showInflationCalculator} centered onHide={handleCloseInflationCalculator}>
                    <Modal.Header closeButtonNewContact>
                        <Modal.Title>Calculadora de inflación</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Desde mes</Form.Label>
                                        <Form.Select className='cover content rows element' id="selectFromMonth">
                                            <option value="0">Seleccionar...</option>
                                            <option value={1}>Enero</option>
                                            <option value={2}>Febrero</option>
                                            <option value={3}>Marzo</option>
                                            <option value={4}>Abril</option>
                                            <option value={5}>Mayo</option>
                                            <option value={6}>Junio</option>
                                            <option value={7}>Julio</option>
                                            <option value={8}>Agosto</option>
                                            <option value={9}>Septiembre</option>
                                            <option value={10}>Octubre</option>
                                            <option value={11}>Noviembre</option>
                                            <option value={12}>Diciembre</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Desde año</Form.Label>
                                        <Form.Select className='cover content rows element' id="selectFromYear">
                                            <option value="0">Seleccionar...</option>
                                            <option value={2021}>2021</option>
                                            <option value={2022}>2022</option>
                                            <option value={2023}>2023</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Hasta mes</Form.Label>
                                        <Form.Select className='cover content rows element' id="selectToMonth">
                                            <option value="0">Seleccionar...</option>
                                            <option value={1}>Enero</option>
                                            <option value={2}>Febrero</option>
                                            <option value={3}>Marzo</option>
                                            <option value={4}>Abril</option>
                                            <option value={5}>Mayo</option>
                                            <option value={6}>Junio</option>
                                            <option value={7}>Julio</option>
                                            <option value={8}>Agosto</option>
                                            <option value={9}>Septiembre</option>
                                            <option value={10}>Octubre</option>
                                            <option value={11}>Noviembre</option>
                                            <option value={12}>Diciembre</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Hasta año</Form.Label>
                                        <Form.Select className='cover content rows element' id="selectToYear">
                                            <option value="0">Seleccionar...</option>
                                            <option value={2021}>2021</option>
                                            <option value={2022}>2022</option>
                                            <option value={2023}>2023</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Form.Group className='mt-2'>
                                <Form.Text>Monto</Form.Text>
                                <Form.Control type="text" id="textInitialAmount" placeholder="" />
                            </Form.Group>

                            <Form.Group className='mt-2'>
                                <Form.Text>Resultado</Form.Text>
                                <Form.Control type="text" id="textResultAmount" placeholder="" />
                            </Form.Group>


                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseInflationCalculator}>
                            Cancelar
                        </Button>

                        <Button variant="primary" onClick={calculateInflation}>
                            Calcular
                        </Button>

                    </Modal.Footer>
                </Modal>

                <Modal show={showNewOperationModal} onHide={handleCloseNewOperationModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Nueva deuda</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className=''>
                            <Form.Text>Monto</Form.Text>
                            <Form.Control type="text" id="newOperationAmount" placeholder="" />
                        </Form.Group>
                        <Form.Group className='mt-2'>
                            <Form.Text>Fecha</Form.Text>
                            <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newOperationDate} onChange={(d) => handleNewOperationDate(d)} />
                        </Form.Group>

                        <Form.Group className='mt-2'>
                            <Form.Text>Descripción</Form.Text>
                            <Form.Control type="text" id="newOperationDescription" placeholder="" />
                        </Form.Group>

                        <Form.Group className="mt-2">
                            <Form.Text>Archivo</Form.Text>
                            <Form.Control accept='.pdf, .docx, .xlsx, .png, .jpg, .jpeg, .zip' onChange={(event) => handleNewOperationFile(event)} type="file" />
                        </Form.Group>

                        {error}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseNewOperationModal}>
                            Cerrar
                        </Button>

                        <Button variant="danger" onClick={createOperation}>Crear</Button>


                    </Modal.Footer>
                </Modal>

                <Modal show={showDeleteOperationModal} onHide={handleCloseDeleteOperationModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        ¿Desea eliminar la operación?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDeleteOperationModal}>
                            Cerrar
                        </Button>
                        <Button variant="danger" onClick={deleteOperation}>
                            Eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>

            <Modal show={showNewCertificateModal} onHide={handleCloseNewCertificateModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva oblea</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Descripción</Form.Text>
                        <Form.Control type="text" id="textNewCertificateDescription" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Fecha de emisión</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newCertificateDate} onChange={(d) => handleNewCertificateDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Fecha de vencimiento</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newCertificateDateExpiration} onChange={(d) => handleNewCertificateDateExpiration(d)} />
                    </Form.Group>

                    <Form.Group className="mt-2">
                        <Form.Text>Archivo</Form.Text>
                        <Form.Control accept='.png, .jpg, .jpeg, .pdf, .docx, .doc' onChange={(event) => handleNewCertificateFile(event)} type="file" />
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewCertificateModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={createCertificate}>Confirmar</Button>
                </Modal.Footer>
            </Modal>

            <Modal size='lg' show={showViewFileModal} onHide={handleCloseViewFileModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Archivo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PDFViewer document={{
                        base64: actualViewFile
                    }} />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseViewFileModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditContactModal} onHide={handleCloseEditContactModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Modificar contacto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup className='mt-2'>
                        {selectedContact.data.map((d, i) => (
                            <Row className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                <Col className='align-center-vertically-v2' xs={10} md={10}>
                                    <span className='me-1'>{d.icon}</span> {d.data}

                                </Col>
                                <Col className='align-center-vertically-v2' style={{ textAlign: 'right' }} xs={2} md={2}>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                                            <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => deleteContactData(d.id)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>

                            </Row>
                        ))}
                    </ListGroup>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditContactModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showDeleteCertificateModal} onHide={handleCloseDeleteCertificateModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        ¿Desea eliminar la oblea?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDeleteCertificateModal}>
                            Cerrar
                        </Button>
                        <Button variant="danger" onClick={deleteCertificate}>
                            Eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>
        </div>
    )
}