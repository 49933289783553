import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Table, Dropdown, DropdownButton, Form, Col, Modal, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faGavel, faEye, faSearch, faFilter, faDownload, faEllipsisV, faCoins, faCalendar, faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import { get, post } from '../../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../../controllers/user.controller';
import { timeSince } from '../../../tools/tools';
import { triggerBase64Download } from 'common-base64-downloader-react';
import { params } from '../../../tools/constants';

export default function CourtFiles() {

    const PAGE_TITLE = "Mis citas";
    const PAGE_ICON = faCalendarAlt;
    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableRows, setTableRows] = React.useState([]);

    const [orderTypes, setOrderTypes] = React.useState(["Ordenar por fecha", "Ordenar por cliente (A -> Z)", "Ordenar por cliente (Z -> A)"]);
    const [selectedOrderType, setSelectedOrderType] = React.useState("Ordenar por fecha");

    const [selectedOnlyMine, setSelectedOnlyMine] = React.useState(false);
    const [selectedStatusPending, setSelectedStatusPending] = React.useState(true);
    const [selectedStatusFinished, setSelectedStatusFinished] = React.useState(false);
    const [selectedStatusCanceled, setSelectedStatusCanceled] = React.useState(false);
    const [selectedStatusPendingDate, setSelectedStatusPendingDate] = React.useState(true);

    const [showFilterModal, setShowFilterModal] = React.useState(false);
    const handleCloseFilterModal = () => { setShowFilterModal(false); };
    const handleShowFilterModal = () => {
        setShowFilterModal(true);
    };

    const handleOrderType = (event) => {
        setSelectedOrderType(event.target.value);
    };

    const handleOnlyMine = (event) => {
        setSelectedOnlyMine(event.target.checked)
    };

    const handleStatusPending = (event) => {
        setSelectedStatusPending(event.target.checked)
    };

    const handleStatusFinished = (event) => {
        setSelectedStatusFinished(event.target.checked)
    };

    const handleStatusCanceled = (event) => {
        setSelectedStatusCanceled(event.target.checked)
    };

    const handleStatusPendingDate = (event) => {
        setSelectedStatusPendingDate(event.target.checked)
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `Plagamix - ${PAGE_TITLE}`;

        getAppointments();
    }, []);

    const getAppointments = async function (onLoad, mobile) {
        const req = await get(`/appointments/mine`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            processAppointments(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
            console.log(res)
        }
    }

    const processAppointments = (data) => {
        let result = [];

        let users;

        for (let a of data) {
            users = [];

            for (let u of a.AppointmentEmployees) {
                users.push(`${u.User.name} ${u.User.surname}`)
            }

            result.push({
                type: a.AppointmentType.name,
                icon: a.AppointmentType.icon,
                day: a.dateStart ? (moment(a.dateStart).format('dddd DD MMM')).toString().toUpperCase().replace('.', '') : "",
                hour: a.dateStart ? `${moment(a.dateStart).format('HH:mm')} a ${moment(a.dateStart).add(a.duration, 'minute').format('HH:mm')}` : "",
                duration: a.duration,
                customerName: a.Customer.name,
                customerAddress: a.Customer.address,
                customerCity: a.Customer.city,
                status: <Badge bg='' className='me-2 content-table status' style={{ background: `${a.Status.color}` }} >{a.Status.name}</Badge>,
                statusMobile: <Badge bg='' className='' style={{ background: `${a.Status.color}` }} >{a.Status.name}</Badge>,
                users: users,
                action: <Link to={`/citas/detalles/?id=${a.id}`}><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye}></FontAwesomeIcon></Button></Link>
            })
        }

        setTableRows(result)
    }

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }} className='d-none d-sm-block'>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 45, fontWeight: 300 }}>{PAGE_TITLE}</p>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>

                    <div className='d-block d-sm-none'>
                        <Row style={{ paddingTop: 10, height: 200 }} >
                            <Col xs={6} className='align-center-vertically-v2'>
                                <p className='m-0' style={{ color: '#A0A0A0', fontSize: 35, fontWeight: 300 }}>{PAGE_TITLE}</p>
                            </Col>
                            <Col xs={6} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 70, color: "A0A0A0" }} />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <Row className='content-options content'>
                            <Col className='d-none d-sm-block align-center-vertically-v2 ps-4'>
                                Mis últimas 20 citas
                            </Col>
                            <Col className='d-none d-sm-block align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>

                            </Col>

                            <Col xs={6} className='d-block d-sm-none align-center-vertically-v2 ps-3'>
                                Mis últimas 20 citas
                            </Col>
                            <Col xs={6} className='d-block d-sm-none align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>

                            </Col>
                        </Row>

                        {tableRows.length > 0 ?
                            <Container className='pt-3'>
                                {tableRows.map((r, i) => (
                                    <Row className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                        <Col className='d-none d-sm-block' xs={1}>
                                            <img src={`${params().dataUri}${r.icon}`} width='70'></img>
                                        </Col>
                                        <Col className='d-block d-sm-none' xs={10}>
                                            <div>
                                                <p className='m-0'></p>
                                                <p className='m-0' style={{ fontSize: 12 }}>{r.statusMobile}</p>
                                                <p className='m-0' style={{ fontSize: 12 }}>{r.day} - {r.hour}</p>
                                                <p className='m-0'>{r.type}</p>
                                            </div>
                                        </Col>
                                        <Col xs={6} className='d-none d-sm-block align-center-vertically-v2'>
                                            <div>
                                                <p className='m-0'>{r.type}</p>
                                            </div>

                                        </Col>
                                        <Col xs={2} className='d-none d-sm-block align-center-vertically-v2 middle'>
                                            <div>
                                                <p className='m-0'>{r.day}</p>
                                                <p className='m-0'>{r.hour}</p>
                                            </div>
                                        </Col>
                                        <Col xs={2} className='d-none d-sm-block align-center-vertically-v2 middle'>
                                            <div className='middle'>
                                                <p className='m-0'>{r.status}</p>
                                            </div>
                                        </Col>
                                        <Col xs={2} md={1} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                            <div>
                                                {r.action}
                                            </div>
                                        </Col>
                                    </Row>
                                ))}
                            </Container>
                            :
                            <Container className='mt-5 mb-5'>

                                <Row style={{ height: 300, textAlign: "center" }}>
                                    <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faCalendarAlt}></FontAwesomeIcon></p>
                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se han encontrado citas</p>
                                </Row>
                            </Container>

                        }

                    </Card>
                </Row>
            </Container>

            
        </div>
    )
}