import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import './App.css';

import Login from "./components/ui/login/Login";
import Index from "./components/ui/home/Index";
import MyHome from "./components/ui/home/MyHome";
import Home from "./components/ui/home/Home";
import Appointments from "./components/ui/appointments/Appointments";
import MyAppointments from "./components/ui/appointments/MyAppointments";
import NewAppointment from "./components/ui/appointments/NewAppointment";
import AppointmentDetails from "./components/ui/appointments/AppointmentDetails";
import ConfirmAppointment from "./components/ui/appointments/ConfirmAppointment";
import Roadmap from "./components/ui/roadmap/Roadmaps";
import Administration from "./components/ui/administration/Administration";
import CashFlow from "./components/ui/administration/CashFlow";
import Bills from "./components/ui/administration/Bills";
import UserCurrentAccount from "./components/ui/administration/UserCurrentAccount";
import Customers from "./components/ui/customers/Customers";
import CustomerDetails from "./components/ui/customers/CustomerDetails";
import NewCustomer from "./components/ui/customers/NewCustomer";
import Settings from "./components/ui/settings/Settings";
import UserDetails from "./components/ui/settings/UserDetails";
import MyUserDetails from "./components/ui/settings/MyUserDetails";
import NewUser from "./components/ui/settings/NewUser";
import Calendar from "./components/ui/calendar/Calendar";
import MyCertificates from "./components/ui/calendar/MyCertificates";
import MyAppointmentDetails from "./components/ui/appointments/MyAppointmentDetails";

import { getUserRole } from "./controllers/user.controller";

export function App() {

    const ProcessRoutes = () => {
        let r = getUserRole();
  

        if (r == "Administrador") {
            return (
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<Index />} />
                    <Route path="/inicio" element={<Home />} />
                    <Route path="/citas" element={<Appointments />} />
                    <Route path="/citas/nueva" element={<NewAppointment />} />
                    <Route path="/citas/detalles" element={<AppointmentDetails />} />
                    <Route path="/citas/confirmar" element={<ConfirmAppointment />} />
                    <Route path="/hojaderuta" element={<Roadmap />} />
                    <Route path="/administracion" element={<Administration />} />
                    <Route path="/administracion/flujoCaja" element={<CashFlow />} />
                    <Route path="/administracion/facturas" element={<Bills />} />
                    <Route path="/usuarios/cuentaCorriente" element={<UserCurrentAccount />} />
                    <Route path="/clientes" element={<Customers />} />
                    <Route path="/clientes/detalles" element={<CustomerDetails />} />
                    <Route path="/clientes/nuevo" element={<NewCustomer />} />
                    <Route path="/sistema" element={<Settings />} />
                    <Route path="/sistema/usuarios/detalles" element={<UserDetails />} />
                    <Route path="/sistema/usuarios/nuevo" element={<NewUser />} />
                    <Route path="/configuracion/usuarios/detalles" element={<MyUserDetails />} />
                    <Route path="/calendario" element={<Calendar />} />
                    <Route path="/perfil" element={<MyUserDetails />} />
                </Routes>
            )
        } else if (r == "Empleado") {
            return (
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<Index />} />
                    <Route path="/inicio" element={<Home />} />
                    <Route path="/citas" element={<Appointments />} />
                    <Route path="/citas/nueva" element={<NewAppointment />} />
                    <Route path="/citas/detalles" element={<AppointmentDetails />} />
                    <Route path="/citas/confirmar" element={<ConfirmAppointment />} />
                    <Route path="/hojaderuta" element={<Roadmap />} />
                    <Route path="/administracion" element={<Administration />} />
                    <Route path="/administracion/flujoCaja" element={<CashFlow />} />
                    <Route path="/administracion/facturas" element={<Bills />} />
                    <Route path="/usuarios/cuentaCorriente" element={<UserCurrentAccount />} />
                    <Route path="/clientes" element={<Customers />} />
                    <Route path="/clientes/detalles" element={<CustomerDetails />} />
                    <Route path="/clientes/nuevo" element={<NewCustomer />} />
                    <Route path="/sistema" element={<Settings />} />
                    <Route path="/sistema/usuarios/detalles" element={<UserDetails />} />
                    <Route path="/sistema/usuarios/nuevo" element={<NewUser />} />
                    <Route path="/configuracion/usuarios/detalles" element={<MyUserDetails />} />
                    <Route path="/calendario" element={<Calendar />} />
                    <Route path="/perfil" element={<MyUserDetails />} />
                </Routes>)
        } else if (r == "Cliente") {
            return (
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<Index />} />
                    <Route path="/inicio" element={<MyHome />} />
                    <Route path="/citas" element={<MyAppointments />} />
                    <Route path="/obleas" element={<MyCertificates />} />
                    <Route path="/perfil" element={<MyUserDetails />} />
                    <Route path="/citas/detalles" element={<MyAppointmentDetails />} />
                    <Route path="/citas/confirmar" element={<ConfirmAppointment />} />
                </Routes>)
        } else {
            return (
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<Index />} />
                    <Route path="/inicio" element={<Home />} />
                </Routes>)
        }
    }

    return (
        <div>
            <Router>
                <Row className="m-0">
                <ProcessRoutes />


                </Row>
            </Router>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossOrigin="anonymous" />
        </div>
    );
}