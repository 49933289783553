import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Table, Dropdown, DropdownButton, Form, Col, Modal, Badge, ButtonGroup, ToggleButton, ListGroup, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faGavel, faEye, faSearch, faFilter, faDownload, faEllipsisV, faCoins, faCalendar, faCalendarAlt, faFilePdf, faTrash, faCheck, faCross, faTimes, faFile, faHistory } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import { del, get, post, put } from '../../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../../controllers/user.controller';
import { timeSince, convert2Base64, downloadFile } from '../../../tools/tools';
import { triggerBase64Download } from 'common-base64-downloader-react';
import { pn } from '../../../tools/chains';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { params, colors } from '../../../tools/constants';
import PDFViewer from 'mgr-pdf-viewer-react';

export default function CourtFiles() {

    const PAGE_TITLE = "Administración";
    const PAGE_ICON = faCoins;
    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableRows, setTableRows] = React.useState([]);
    const [tableRows2, setTableRows2] = React.useState([]);

    const [billLastCheck, setBillLastCheck] = React.useState(new Date());

    const [error, setError] = React.useState(<div></div>);
    const [bills, setBills] = React.useState([]);
    const [currentAccountIndicators, setCurrentAccountIndicators] = React.useState([]);
    const [paymentCategories, setPaymentCategories] = React.useState([]);
    const [paymentMethods, setPaymentMethods] = React.useState([]);
    const [customers, setCustomers] = React.useState([]);
    const [employees, setEmployees] = React.useState([]);
    const [employeesCurrentAccount, setEmployeesCurrentAccount] = React.useState([]);
    const [pendingCurrentAccount, setPendingCurrentAccount] = React.useState([]);
    const [currentAccount, setCurrentAccount] = React.useState([]);
    const [newOperationDate, setNewOperationDate] = React.useState(new Date());
    const [newOperationFile, setNewOperationFile] = React.useState(null);
    const [newOperationCategory, setNewOperationCategory] = React.useState("0");
    const [showNewOperationModal, setShowNewOperationModal] = React.useState(false);
    const handleCloseNewOperationModal = () => { setShowNewOperationModal(false); };
    const handleShowNewOperationModal = () => {
        setError()
        setShowNewOperationModal(true);
    };
    const [newOperationIsCredit, setNewOperationIsCredit] = React.useState(false);

    const [showDeleteOperationModal, setShowDeleteOperationModal] = React.useState(false);
    const [deleteOperationId, setDeleteOperationId] = React.useState(0);
    const handleCloseDeleteOperationModal = () => { setShowDeleteOperationModal(false); };
    const handleShowDeleteOperationModal = (id) => {
        setDeleteOperationId(id)
        setShowDeleteOperationModal(true);
    };

    const [showViewFileModal, setShowViewFileModal] = React.useState(false);
    const [actualViewFile, setActualViewFile] = React.useState("");
    const handleCloseViewFileModal = () => { setShowViewFileModal(false); };
    const handleShowViewFileModal = () => {
        setShowViewFileModal(true);
    };

    const [newBillCustomerText, setNewBillCustomerText] = React.useState("");
    const [newBillService, setNewBillService] = React.useState("");
    const [newBillAmount, setNewBillAmount] = React.useState("");
    const [newBillDate, setNewBillDate] = React.useState(new Date());
    const [showNewBillModal, setShowNewBillModal] = React.useState(false);
    const handleCloseNewBillModal = () => { setShowNewBillModal(false); };
    const handleShowNewBillModal = () => {
        setNewBillDate(new Date())
        setSelectedCustomer(null)
        setError()
        setShowNewBillModal(true);
    };

    const [showNewBillConfirmationModal, setShowNewBillConfirmationModal] = React.useState(false);
    const handleCloseNewBillConfirmationModal = () => { setShowNewBillConfirmationModal(false); };
    const handleShowNewBillConfirmationModal = () => {
        setShowNewBillConfirmationModal(true);
    };

    const [cashFlowHistory, setCashFlowHistory] = React.useState([]);
    const [showCashFlowHistoryModal, setShowCashFlowHistoryModal] = React.useState(false);
    const handleCloseCashFlowHistoryModal = () => { setShowCashFlowHistoryModal(false); };
    const handleShowCashFlowHistoryModal = () => {
        getCurrentAccountHistory()
        setShowCashFlowHistoryModal(true);
    };

    const [selectedCustomer, setSelectedCustomer] = React.useState(null);
    const [searchedCustomers, setSearchedCustomers] = React.useState([]);
    const [showSearchCustomerModal, setShowSearchCustomerModal] = React.useState(false);
    const handleCloseSearchCustomerModal = () => {
        setShowSearchCustomerModal(false);
    };

    const handleOpenSearchCustomerModal = () => {
        setShowSearchCustomerModal(true);
    };

    const handleCustomerSelected = (index) => {
        for (let c of customers) {
            if (c.id == index) {
                setSelectedCustomer(c);
                document.getElementById('textCustomer').value = c.name
                setSearchedCustomers([]);
                handleCloseSearchCustomerModal();
            }
        }
    };

    const handleSearchCustomers = async (event) => {
        if (event.target.value.length > 2) {
            const chain = {
                chain: event.target.value
            };
            const req = await post(`/customers/search`, getUserToken(), chain);
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setSearchedCustomers(res)
            } else {
                alert(res.message);
                console.log(res)
            }
        } else {
            setSearchedCustomers([])
        }
    };


    const [orderTypes, setOrderTypes] = React.useState(["Ordenar por cliente (A -> Z)", "Ordenar por cliente (Z -> A)", "Ordenar por encargado", "Ordenar por fecha"]);
    const [selectedOrderType, setSelectedOrderType] = React.useState("Ordenar por fecha");
    const [selectedOnlyMine, setSelectedOnlyMine] = React.useState(false);

    const [showFilterModal, setShowFilterModal] = React.useState(false);
    const handleCloseFilterModal = () => { setShowFilterModal(false); };
    const handleShowFilterModal = () => {
        setShowFilterModal(true);
    };

    const handleOrderType = (event) => {
        setSelectedOrderType(event.target.value);
    };

    const handleOnlyMine = (event) => {
        setSelectedOnlyMine(event.target.checked)
    };

    const handleNewOperationIsCredit = (event) => {
        setNewOperationIsCredit(event == "Egreso");
    };

    const handleNewOperationDate = (event) => {
        setNewOperationDate(event);
    };

    const handleNewBillDate = (event) => {
        setNewBillDate(event);
    };

    const handleNewOperationCategory = (event) => {
        setNewOperationCategory(event.target.value);
    };

    const handleNewOperationFile = async (event) => {
        const file = event.target.files[0]
        setNewOperationFile(file)
    }

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        registerLocale('es', es);

        document.title = `Plagamix - ${PAGE_TITLE}`;

        getIndicators();
        getPaymentCategories();
        getPaymentMethods();
        getEmployees();
        getCustomers();
        getCurrentAccount();
        getEmployeesLastMovement();
        getPendingCurrentAccount();
        getBills();
        getLastBillCheck();
    }, []);



    const getIndicators = async function () {
        const req = await get(`/administration/currentAccount/indicators`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setCurrentAccountIndicators(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getPaymentMethods = async function () {
        const req = await get(`/administration/currentAccount/methods`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setPaymentMethods(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getPaymentCategories = async function () {
        const req = await get(`/administration/currentAccount/categories`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setPaymentCategories(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getEmployees = async function () {
        const req = await get(`/users/employees`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setEmployees(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getEmployeesLastMovement = async function () {
        const req = await get(`/administration/employees/lastMovement`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            processEmployeesLastMovement(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processEmployeesLastMovement = async function (data) {
        let result = [];

        for (let e of data) {
            e.action = <Link to={`/usuarios/cuentaCorriente/?id=${e.id}`}><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye} /></Button></Link>;
            if (e.lastMovement == null) {
                e.lastMovement = "nunca"
            }
            result.push(e);
        }

        setEmployeesCurrentAccount(result)
    }

    const getCustomers = async function () {
        const req = await get(`/customers`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setCustomers(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getLastBillCheck = async function () {
        const req = await get(`/config/byParam/AFIP_LASTCHECK`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setBillLastCheck(new Date(res.value));
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getCurrentAccount = async function () {
        const req = await get(`/administration/currentAccount/last10`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            processCurrentAccount(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getCurrentAccountHistory = async function () {
        const req = await get(`/administration/currentAccount/months`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setCashFlowHistory(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processCurrentAccount = (data) => {
        let result = [];

        for (let d of data) {
            d.icon = d.File ? faFile : null;
            d.action = (<Dropdown>
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                    <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {d.File ? <div>{d.File.extension == ".pdf" ? <Dropdown.Item onClick={() => viewFile(d.fileId, d.File.name)}><FontAwesomeIcon icon={faEye} style={{ marginRight: 10, marginLeft: 2 }} />Ver archivo</Dropdown.Item> : <div></div>}</div> : <div></div>}
                    {d.File ? <Dropdown.Item onClick={() => downloadFile(d.fileId, d.File.name)}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 10, marginLeft: 2 }} />Descargar archivo</Dropdown.Item> : <div></div>}
                    <Dropdown.Item onClick={() => handleShowDeleteOperationModal(d.id)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>);
            if (d.PaymentCategory != null) {
                d.category = <Badge bg='' className='' style={{ backgroundColor: `${d.PaymentCategory.color}`, fontSize: 10 }}>{d.PaymentCategory.name}</Badge>;
            } else {
                d.category = <div></div>
            }

            if (d.PaymentMethod != null) {
                d.method = <Badge bg='' className='' style={{ backgroundColor: `${d.PaymentMethod.color}`, fontSize: 10 }}>{d.PaymentMethod.name}</Badge>;
            } else {
                d.method = <div></div>
            }

            if (d.credit != null) {
                d.balance = `$${pn(d.credit * -1)}`
            } else if (d.debit != null) {
                d.balance = `$${pn(d.debit)}`
            }

            result.push(d)
        }

        setCurrentAccount(result)

        setTableColumns(['Fecha', 'Descripción', 'Ingreso', 'Egreso', 'Categoría', 'Método', '', '']);
    }

    const createOperation = async function () {
        let debit = null;
        let credit = null;
        let currentAccount = 0;

        if (newOperationIsCredit) {
            credit = parseFloat(document.getElementById('newOperationAmount').value.replace(',', '.'));
        } else {
            debit = parseFloat(document.getElementById('newOperationAmount').value.replace(',', '.'));
        }

        let file = null;
        let fileName = null;
        let fileExtension = null;
        let fromCustomerId = null;
        let toUserId = null;

        if (document.getElementById('selectNewOperationToUserId') != null) {
            toUserId = parseInt(document.getElementById('selectNewOperationToUserId').value) != 0 ? parseInt(document.getElementById('selectNewOperationToUserId').value) : null;
        }

        if (document.getElementById('selectNewOperationFromCustomerId') != null) {
            fromCustomerId = parseInt(document.getElementById('selectNewOperationFromCustomerId').value) != 0 ? parseInt(document.getElementById('selectNewOperationFromCustomerId').value) : null;
            currentAccount = debit * -1;
        }

        if (newOperationFile != null) {
            file = await convert2Base64(newOperationFile);
            const aux = newOperationFile.name.split('.')
            fileExtension = `.${aux[aux.length - 1]}`
            fileName = newOperationFile.name;
        }

        let body = {
            date: newOperationDate,
            description: document.getElementById('newOperationDescription').value,
            debit: debit,
            credit: credit,
            currentAccount: currentAccount,
            paymentMethodId: parseInt(document.getElementById('selectNewOperationMethodId').value) != 0 ? parseInt(document.getElementById('selectNewOperationMethodId').value) : null,
            categoryId: parseInt(document.getElementById('selectNewOperationCategoryId').value) != 0 ? parseInt(document.getElementById('selectNewOperationCategoryId').value) : null,
            toUserId: toUserId,
            fromCustomerId: fromCustomerId,
            file: file,
            extension: fileExtension,
            executed: true
        }

        const req = await post(`/administration/currentAccount`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getPendingCurrentAccount = async function () {
        const req = await get(`/administration/currentAccount/pending/`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processPendingCurrentAccount(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processPendingCurrentAccount = (data) => {
        let result = [];

        for (let d of data) {
            d.avatar = `${params().dataUri}${d.User.avatar}`;
            d.userName = `${d.User.name} ${d.User.surname}`;
            d.icon = d.File ? faFile : null;
            d.action = (
                <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setPendingCurrentAccountAsExecuted(d.id)}><FontAwesomeIcon icon={faCheck} style={{ marginRight: 10, marginLeft: 2 }} />Marcar como rendida</Dropdown.Item>
                        {d.File ? <div>{d.File.extension == ".pdf" ? <Dropdown.Item onClick={() => viewFile(d.fileId, d.File.name)}><FontAwesomeIcon icon={faEye} style={{ marginRight: 10, marginLeft: 2 }} />Ver archivo</Dropdown.Item> : <div></div>}</div> : <div></div>}
                        {d.File ? <Dropdown.Item onClick={() => downloadFile(d.fileId, d.File.name)}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 10, marginLeft: 2 }} />Descargar archivo</Dropdown.Item> : <div></div>}
                        <Dropdown.Item onClick={() => handleShowDeleteOperationModal(d.id)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )
            result.push(d)
        }

        setPendingCurrentAccount(result)
    }



    const setPendingCurrentAccountAsExecuted = async function (id) {
        const body = {
            id: id
        }

        const req = await put(`/administration/currentAccount/executed`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            getPendingCurrentAccount();
            getCurrentAccount();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const viewFile = async (fileId, name) => {
        const req = await get(`/files/byIdNoHeader/${fileId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setActualViewFile(res.file)
            handleShowViewFileModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const deleteOperation = async function () {
        const body = {
            operationId: deleteOperationId,
        }
        const req = await del(`/administration/currentAccount/`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getBills = async function () {
        const req = await get(`/administration/bills/last10/`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processBills(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processBills = (data) => {
        let result = [];

        for (let d of data) {
            d.avatar = `${params().dataUri}${d.User.avatar}`;
            d.userName = `${d.User.name} ${d.User.surname}`;

            if (d.date) {
                d.day = (moment(d.date).format('DD MMM YYYY')).toString().toUpperCase().replace('.', '')
            } else {
                d.day = '';
            }

            if (d.error) {
                d.status = <Badge bg='danger'>{d.error}</Badge>
            } else {
                if (d.cae) {
                    d.status = <Badge bg='success'>Emitida</Badge>
                } else {
                    d.status = <Badge bg='warning'>Pendiente AFIP</Badge>
                }
            }

            if (d.File) {
                d.action = (
                    <Dropdown>
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                            <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {d.File ? <div>{d.File.extension == ".pdf" ? <Dropdown.Item onClick={() => viewFile(d.fileId, d.File.name)}><FontAwesomeIcon icon={faEye} style={{ marginRight: 10, marginLeft: 2 }} />Ver archivo</Dropdown.Item> : <div></div>}</div> : <div></div>}
                            <Dropdown.Item onClick={() => downloadFile(d.fileId, d.File.name)}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 10, marginLeft: 2 }} />Descargar archivo</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )
            } else {
                d.action = <div></div>;
            }

            result.push(d)
        }

        setBills(result)
    }

    const prepareBillResume = function () {
        if (checkBill()) {
            setNewBillAmount(parseFloat(document.getElementById('newBillAmount').value.replaceAll(',', '.')))
            setNewBillService(document.getElementById('newBillService').value)
            setNewBillCustomerText(document.getElementById('textCustomer').value)
            handleCloseNewBillModal();
            handleShowNewBillConfirmationModal();
        }
    }

    const createBill = async function () {
        const body = {
            amount: newBillAmount,
            description: newBillService,
            customerId: selectedCustomer.id,
            appointmentId: null,
            date: newBillDate
        }
        const req = await post(`/administration/bills/`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const checkBill = () => {
        const a = parseFloat(document.getElementById('newBillAmount').value.replaceAll(',', '.'));
        const d = document.getElementById('newBillService').value;

        if (isNaN(a)) {
            showError("El monto es inválido")
            return false;
        } else if (d.length == 0) {
            showError("No se ha ingresado un artículo")
            return false;
        } else if (selectedCustomer == null) {
            showError("No se ha seleccionado un cliente")
            return false;
        } else if (selectedCustomer.legalIdType == null && a > 19999) {
            showError("El cliente seleccionado tiene los datos fiscales incompletos. Debido al monto a facturar, debés completarlos para realizar la factura")
            return false;
        }
        return true;
    }

    const showError = (title) => {
        setError(
            <Alert className='mt-3 mb-0' key='danger' variant='danger'>
                {title}
            </Alert>
        )
    }

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }} className='d-none d-sm-block'>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 45, fontWeight: 300 }}>{PAGE_TITLE}</p>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>

                    <div className='d-block d-sm-none'>
                        <Row style={{ paddingTop: 10, height: 200 }} >
                            <Col xs={8} className='align-center-vertically-v2'>
                                <p className='m-0' style={{ color: '#A0A0A0', fontSize: 35, fontWeight: 300 }}>{PAGE_TITLE}</p>
                            </Col>
                            <Col xs={4} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 70, color: "A0A0A0" }} />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <Row className='content-options content'>
                            <Col className='d-none d-sm-block align-center-vertically-v2 ps-5'>
                                Flujo de caja (últimas 10 operaciones)
                            </Col>
                            <Col className='d-none d-sm-block align-center-vertically-v2 pe-5' style={{ textAlign: 'right' }}>
                                <Button variant="primary" onClick={handleShowCashFlowHistoryModal}><FontAwesomeIcon icon={faHistory} style={{ marginRight: 8 }} />Ver flujo histórico</Button>

                                <Link to='/administracion/flujoCaja'>
                                    <Button className='ms-1' variant="primary"><FontAwesomeIcon icon={faEye} style={{ marginRight: 8 }} />Ver flujo de caja</Button>
                                </Link>

                                <Button className='ms-1' variant="success" onClick={handleShowNewOperationModal}><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nueva operación</Button>
                            </Col>

                            <Col xs={8} className='d-block d-sm-none align-center-vertically-v2 ps-3'>
                                Flujo de caja (últimas 10 operaciones)
                            </Col>
                            <Col xs={4} className='d-block d-sm-none align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>
                                <Link to='/administracion/flujoCaja'>
                                    <Button variant="primary"><FontAwesomeIcon icon={faEye} /></Button>
                                </Link>

                                <Button className='ms-1' variant="success" onClick={handleShowNewOperationModal}><FontAwesomeIcon icon={faPlusCircle} /></Button>
                            </Col>
                        </Row>

                        <Container>
                            <Row className='mb-3'>
                                {currentAccountIndicators.map((ind, i) => (
                                    <Col md={3} sm={12} className='mt-3'>
                                        <Card className={`statusCard ${ind.color} administration`}>
                                            <Card.Body>
                                                <Card.Title>{ind.name}</Card.Title>
                                                <Card.Text>
                                                    <p className='m-0' >
                                                        <span style={{ fontSize: 28, fontStyle: 'bold' }}>{ind.value}</span>
                                                        <span className='ms-1' style={{ fontSize: 13, color: 'gray' }}>{ind.text}</span>
                                                    </p>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Container>

                        {currentAccount.length > 0 ?
                            <Container className='pt-3'>
                                <div className='d-none d-sm-block'>
                                    <Row className=' p-2 mb-2' style={{ background: `${colors().brand_primary}`, color: 'black', borderRadius: 8 }}>
                                        <Col sm={1} className='align-center-vertically-v2'>
                                            <div>
                                                Fecha
                                            </div>
                                        </Col>

                                        <Col sm={5} className='align-center-vertically-v2' >
                                            <div>
                                                Descripción
                                            </div>
                                        </Col>

                                        <Col sm={2} className='align-center-vertically-v2' >
                                            <div style={{ textAlign: 'center' }}>
                                                Ingreso
                                            </div>
                                        </Col>

                                        <Col sm={2} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>
                                                Egreso
                                            </div>
                                        </Col>
                                        <Col sm={2} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>

                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                {currentAccount.map((r, i) => (
                                    <div>
                                        <div className='d-none d-sm-block'>
                                            <Row className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                <Col xs={1} className='align-center-vertically'>
                                                    <Moment format="DD/MM">{r.date}</Moment>

                                                </Col>
                                                <Col xs={5} className='align-center-vertically-v2'>
                                                    <div>
                                                        <p className='m-0'>{r.method}</p>
                                                        <p className='m-0'>{r.description}</p>
                                                    </div>

                                                </Col>
                                                <Col xs={2} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'center' }}>
                                                        {r.debit != null ? `$${pn(r.debit)}` : "---"}
                                                    </div>
                                                </Col>
                                                <Col xs={2} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'center' }}>
                                                        {r.credit != null ? `$${pn(r.credit)}` : "---"}
                                                    </div>
                                                </Col>

                                                <Col xs={1} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                                    <div style={{ textAlign: 'right' }}>
                                                        {r.icon != null ? <FontAwesomeIcon style={{ fontSize: 30 }} icon={r.icon}></FontAwesomeIcon> : ""}
                                                    </div>
                                                </Col>
                                                <Col xs={1} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                                    <div style={{ textAlign: 'right' }}>
                                                        {r.action}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className='d-block d-sm-none'>
                                            <Row className=' p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>

                                                <Col xs={9} className='align-center-vertically'>
                                                    <div>
                                                        <p className='m-0'><span style={{ fontSize: 10 }}><Moment format="DD/MM">{r.date}</Moment></span> {r.method}</p>
                                                        <p className='m-0'>{r.description}</p>
                                                        <p className='m-0' style={{ fontSize: 10 }}>{r.balance}</p>
                                                    </div>

                                                </Col>

                                                <Col xs={3} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                                    <div style={{ textAlign: 'right' }}>
                                                        {r.action}
                                                    </div>
                                                </Col>
                                            </Row>

                                        </div>


                                    </div>

                                ))}
                            </Container>
                            :
                            <Container className='mt-5 mb-5'>

                                <Row style={{ height: 300, textAlign: "center" }}>
                                    <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faCoins}></FontAwesomeIcon></p>
                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se encontraron movimientos</p>
                                </Row>
                            </Container>

                        }



                    </Card>
                </Row>

                <Row>
                    <Card className='cards content mb-5'>
                        <Row className='content-options content'>
                            <Col className='d-none d-sm-block align-center-vertically-v2 ps-5'>
                                Saldo de usuarios
                            </Col>
                            <Col className='d-none d-sm-block align-center-vertically-v2 pe-5' style={{ textAlign: 'right' }}>

                            </Col>

                            <Col xs={8} className='d-block d-sm-none align-center-vertically-v2 ps-3'>
                                Saldo de usuarios
                            </Col>
                            <Col xs={4} className='d-block d-sm-none align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>

                            </Col>
                        </Row>

                        <Container className='pt-3 d-none d-sm-block'>
                            {employeesCurrentAccount.map((e, i) => (
                                <Row className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                    <Col xs={7} className='align-center-vertically middle'>
                                        <div>
                                            <p className='m-0'><img className='avatar-40 me-2' src={`${params().dataUri}${e.avatar}`}></img> {e.name} {e.surname}</p>
                                        </div>
                                    </Col>
                                    <Col xs={4} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center' }}>
                                            <p className='m-0'>Último movimiento: {e.lastMovement}</p>
                                        </div>
                                    </Col>


                                    <Col xs={1} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'right' }}>
                                            <p className='m-0'>{e.action}</p>
                                        </div>
                                    </Col>
                                </Row>
                            ))}
                        </Container>

                        {employeesCurrentAccount.length > 0 ?
                            <Container className='pt-3'>
                                {employeesCurrentAccount.map((e, i) => (
                                    <div>
                                        <div className='d-block d-sm-none'>
                                            <Row className=' p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>

                                                <Col xs={2} className='align-center-vertically'>
                                                    <div>
                                                        <p className='m-0'><img className='avatar-40 me-2' src={`${params().dataUri}${e.avatar}`}></img></p>
                                                    </div>

                                                </Col>

                                                <Col xs={7} className='align-center-vertically'>
                                                    <div>
                                                        <p className='m-0'>{e.name} {e.surname}</p>
                                                        <p className='m-0' style={{ fontSize: 10 }}>Último movimiento: {e.lastMovement}</p>
                                                    </div>
                                                </Col>

                                                <Col xs={3} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                                    <div style={{ textAlign: 'right' }}>
                                                        {e.action}
                                                    </div>
                                                </Col>
                                            </Row>

                                        </div>


                                    </div>

                                ))}
                            </Container>
                            :
                            <Container className='mt-5 mb-5'>

                                <Row style={{ height: 300, textAlign: "center" }}>
                                    <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faCoins}></FontAwesomeIcon></p>
                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se encontraron movimientos</p>
                                </Row>
                            </Container>
                        }
                    </Card>
                </Row>


                <Row>
                    <Card className='cards content mb-5'>
                        <Row className='content-options content'>
                            <Col className='d-none d-sm-block align-center-vertically-v2 ps-5'>
                                Facturas (últimas 10) | Último intento: {moment(billLastCheck).format('HH:mm')}
                            </Col>
                            <Col className='d-none d-sm-block align-center-vertically-v2 pe-5' style={{ textAlign: 'right' }}>
                                <Link to='/administracion/facturas'>
                                    <Button variant="primary"><FontAwesomeIcon icon={faEye} style={{ marginRight: 8 }} />Ver facturas</Button>
                                </Link>
                                <Button className='ms-1' variant="success" onClick={handleShowNewBillModal}><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nueva factura</Button>
                            </Col>

                            <Col xs={8} className='d-block d-sm-none align-center-vertically-v2 ps-3'>
                                Facturas (últimas 10)
                            </Col>
                            <Col xs={4} className='d-block d-sm-none align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>
                                <Link to='/administracion/facturas'>
                                    <Button variant="primary"><FontAwesomeIcon icon={faEye} /></Button>
                                </Link>
                                <Button className='ms-1' variant="success" onClick={handleShowNewBillModal}><FontAwesomeIcon icon={faPlusCircle} /></Button>
                            </Col>
                        </Row>

                        <Container className='pt-3 d-none d-sm-block'>
                            {bills.map((b, i) => (
                                <Row className={b.File ? `p-2 mb-3` : `ps-2 pe-2 pt-3 pb-3 mb-3`} style={{ background: '#F0F0F0', borderRadius: 8 }}>

                                    <Col xs={3} className='align-center-vertically'>
                                        <div>
                                            <p className='m-0'><span className='me-2'>{b.status}</span>{b.completeNumber}</p>
                                        </div>

                                    </Col>
                                    <Col xs={2} className='align-center-vertically middle' style={{ textAlign: 'center' }}>
                                        <div>
                                            <p className='m-0'>{b.day}</p>
                                        </div>
                                    </Col>
                                    <Col xs={4} className='align-center-vertically middle'>
                                        <div>
                                            <p className='m-0'>{b.Customer ? b.Customer.name : ''}</p>
                                        </div>
                                    </Col>
                                    <Col xs={2} className='align-center-vertically middle'>
                                        <div>
                                            <p className='m-0'>${pn(b.totalAmount)}</p>
                                        </div>
                                    </Col>
                                    <Col xs={1} className='align-center-vertically-v2' >
                                        <div style={{ textAlign: 'right' }}>
                                            {b.action}
                                        </div>
                                    </Col>
                                </Row>

                            ))}
                        </Container>

                        {bills.length > 0 ?
                            <Container className='pt-3'>
                                {bills.map((b, i) => (
                                    <div>
                                        <div className='d-block d-sm-none'>
                                            <Row className=' p-2 mb-1' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                <Col xs={10} className='align-center-vertically'>
                                                    <div>
                                                        <p className='m-0' style={{ fontSize: 10 }}><span className='me-2'>{b.status}</span>{b.completeNumber}</p>
                                                        <p className='m-0'>{b.Customer ? b.Customer.name : ''}</p>
                                                    </div>
                                                </Col>

                                                <Col xs={2} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                                    <div style={{ textAlign: 'right' }}>
                                                        {b.action}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                ))}
                            </Container>
                            :
                            <Container className='mt-5 mb-5'>

                                <Row style={{ height: 300, textAlign: "center" }}>
                                    <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faFile}></FontAwesomeIcon></p>
                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se encontraron facturas</p>
                                </Row>
                            </Container>
                        }
                    </Card>
                </Row>


                <Row>
                    <Card className='cards content mb-5'>
                        <Row className='content-options content'>
                            <Col className='d-none d-sm-block align-center-vertically-v2 ps-5'>
                                Facturas a rendir
                            </Col>
                            <Col className='d-none d-sm-block align-center-vertically-v2 pe-5' style={{ textAlign: 'right' }}>

                            </Col>

                            <Col xs={8} className='d-block d-sm-none align-center-vertically-v2 ps-3'>
                                Facturas a rendir
                            </Col>
                            <Col xs={4} className='d-block d-sm-none align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>

                            </Col>
                        </Row>

                        <Container className='pt-3 d-none d-sm-block'>
                            {pendingCurrentAccount.length > 0 ?
                                <div>
                                    {pendingCurrentAccount.map((r, i) => (
                                        <Row className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>

                                            <Col xs={5} className='align-center-vertically-v2'>
                                                <div>
                                                    <p className='m-0' style={{ fontSize: 10 }}>{<Moment format="DD/MM/YYYY">{r.date}</Moment>}</p>
                                                    <p className='m-0'>{r.description}</p>
                                                </div>
                                            </Col>


                                            <Col xs={2} className='align-center-vertically-v2'>
                                                <div style={{ textAlign: 'center' }}>
                                                    <p className='m-0'>${pn(r.credit)}</p>
                                                </div>
                                            </Col>

                                            <Col xs={3} className='align-center-vertically-v2'>
                                                <div style={{ textAlign: 'center' }}>
                                                    <img className='avatar-10' src={`${r.avatar}`} /> <span className='ms-2'>{r.userName}</span>
                                                </div>
                                            </Col>

                                            <Col xs={1} className='align-center-vertically-v2'>
                                                <div style={{ textAlign: 'right' }}>
                                                    <p className='m-0'>{r.icon != null ? <FontAwesomeIcon style={{ fontSize: 30 }} icon={r.icon}></FontAwesomeIcon> : ""}</p>
                                                </div>
                                            </Col>


                                            <Col xs={1} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                                <div style={{ textAlign: 'right' }}>
                                                    {r.action}
                                                </div>
                                            </Col>
                                        </Row>
                                    ))}

                                </div>

                                :

                                <Row style={{ height: 300, textAlign: "center" }}>
                                    <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faFile}></FontAwesomeIcon></p>
                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se encontraron comprobantes</p>
                                </Row>
                            }
                        </Container>

                        {pendingCurrentAccount.length > 0 ?
                            <Container className='pt-3'>
                                {pendingCurrentAccount.map((r, i) => (
                                    <div>
                                        <div className='d-block d-sm-none'>
                                            <Row className=' p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                <Col xs={9} className='align-center-vertically'>
                                                    <div>
                                                        <p className='m-0' style={{ fontSize: 10 }}>{<Moment format="DD/MM/YYYY">{r.date}</Moment>} | {r.userName}</p>
                                                        <p className='m-0'>{r.description}</p>
                                                        <p className='m-0' style={{ fontSize: 10 }}>${pn(r.credit)}</p>
                                                    </div>
                                                </Col>

                                                <Col xs={3} className='align-center-vertically-v2' >
                                                    <div style={{ textAlign: 'right' }}>
                                                        {r.action}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                ))}
                            </Container>
                            :
                            <Container className='mt-5 mb-5 d-block d-sm-none'>

                                <Row style={{ height: 300, textAlign: "center" }}>
                                    <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faFile}></FontAwesomeIcon></p>
                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se encontraron comprobantes</p>
                                </Row>
                            </Container>
                        }
                    </Card>
                </Row>
            </Container>

            <Modal show={showDeleteOperationModal} onHide={handleCloseDeleteOperationModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Desea eliminar la operación?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteOperationModal}>
                        Cerrar
                    </Button>
                    <Button variant="danger" onClick={deleteOperation}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showNewOperationModal} onHide={handleCloseNewOperationModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva operación</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <ButtonGroup>
                            <ToggleButton
                                key={1}
                                id={1}
                                type="radio"
                                variant='outline-success'
                                name="radio"
                                value={"Ingreso"}
                                checked={newOperationIsCredit === false}
                                onChange={(e) => handleNewOperationIsCredit(e.currentTarget.value)}
                            >
                                Ingreso
                            </ToggleButton>

                            <ToggleButton
                                key={2}
                                id={2}
                                type="radio"
                                variant='outline-danger'
                                name="radio"
                                value={"Egreso"}
                                checked={newOperationIsCredit === true}
                                onChange={(e) => handleNewOperationIsCredit(e.currentTarget.value)}
                            >
                                Egreso
                            </ToggleButton>
                        </ButtonGroup>
                    </Row>

                    <Form.Group className='mt-2'>
                        <Form.Text>Monto</Form.Text>
                        <Form.Control type="text" id="newOperationAmount" placeholder="" />
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>Fecha</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newOperationDate} onChange={(d) => handleNewOperationDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Descripción</Form.Text>
                        <Form.Control type="text" id="newOperationDescription" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Categoría</Form.Text>
                        <Form.Select className='cover content rows element' id="selectNewOperationCategoryId">
                            <option value="0">Seleccionar...</option>
                            {paymentCategories.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Método de pago</Form.Text>
                        <Form.Select className='cover content rows element' id="selectNewOperationMethodId">
                            <option value="0">Seleccionar...</option>
                            {paymentMethods.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    {newOperationIsCredit ?
                        <Form.Group className='mt-2'>
                            <Form.Text>Pago a</Form.Text>
                            <Form.Select className='cover content rows element' id="selectNewOperationToUserId">
                                <option value="0">Seleccionar...</option>
                                {employees.map((p, i) => (
                                    <option key={i} id={i} value={p.id}>{p.name} {p.surname}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        :
                        <Form.Group className='mt-2'>
                            <Form.Text>Recibo de</Form.Text>
                            <Form.Select className='cover content rows element' id="selectNewOperationFromCustomerId">
                                <option value="0">Seleccionar...</option>
                                {customers.map((p, i) => (
                                    <option key={i} id={i} value={p.id}>{p.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    }

                    <Form.Group className="mt-2">
                        <Form.Text>Archivo</Form.Text>
                        <Form.Control accept='.pdf, .docx, .xlsx, .png, .jpg, .jpeg, .zip' onChange={(event) => handleNewOperationFile(event)} type="file" />
                    </Form.Group>

                    {error}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewOperationModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={createOperation}>Crear</Button>


                </Modal.Footer>
            </Modal>

            <Modal size='lg' show={showViewFileModal} onHide={handleCloseViewFileModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Archivo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PDFViewer document={{
                        base64: actualViewFile
                    }} />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseViewFileModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showSearchCustomerModal} size='lg' onHide={handleCloseSearchCustomerModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Buscar cliente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" id="" autoFocus placeholder="Buscar..." onChange={(event) => handleSearchCustomers(event)} />
                    {searchedCustomers.length > 0 ? <div></div> : <span></span>}
                    <ListGroup className='mt-2'>
                        {searchedCustomers.map((c, i) => (
                            <ListGroup.Item key={i} onClick={() => handleCustomerSelected(c.id)}>{<div><p className='m-0' style={{ fontSize: 12 }}>{c.legalId ? `CUIT ${c.legalId} - ` : ""}{c.legalName}</p><p className='m-0'></p>{c.name}</div>}</ListGroup.Item>
                        ))}
                    </ListGroup>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSearchCustomerModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>



            <Modal show={showNewBillModal} onHide={handleCloseNewBillModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva factura</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Text>Cliente</Form.Text>
                    <Row>
                        <Col xs={10}>
                            <Form.Group>
                                <Form.Control type="text" id="textCustomer" disabled placeholder="" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Button variant="primary" onClick={handleOpenSearchCustomerModal}><FontAwesomeIcon icon={faSearch} /></Button>
                        </Col>
                    </Row>

                    <Form.Group className='mt-2'>
                        <Form.Text>Fecha del servicio</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newBillDate} onChange={(d) => handleNewBillDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Descripción</Form.Text>
                        <Form.Control type="text" id="newBillService" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Monto</Form.Text>
                        <Form.Control type="text" id="newBillAmount" placeholder="" />
                    </Form.Group>

                    {error}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewBillModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={prepareBillResume}>Siguiente</Button>


                </Modal.Footer>
            </Modal>

            <Modal show={showNewBillConfirmationModal} onHide={handleCloseNewBillConfirmationModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva factura</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>¿Está seguro de querer generar una factura con estos datos?</p>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Text>Cliente</Form.Text>
                                <Form.Control type="text" disabled placeholder="" value={newBillCustomerText} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Form.Group className='mt-2'>
                        <Form.Text>Fecha del servicio</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newBillDate} readOnly />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Descripción</Form.Text>
                        <Form.Control type="text" value={newBillService} readOnly placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Monto</Form.Text>
                        <Form.Control type="text" value={`$${pn(newBillAmount)}`} readOnly placeholder="" />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewBillConfirmationModal}>
                        Cancelar
                    </Button>

                    <Button variant="danger" onClick={createBill}>Confirmar</Button>


                </Modal.Footer>
            </Modal>

            <Modal show={showCashFlowHistoryModal} size='lg' onHide={handleCloseCashFlowHistoryModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Flujo de caja histórico</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className=' p-2 mb-2' style={{ background: `${colors().brand_primary}`, color: 'black', borderRadius: 8 }}>
                        <Col xs={3} className='align-center-vertically-v2'>
                            <div>
                                Período
                            </div>
                        </Col>

            
                        <Col xs={3} className='align-center-vertically-v2' >
                            <div style={{ textAlign: 'center' }}>
                                Ingreso
                            </div>
                        </Col>

                        <Col xs={3} className='align-center-vertically-v2'>
                            <div style={{ textAlign: 'center' }}>
                                Egreso
                            </div>
                        </Col>

                        <Col xs={3} className='align-center-vertically-v2'>
                            <div style={{ textAlign: 'center' }}>
                                Balance
                            </div>
                        </Col>
                       
                    </Row>

                    {cashFlowHistory.map((r, i) => (
                        <div>
                            <div className='d-none d-sm-block'>
                                <Row className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                    <Col xs={3} className='align-center-vertically'>
                                        <div>
                                            <p className='m-0'>{r.month}</p>
                                        </div>

                                    </Col>
                                    <Col xs={3} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center' }}>
                                            {r.debit != null ? `$${pn(parseFloat(r.debit).toFixed(2))}` : "---"}
                                        </div>
                                    </Col>
                                    <Col xs={3} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center' }}>
                                            {r.credit != null ? `$${pn(parseFloat(r.credit).toFixed(2))}` : "---"}
                                        </div>
                                    </Col>
                                    <Col xs={3} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center' }}>
                                            {r.balance != null ? `$${pn(parseFloat(r.balance).toFixed(2))}` : "---"}
                                        </div>
                                    </Col>

                                </Row>
                            </div>



                        </div>

                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCashFlowHistoryModal}>
                        Cerrar
                    </Button>




                </Modal.Footer>
            </Modal>
        </div>
    )
}