import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Col, Card, Badge, Button, Table, Dropdown, Form, Modal, ToggleButton, Alert } from "react-bootstrap";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import bootstrapPlugin from '@fullcalendar/bootstrap';
import listPlugin from '@fullcalendar/list';
import Moment from 'react-moment';
import moment from 'moment'
import 'moment/locale/es-mx';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { params } from '../../../tools/constants'

import { get, post, del, put } from '../../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../../controllers/user.controller';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faSmile } from '@fortawesome/free-regular-svg-icons'
import { faBell, faCheck, faCoins, faDownload, faEllipsisV, faEye, faFile, faGavel, faPlusCircle, faShare, faTasks, faTrash } from '@fortawesome/free-solid-svg-icons';

import NavBar from "../navbar/Navbar";
import { processDate, processDateCalendar, processDateEvents, timeDue, downloadFile, dueWithBadge, pnint } from '../../../tools/tools';
import { pn } from '../../../tools/chains';
import PDFViewer from 'mgr-pdf-viewer-react';



export default function Home() {
    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableRows, setTableRows] = React.useState([]);
    const [tasks, setTasks] = React.useState([]);
    const [error, setError] = React.useState(<div></div>);
    const [indicators, setIndicators] = React.useState([]);
    const [proceedings, setProceedings] = React.useState([]);
    const [certificates, setCertificates] = React.useState([]);
    const [lawer, setLawer] = React.useState({});
    const [showNewTaskModal, setShowNewTaskModal] = React.useState(false);
    const [reminderOptions, setReminderOptions] = React.useState([]);
    const [newTaskProceedingId, setNewTaskProceedingId] = React.useState(null);
    const [newTaskDate, setNewTaskDate] = React.useState(null);
    const [newTaskReminder, setNewTaskReminder] = React.useState(null);
    const [newTaskNotificationChecked, setNewTaskNotificationChecked] = React.useState(false);
    const [newTaskUser, setNewTaskUser] = React.useState({});
    const [selectedLawer, setSelectedLawer] = React.useState({});
    const [homeIndicators, setHomeIndicators] = React.useState([]);
    const [customers, setCustomers] = React.useState([]);

    const [showViewFileModal, setShowViewFileModal] = React.useState(false);
    const [actualViewFile, setActualViewFile] = React.useState("");
    const handleCloseViewFileModal = () => { setShowViewFileModal(false); };
    const handleShowViewFileModal = () => {
        setShowViewFileModal(true);
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto']
            }
        });

        document.title = "Plagamix - Inicio";
        registerLocale('es', es);

        getRoadmap();
        getCertificates();
    }, []);

    const getRoadmap = async function () {
        const req = await get(`/appointments/mine`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            processAppointments(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
            console.log(res)
        }
    }

    const getCertificates = async function () {
        const req = await get(`/appointments/certificates/mine`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            processCertificates(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
            console.log(res)
        }
    }

    const processCertificates = (data) => {
        let cert = [];
        for (let c of data) {
            c.action = (
                <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {c.fileId ? <Dropdown.Item onClick={() => viewFile(c.fileId, c.File.name)} ><FontAwesomeIcon icon={faEye} style={{ marginRight: 10, marginLeft: 2 }} />Ver archivo</Dropdown.Item> : <div></div>}
                        {c.fileId ? <Dropdown.Item onClick={() => downloadFile(c.fileId, c.File.name)}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 10, marginLeft: 2 }} />Descargar archivo</Dropdown.Item> : <div></div>}
                    </Dropdown.Menu>
                </Dropdown>
            )

            cert.push(c)
        }
        setCertificates(cert)
    }

    const processAppointments = (data) => {
        let result = [];
        let users;

        let i = 0;

        

        for (let a of data) {
            if(i < 5){
                users = [];

                for (let u of a.AppointmentEmployees) {
                    users.push(`${u.User.name} ${u.User.surname}`)
                }
    
                result.push({
                    type: a.AppointmentType.name,
                    icon: a.AppointmentType.icon,
                    day: a.dateStart ? (moment(a.dateStart).format('dddd DD MMM')).toString().toUpperCase().replace('.', '') : "",
                hour: a.dateStart ? `${moment(a.dateStart).format('HH:mm')} a ${moment(a.dateStart).add(a.duration, 'minute').format('HH:mm')}` : "",
                    duration: a.duration,
                    customerName: a.Customer.name,
                    customerAddress: a.Customer.address,
                    customerCity: a.Customer.city,
                    status: <Badge bg='' className='me-2 content-table status' style={{ background: `${a.Status.color}` }} >{a.Status.name}</Badge>,
                    statusMobile: <Badge bg='' className='' style={{ background: `${a.Status.color}` }} >{a.Status.name}</Badge>,
                    users: users,
                    action: a.Status.name == "A concretar" ? <Link to={`/citas/confirmar/?id=${a.id}`}><Button variant="warning"><FontAwesomeIcon icon={faCalendarAlt}></FontAwesomeIcon></Button></Link> : <Link to={`/citas/detalles/?id=${a.id}`}><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye}></FontAwesomeIcon></Button></Link>
                })
            }
            
            i++;
        }

        setTableRows(result)
    }



    const handleNewTaskUser = (event) => {
        setNewTaskUser(event.target.value)
    };

    const handleNewTaskNotification = (event) => {
        setNewTaskNotificationChecked(event)
    };

    const handleNewTaskProceeding = (event) => {
        setNewTaskProceedingId(event.target.value)
    };


    const handleNewTaskReminder = (event) => {
        setNewTaskReminder(event.target.value)
    };

    const handleNewTaskDate = (event) => {
        setNewTaskDate(event);
    };


    const setupOpTable = function () {
        const cols = ['Código', 'Cliente', 'Estado', ''];
        setTableColumns(cols);

    };

    const getTasks = async function () {
        const req = await get('/tasks/home', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processTasks(res)
            //processEvents(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processTasks = function (data) {
        let result = [];
        let aux;

        for (let t of data) {
            if (t.Proceeding) {
                switch (t.Proceeding.Status.name) {
                    case "Deuda":
                        aux = `GDD - ${t.Proceeding.Debt.name}`;
                        break;
                    case "Judicial":
                        aux = `Expediente - ${t.Proceeding.CourtFile.name}`;
                        break;
                }
            } else {
                aux = "";
            }

            if (t.Proceeding.Status.name == "Judicial") {
                t.proceedingUrl = `/expedientes/detalles/?id=${t.Proceeding.id}`
            } else if (t.Proceeding.Status.name == "Deuda") {
                t.proceedingUrl = `/moras/detalles/?id=${t.Proceeding.id}`
            }

            t.proceedingName = aux;
            result.push(t);
        }

        setTasks(result);
    }

    const getIndicators = async function () {
        const req = await get(`/indicators/home`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setHomeIndicators(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getCustomers = async function (status) {
        const req = await get('/customers/negativeBalance', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processCustomers(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processCustomers = async function (data) {
        let result = [];
        for (let r of data) {
            r.action = <Link to={`/clientes/detalles/?id=${r.id}`}><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye} /></Button></Link>;
            r.bal = <Badge bg='danger' className='content-table status'>${pnint(r.balance)}</Badge>;
            r.balMobile = <Badge bg='danger' className=''>${pnint(r.balance)}</Badge>;
            result.push(r);
        }
        setCustomers(result);
    }

    const showError = (title) => {
        setError(
            <Alert className='mt-3 mb-0' key='danger' variant='danger'>
                {title}
            </Alert>
        )
    }

    const viewFile = async (fileId, name) => {
        const req = await get(`/files/byIdNoHeader/${fileId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setActualViewFile(res.file)
            handleShowViewFileModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }


    return (
        <div className='p-0 mt-3' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <Container>
                <Row className=''>
                    {homeIndicators.map((ind, i) => (
                        <Col md={3} sm={12} className='mt-3'>
                            <Card className={`statusCard ${ind.color} administration`}>
                                <Card.Body>
                                    <Card.Title>{ind.name}</Card.Title>
                                    <Card.Text>
                                        <p className='m-0' >
                                            <span style={{ fontSize: 28, fontStyle: 'bold' }}>{ind.value}</span>
                                            <span className='ms-1' style={{ fontSize: 13, color: 'gray' }}>{ind.text}</span>
                                        </p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>

            <Container className=''>


                <Row className='mb-5'>
                    <Col xs={12} md={6} className='mt-4'>
                        <Card className='cards'>
                            <Card.Body>
                                <Card.Title>Últimas citas</Card.Title>

                                <Card.Text className='p-1'>
                                    {tableRows.length > 0 ?
                                        <div>
                                            {tableRows.map((r, i) => (
                                                <Row className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                    <Col xs={5} className='align-center-vertically-v2'>
                                                        <div>
                                                        <p className='m-0'>{r.statusMobile}</p>
                                                            <p className='m-0'>{r.type}</p>
                                                        </div>

                                                    </Col>
                                                    <Col xs={5} className='align-center-vertically-v2 middle'>
                                                        <div>
                                                            <p className='m-0'>{r.day}</p>
                                                            <p className='m-0'>{r.hour}</p>
                                                        </div>
                                                    </Col>
                                                    <Col xs={2} className='align-center-vertically-v2'>
                                                        <div style={{ textAlign: 'right' }}>
                                                            {r.action}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            ))}
                                        </div>
                                        :
                                        <Container className='mt-5 mb-5'>

                                            <Row style={{ height: 300, textAlign: "center" }}>
                                                <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faCalendarAlt}></FontAwesomeIcon></p>
                                                <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No hay citas</p>
                                            </Row>
                                        </Container>
                                    }

                                </Card.Text>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} md={6} className='mt-4' >
                        <Card className='cards' style={{ height: '100%'}}>
                            <Card.Body>
                                <Card.Title>Obleas</Card.Title>
                                <Card.Text className='p-1'>
                                {certificates.length > 0 ?
                                <div>
                                    {certificates.map((c, i) => (
                                        <Row className='p-2 mt-1 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                            <Col xs={1} className='d-none d-sm-block align-center-vertically-v2'>
                                                <FontAwesomeIcon icon={faFile} style={{ fontSize: 30 }}></FontAwesomeIcon>
                                            </Col>
                                            <Col xs={6} className='d-none d-sm-block align-center-vertically-v2'>
                                                <div>
                                                    <p className='m-0' style={{ fontSize: 12 }}>{c.Customer.name}</p>
                                                    <p className='m-0'>{c.description}</p>
                                                    <p className='m-0' style={{ fontSize: 12 }}>{moment(c.date).format("DD MMM YYYY").replace(".", "").toUpperCase()} a {moment(c.dateExpiration).format("DD MMM YYYY").replace(".", "").toUpperCase()}</p>
                                                </div>
                                            </Col>

                                            <Col xs={9} className='d-block d-sm-none align-center-vertically-v2'>
                                                <div>
                                                    <p className='m-0' style={{ fontSize: 12 }}>{dueWithBadge(c.dateExpiration, true)} {c.Customer.name}</p>
                                                    <p className='m-0'>{c.description}</p>
                                                    <p className='m-0' style={{ fontSize: 12 }}>{moment(c.date).format("DD MMM YYYY").replace(".", "").toUpperCase()} a {moment(c.dateExpiration).format("DD MMM YYYY").replace(".", "").toUpperCase()}</p>
                                                </div>
                                            </Col>
                                            <Col xs={1} className='d-none d-sm-block align-center-vertically-v2'>
                                                <div style={{ textAlign: 'center' }}>
                                                    
                                                </div>

                                            </Col>

                                            <Col xs={2} className='d-none d-sm-block align-center-vertically-v2'>
                                                <div style={{ textAlign: 'center' }}>
                                                    <p className='m-0'>{dueWithBadge(c.dateExpiration)}</p>
                                                </div>

                                            </Col>
                                            <Col xs={3} md={2} className='align-center-vertically-v2'>
                                                <div style={{textAlign: 'right'}}>{c.action}</div>
                                            </Col>
                                        </Row>
                                    ))}
                                </div>
                                :
                                <div>
                                    <Row style={{ height: 300, textAlign: "center" }}>
                                        <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faFile}></FontAwesomeIcon></p>
                                        <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se han cargado obleas</p>
                                    </Row>
                                </div>
                            }


                                </Card.Text>
                            </Card.Body>
                        </Card>


                    </Col>
                </Row>

            </Container>

            <Modal size='lg' show={showViewFileModal} onHide={handleCloseViewFileModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Archivo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PDFViewer document={{
                        base64: actualViewFile
                    }} />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseViewFileModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>



        </div>
    )
}