import React, { useEffect } from 'react'
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Col, Badge, Dropdown, Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import { faCheck, faFilePdf, faEllipsisV, faCommentAlt, faArrowLeft, faPlusCircle, faGavel, faTrash, faDownload, faEye, faFile, faShare } from '@fortawesome/free-solid-svg-icons'
import '@fortawesome/fontawesome-free/css/all.css';
import NavBar from "../navbar/Navbar";
import WebFont from 'webfontloader';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import bootstrapPlugin from '@fullcalendar/bootstrap';
import moment from 'moment'
import 'moment/locale/es-mx';
import { get, put } from '../../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../../controllers/user.controller';
import { processDate, processDateCalendar, timeDue, dueWithBadge, downloadFile } from '../../../tools/tools';
import PDFViewer from 'mgr-pdf-viewer-react';


export default function Calendar() {

    moment.locale('es-mx');
    const PAGE_TITLE = "Calendario";
    const PAGE_ICON = faCalendarAlt;
    const [certificates, setCertificates] = React.useState([]);
    const [events, setEvents] = React.useState([]);
    const [lastSyncGoogle, setLastSyncGoogle] = React.useState([]);

    const [showViewFileModal, setShowViewFileModal] = React.useState(false);
    const [actualViewFile, setActualViewFile] = React.useState("");
    const handleCloseViewFileModal = () => { setShowViewFileModal(false); };
    const handleShowViewFileModal = () => {
        setShowViewFileModal(true);
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `Plagamix - ${PAGE_TITLE}`;

        document.getElementsByClassName('fc-today-button')[0].innerHTML = 'Hoy'
        document.getElementsByClassName('fc-dayGridDay-button')[0].innerHTML = 'Día'
        document.getElementsByClassName('fc-dayGridWeek-button')[0].innerHTML = 'Semana'
        document.getElementsByClassName('fc-dayGridMonth-button')[0].innerHTML = 'Mes'

        getEvents();

    }, []);


    const getEvents = async function () {
        const req = await get('/events/', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processEvents(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processEvents = function (data) {

        console.log(data)
        let result = [];

        for (let e of data.events) {
            result.push({
                title: e.title,
                date: processDateCalendar(e.date)
            });
        }

        setEvents(result);

        let cert = [];
        for (let c of data.certificates) {
            c.action = (
                <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {c.fileId ? <Dropdown.Item onClick={() => viewFile(c.fileId, c.File.name)} ><FontAwesomeIcon icon={faEye} style={{ marginRight: 10, marginLeft: 2 }} />Ver archivo</Dropdown.Item> : <div></div>}
                        {c.fileId ? <Dropdown.Item onClick={() => downloadFile(c.fileId, c.File.name)}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 10, marginLeft: 2 }} />Descargar archivo</Dropdown.Item> : <div></div>}
                        <Dropdown.Item onClick={() => goToCustomer(c.customerId)} ><FontAwesomeIcon icon={faShare} style={{ marginRight: 10, marginLeft: 2 }} />Ver cliente</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )

            cert.push(c)
        }
        setCertificates(cert)
    }

    const goToCustomer = (id) => {
        window.location.href = `/clientes/detalles/?id=${id}`
    }

    const viewFile = async (fileId, name) => {
        const req = await get(`/files/byIdNoHeader/${fileId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setActualViewFile(res.file)
            handleShowViewFileModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }


    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }} className='d-none d-sm-block'>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 45, fontWeight: 300 }}>{PAGE_TITLE}</p>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>

                    <div className='d-block d-sm-none'>
                        <Row style={{ paddingTop: 10, height: 200 }} >
                            <Col xs={6} className='align-center-vertically-v2'>
                                <p className='m-0' style={{ color: '#A0A0A0', fontSize: 35, fontWeight: 300 }}>{PAGE_TITLE}</p>
                            </Col>
                            <Col xs={6} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 70, color: "A0A0A0" }} />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            <Container style={{ marginTop: -70 }}>
                <div className='d-none d-sm-block'>
                    <Row >
                        <Card className='cards p-4 mb-5'>
                            <FullCalendar
                                plugins={[dayGridPlugin, bootstrapPlugin]}
                                locale='esLocale'
                                initialView="dayGridMonth"
                                aspectRatio='2'
                                dayHeaderFormat={{ weekday: 'long' }}
                                themeSystem='bootstrap'
                                events={events}
                                headerToolbar={{
                                    start: 'title',
                                    center: '',
                                    end: 'today prev,next dayGridDay dayGridWeek dayGridMonth'
                                }}
                            />
                            
                        </Card>
                    </Row>
                </div>

                <Row>
                    <Card className='cards content mb-5'>
                        <Row className='content-options content'>
                            <Col className='align-center-vertically-v2 ps-4'>
                                <span>Vencimiento de obleas</span>
                            </Col>
                        </Row>

                        <Container className='mt-3 mb-3'>
                            {certificates.length > 0 ?
                                <div>
                                    {certificates.map((c, i) => (
                                        <Row className='p-2 mt-1 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                            <Col xs={1} className='d-none d-sm-block align-center-vertically-v2'>
                                                <FontAwesomeIcon icon={faFile} style={{ fontSize: 30 }}></FontAwesomeIcon>
                                            </Col>
                                            <Col xs={6} className='d-none d-sm-block align-center-vertically-v2'>
                                                <div>
                                                    <p className='m-0' style={{ fontSize: 12 }}>{c.Customer.name}</p>
                                                    <p className='m-0'>{c.description}</p>
                                                    <p className='m-0' style={{ fontSize: 12 }}>{moment(c.date).format("DD MMM YYYY").replace(".", "").toUpperCase()} a {moment(c.dateExpiration).format("DD MMM YYYY").replace(".", "").toUpperCase()}</p>
                                                </div>
                                            </Col>

                                            <Col xs={9} className='d-block d-sm-none align-center-vertically-v2'>
                                                <div>
                                                    <p className='m-0' style={{ fontSize: 12 }}>{dueWithBadge(c.dateExpiration, true)} {c.Customer.name}</p>
                                                    <p className='m-0'>{c.description}</p>
                                                    <p className='m-0' style={{ fontSize: 12 }}>{moment(c.date).format("DD MMM YYYY").replace(".", "").toUpperCase()} a {moment(c.dateExpiration).format("DD MMM YYYY").replace(".", "").toUpperCase()}</p>
                                                </div>
                                            </Col>
                                            <Col xs={2} className='d-none d-sm-block align-center-vertically-v2'>
                                                <div style={{ textAlign: 'center' }}>
                                                    <p className='m-0'>{moment(c.dateExpiration).format("DD MMM YYYY").replace(".", "").toUpperCase()}</p>
                                                </div>

                                            </Col>

                                            <Col xs={2} className='d-none d-sm-block align-center-vertically-v2'>
                                                <div style={{ textAlign: 'center' }}>
                                                    <p className='m-0'>{dueWithBadge(c.dateExpiration)}</p>
                                                </div>

                                            </Col>
                                            <Col xs={3} md={1} className='align-center-vertically middle-v2'>
                                                {c.action}
                                            </Col>
                                        </Row>
                                    ))}
                                </div>
                                :
                                <div>
                                    <Row style={{ height: 300, textAlign: "center" }}>
                                        <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faFile}></FontAwesomeIcon></p>
                                        <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se han cargado obleas</p>
                                    </Row>
                                </div>
                            }



                        </Container>
                    </Card>
                </Row>

            </Container>

            <Modal size='lg' show={showViewFileModal} onHide={handleCloseViewFileModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Archivo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PDFViewer document={{
                        base64: actualViewFile
                    }} />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseViewFileModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}