import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Table, Dropdown, Badge, Modal, Form, ListGroup, Col, ButtonGroup, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faCalendarAlt, faCoins, faDownload, faEllipsisV, faEye, faFile, faFilePdf, faGavel, faMap, faPen, faPlusCircle, faSchool, faTrash } from '@fortawesome/free-solid-svg-icons'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import NavBar from "../navbar/Navbar";
import { get, post, put } from '../../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../../controllers/user.controller';
import { pn, removeTildes } from '../../../tools/chains';
import { data } from 'jquery';
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api"
import { convert2Base64, downloadFile } from '../../../tools/tools';
import PDFViewer from 'mgr-pdf-viewer-react';
import { params } from '../../../tools/constants';

export default function NewDebtStep1() {

    const PAGE_TITLE = "Cita";
    const PAGE_ICON = faCalendarAlt;

    const [map, setMap] = React.useState(false);
    const [mapLat, setMapLat] = React.useState(null);
    const [mapLng, setMapLng] = React.useState(null);
    const [customerName, setCustomerName] = React.useState("");
    const [customerId, setCustomerId] = React.useState(null);
    const [basicData, setBasicData] = React.useState({});
    const [rawData, setRawData] = React.useState({});
    const [history, setHistory] = React.useState([]);
    const [certificates, setCertificates] = React.useState([]);
    const [documents, setDocuments] = React.useState([]);
    const [status, setStatus] = React.useState([]);
    const [error, setError] = React.useState(<div></div>);

    const [bills, setBills] = React.useState([]);
    const [newBillCustomerText, setNewBillCustomerText] = React.useState("");
    const [newBillService, setNewBillService] = React.useState("");
    const [newBillAmount, setNewBillAmount] = React.useState("");
    const [newBillDate, setNewBillDate] = React.useState(new Date());
    const [showNewBillModal, setShowNewBillModal] = React.useState(false);
    const handleCloseNewBillModal = () => { setShowNewBillModal(false); };
    const handleShowNewBillModal = () => {
        setNewBillDate(new Date())
        setError()
        setShowNewBillModal(true);
    };

    const handleNewBillDate = (event) => {
        setNewBillDate(event);
    };

    const [showNewBillConfirmationModal, setShowNewBillConfirmationModal] = React.useState(false);
    const handleCloseNewBillConfirmationModal = () => { setShowNewBillConfirmationModal(false); };
    const handleShowNewBillConfirmationModal = () => {
        setShowNewBillConfirmationModal(true);
    };

    const [showViewFileModal, setShowViewFileModal] = React.useState(false);
    const [actualViewFile, setActualViewFile] = React.useState("");
    const handleCloseViewFileModal = () => { setShowViewFileModal(false); };
    const handleShowViewFileModal = () => {
        setShowViewFileModal(true);
    };

    const [showEditAppointmentModal, setShowEditAppointmentModal] = React.useState(false);
    const handleCloseEditAppointmentModal = () => { setShowEditAppointmentModal(false); };
    const handleShowEditAppointmentModal = () => {
        setDate(new Date(rawData.dateStart))
        setShowEditAppointmentModal(true);
    };



    const [showNewAppointmentHistoryModal, setShowNewAppointmentHistoryModal] = React.useState(false);
    const handleCloseNewAppointmentHistoryModal = () => { setShowNewAppointmentHistoryModal(false); };
    const handleShowNewAppointmentHistoryModal = () => {
        setShowNewAppointmentHistoryModal(true);
    };

    const [showEditStatusModal, setShowEditStatusModal] = React.useState(false);
    const handleCloseEditStatusModal = () => { setShowEditStatusModal(false); };
    const handleShowEditStatusModal = () => {
        setShowEditStatusModal(true);
    };

    const [showNewCertificateModal, setShowNewCertificateModal] = React.useState(false);
    const handleCloseNewCertificateModal = () => { setShowNewCertificateModal(false); };
    const handleShowNewCertificateModal = () => {
        setShowNewCertificateModal(true);
    };
    const [newCertificateDate, setNewCertificateDate] = React.useState(new Date());
    const [newCertificateDateExpiration, setNewCertificateDateExpiration] = React.useState(new Date());
    const [newCertificateFile, setNewCertificateFile] = React.useState(null);
    const handleNewCertificateFile = async (event) => {
        const file = event.target.files[0]
        setNewCertificateFile(file)
    }

    const { isLoaded } = useLoadScript(
        {
            googleMapsApiKey: "AIzaSyB3jIu7YBXCLamtTDgIJ816A-_3IyZqp1E",
        }
    );

    const handleNewCertificateDate = (event) => {
        setNewCertificateDate(event);
    };

    const handleNewCertificateDateExpiration = (event) => {
        setNewCertificateDateExpiration(event);
    };

    const [date, setDate] = React.useState(new Date());

    const handleDate = (event) => {
        setDate(event);
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `Plagamix - ${PAGE_TITLE}`;
        registerLocale('es', es);

        getAppointment();
        getAllStatus();
        getBills();
    }, []);

    const getParamId = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('id')) {
            return params.get('id');
        } else {
            return -1;
        }
    }

    const getAppointment = async function (data) {
        const req = await get(`/appointments/byId/${getParamId()}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            processAppointment(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
            console.log(res)
        }
    }

    const getAllStatus = async function (data) {
        const req = await get(`/appointments/status/all`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setStatus(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
            console.log(res)
        }
    }

    const processAppointment = (data) => {
        console.log(data)
        //Map
        if (data.Customer.latitude && data.Customer.longitude) {
            setMapLat(parseFloat(data.Customer.latitude))
            setMapLng(parseFloat(data.Customer.longitude))
            setMap(true)
        }

        setCustomerName(data.Customer.name)
        setCustomerId(data.Customer.id)

        let users = [];

        for (let u of data.AppointmentEmployees) {
            users.push(`${u.User.name} ${u.User.surname}`)
        }

        setBasicData({
            statusName: data.Status.name,
            statusColor: data.Status.color,
            users: users,
            customerName: data.Customer.name,
            appointmentType: data.AppointmentType.name,
            duration: `${data.duration} minutos`,
            amount: pn(data.amount),
            dateStart: data.dateStart ? moment(data.dateStart).format('DD/MM/YYYY HH:mm') : "",
            autoBill: data.autoBill
        })

        setRawData({
            duration: data.duration,
            amount: data.amount,
            dateStart: data.dateStart,
            autoBill: data.autoBill,
            customerId: data.customerId
        })

        setHistory(data.AppointmentHistories)

        let cert = [];
        for (let c of data.Certificates) {
            c.action = (
                <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {c.fileId ? <Dropdown.Item onClick={() => viewFile(c.fileId, c.File.name)} ><FontAwesomeIcon icon={faEye} style={{ marginRight: 10, marginLeft: 2 }} />Ver archivo</Dropdown.Item> : <div></div>}
                        {c.fileId ? <Dropdown.Item onClick={() => downloadFile(c.fileId, c.File.name)}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 10, marginLeft: 2 }} />Descargar archivo</Dropdown.Item> : <div></div>}

                    </Dropdown.Menu>
                </Dropdown>
            )

            cert.push(c)
        }
        setCertificates(cert)
    }


    const createAppointmentHistory = async function () {
        const body = {
            description: document.getElementById('textHistoryDescription').value,
            appointmentId: getParamId()
        }
        const req = await post(`/appointments/history`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            handleCloseNewAppointmentHistoryModal()
            getAppointment(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
            console.log(res)
        }
    }

    const updateStatus = async function () {
        const body = {
            statusId: document.getElementById('selectStatus').value,
            appointmentId: getParamId()
        }
        const req = await put(`/appointments/status`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            handleCloseEditStatusModal()
            getAppointment(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
            console.log(res)
        }
    }

    const updateAppointment = async function () {
        const body = {
            appointmentId: getParamId(),
            duration: document.getElementById('textEditDuration').value,
            amount: parseFloat(document.getElementById('textEditAmount').value),
            dateStart: date,
            autoBill: document.getElementById('selectEditAutoBill').value
        }
        const req = await put(`/appointments/basicData`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            handleCloseEditAppointmentModal()
            getAppointment(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
            console.log(res)
        }
    }

    const createCertificate = async function () {
        let file = null;
        let fileExtension = null;

        if (newCertificateFile != null) {
            file = await convert2Base64(newCertificateFile);
            const aux = newCertificateFile.name.split('.')
            fileExtension = `.${aux[aux.length - 1]}`
        }

        const body = {
            description: document.getElementById('textNewCertificateDescription').value,
            date: newCertificateDate,
            dateExpiration: newCertificateDateExpiration,
            appointmentId: getParamId(),
            customerId: customerId,
            file: file,
            extension: fileExtension
        }
        const req = await post(`/appointments/certificates`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            handleCloseNewCertificateModal()
            getAppointment(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
            console.log(res)
        }
    }

    const goToCustomer = (id) => {
        window.location.href = `/clientes/detalles/?id=${id}`
    }

    const viewFile = async (fileId, name) => {
        const req = await get(`/files/byIdNoHeader/${fileId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setActualViewFile(res.file)
            handleShowViewFileModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const prepareBillResume = function () {
        if (checkBill()) {
            setNewBillAmount(parseFloat(document.getElementById('newBillAmount').value.replaceAll(',', '.')))
            setNewBillService(document.getElementById('newBillService').value)
            setNewBillCustomerText(document.getElementById('textCustomer').value)
            handleCloseNewBillModal();
            handleShowNewBillConfirmationModal();
        }
    }

    const createBill = async function () {
        const body = {
            amount: newBillAmount,
            description: newBillService,
            customerId: rawData.customerId,
            appointmentId: getParamId(),
            date: newBillDate
        }
        const req = await post(`/administration/bills/`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const checkBill = () => {
        const a = parseFloat(document.getElementById('newBillAmount').value.replaceAll(',', '.'));
        const d = document.getElementById('newBillService').value;

        if (isNaN(a)) {
            showError("El monto es inválido")
            return false;
        } else if (d.length == 0) {
            showError("No se ha ingresado un artículo")
            return false;
        }
        return true;
    }

    const showError = (title) => {
        setError(
            <Alert className='mt-3 mb-0' key='danger' variant='danger'>
                {title}
            </Alert>
        )
    }

    const getBills = async function () {
        const req = await get(`/administration/bills/byAppointmentId/${getParamId()}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processBills(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processBills = (data) => {
        let result = [];

        for (let d of data) {
            d.avatar = `${params().dataUri}${d.User.avatar}`;
            d.userName = `${d.User.name} ${d.User.surname}`;

            if (d.date) {
                d.day = (moment(d.date).format('DD MMM YYYY')).toString().toUpperCase().replace('.', '')
            } else {
                d.day = '';
            }

            if (d.error) {
                d.status = <Badge bg='danger'>{d.error}</Badge>
            } else {
                if (d.cae) {
                    d.status = <Badge bg='success'>Emitida</Badge>
                } else {
                    d.status = <Badge bg='warning'>Pendiente AFIP</Badge>
                }
            }

            if (d.File) {
                d.action = (
                    <Dropdown>
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                            <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => viewFile(d.fileId, d.File.name)}><FontAwesomeIcon icon={faEye} style={{ marginRight: 10, marginLeft: 2 }} />Ver archivo</Dropdown.Item>
                            <Dropdown.Item onClick={() => downloadFile(d.fileId, d.File.name)}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 10, marginLeft: 2 }} />Descargar archivo</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )
            } else {
                d.action = <div></div>;
            }

            result.push(d)
        }

        setBills(result)
    }

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }} className='d-none d-sm-block'>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 25, fontWeight: 300 }}>Cita</p>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 45, fontWeight: 300 }}>{customerName}</p>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>

                    <div className='d-block d-sm-none'>
                        <Row style={{ paddingTop: 10, height: 200 }} >
                            <Col xs={8} className='align-center-vertically-v2'>
                                <p className='m-0' style={{ color: '#A0A0A0', fontSize: 20, fontWeight: 300 }}>Cita</p>
                                <p className='m-0' style={{ color: '#A0A0A0', fontSize: 35, fontWeight: 300 }}>{customerName}</p>
                            </Col>
                            <Col xs={4} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 70, color: "A0A0A0" }} />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <Row className='content-options content'>
                            <Col className='d-none d-sm-block align-center-vertically-v2 ps-4'>
                                <Link to={'/citas'} className='link-primary'>
                                    <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver a Citas
                                </Link>
                                <span className='ms-3' style={{ color: '#A0A0A0' }}>|</span><span className='ms-3'>Datos generales de la cita</span>
                            </Col>
                            <Col className='d-none d-sm-block align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>
                                
                            </Col>

                            <Col xs={6} className='d-block d-sm-none align-center-vertically-v2 ps-4'>
                                <Link to={'/citas'} className='link-primary'>
                                    <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver a Citas
                                </Link>
                            </Col>
                            <Col xs={6} className='d-block d-sm-none align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>
                                
                            </Col>
                        </Row>

                        {isLoaded ?
                            <div>
                                {map ?
                                    <GoogleMap
                                        zoom={17}
                                        center={{ lat: mapLat, lng: mapLng }}
                                        mapContainerStyle={{ width: '100%', height: '300px' }}>

                                        <Marker position={{ lat: mapLat, lng: mapLng }} />

                                    </GoogleMap>
                                    :
                                    <Row style={{ height: 300, textAlign: "center" }}>
                                        <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faMap}></FontAwesomeIcon></p>
                                        <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se ha procesado la ubicación del cliente</p>
                                    </Row>
                                }
                            </div>
                            :
                            <div></div>
                        }
                    </Card>
                </Row>
            </Container>

            <Container>

                <Row>
                    <Col xs={12} md={6} className='pe-md-4'>

                        <Row>
                            <Card className='cards content mb-5'>

                                <Row className='content-options content'>
                                    <Col className='d-none d-sm-block align-center-vertically-v2 ps-5'>
                                        <span>Datos del servicio</span>
                                    </Col>
                                    <Col className='d-none d-sm-block align-center-vertically-v2 pe-5' style={{ textAlign: 'right' }}>
                                       
                                    </Col>

                                    <Col xs={6} className='d-block d-sm-none align-center-vertically-v2 ps-4'>
                                        <span>Datos del servicio</span>
                                    </Col>
                                    <Col xs={6} className='d-block d-sm-none align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>
                                       
                                    </Col>
                                </Row>

                                <Container className='mt-3 mb-3'>
                                    <Row>
                                        <Col xs={5} className='align-center-vertically'>
                                            Estado
                                        </Col>
                                        <Col>
                                            <Badge bg='' className='me-2 content-table status' style={{ background: `${basicData.statusColor}` }} >{basicData.statusName}</Badge>
                                        </Col>
                                    </Row>

                                    <Row className='mt-3'>
                                        <Col xs={5} className='align-center-vertically'>
                                            Encargados
                                        </Col>
                                        <Col>
                                            {basicData.users ?
                                                <div>
                                                    {basicData.users.map((u, i) => (
                                                        <p className='m-0'>{u}</p>
                                                    ))}
                                                </div>
                                                :
                                                <div></div>
                                            }

                                        </Col>
                                    </Row>

                                    <Row className='mt-3'>
                                        <Col xs={5} className='align-center-vertically'>
                                            Cliente
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Control type="text" id="" disabled placeholder="" defaultValue={basicData.customerName} />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mt-3'>
                                        <Col xs={5} className='align-center-vertically'>
                                            Tipo de servicio
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Control type="text" id="textCustomer" disabled placeholder="" defaultValue={basicData.appointmentType} />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mt-3'>
                                        <Col xs={5} className='align-center-vertically'>
                                            Duración
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Control type="text" id="textCustomer" disabled placeholder="" defaultValue={basicData.duration} />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mt-3'>
                                        <Col xs={5} className='align-center-vertically'>
                                            Monto a cobrar
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Control type="text" id="textCustomer" disabled placeholder="" defaultValue={basicData.amount} />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mt-3'>
                                        <Col xs={5} className='align-center-vertically'>
                                            Fecha
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Control type="text" id="textCustomer" disabled placeholder="" defaultValue={basicData.dateStart} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Container>
                            </Card>
                        </Row>

                    </Col>
                    <Col xs={12} md={6} className='ps-md-4'>
                        <Row>
                            <Card className='cards content mb-5' style={{ minHeight: 455 }}>

                                <Row className='content-options content'>
                                    <Col xs={5} className='d-none d-sm-block align-center-vertically-v2 ps-5'>
                                        <span>Historial</span>
                                    </Col>
                                    <Col className='d-none d-sm-block align-center-vertically-v2 pe-5' style={{ textAlign: 'right' }}>
                                    
                                    </Col>

                                    <Col xs={6} className='d-block d-sm-none align-center-vertically-v2 ps-4'>
                                        <span>Historial</span>
                                    </Col>
                                    <Col xs={6} className='d-block d-sm-none align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>
                                    
                                    </Col>
                                </Row>


                                <Container className='mt-3 mb-3'>
                                    {history.map((h, i) => (
                                        <Row className='p-2 mt-1 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                            <Col xs={5} md={4} className='align-center-vertically'>
                                                {moment(h.date).format("DD MMM - HH:mm").replace(".", "").toUpperCase()}
                                            </Col>
                                            <Col>
                                                {h.description}
                                            </Col>
                                        </Row>
                                    ))}

                                </Container>
                            </Card>
                        </Row>

                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={6} className='pe-md-4'>

                        <Row>
                            <Card className='cards content mb-5' style={{ minHeight: 420 }}>
                                <Row className='content-options content'>
                                    <Col xs={5} className='d-none d-sm-block align-center-vertically-v2 ps-5'>
                                        <span>Documentos</span>
                                    </Col>
                                    <Col className='d-none d-sm-block align-center-vertically-v2 pe-5' style={{ textAlign: 'right' }}>
                                    
                                    </Col>

                                    <Col xs={6} className='d-block d-sm-none align-center-vertically-v2 ps-4'>
                                        <span>Documentos</span>
                                    </Col>
                                    <Col xs={6} className='d-block d-sm-none align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>
                                    
                                    </Col>
                                </Row>

                                <Container className='mt-3 mb-3'>

                                    {bills.length > 0 ?
                                        <div>
                                            {bills.map((b, i) => (
                                                <Row className={b.File ? `p-2 mb-3` : `ps-2 pe-2 pt-3 pb-3 mb-3`} style={{ background: '#F0F0F0', borderRadius: 8 }}>

                                                    <Col xs={4} className='align-center-vertically'>
                                                        <div>
                                                            <p className='m-0'><span className='me-2'>{b.status}</span>Factura</p>
                                                        </div>

                                                    </Col>
                                                    <Col xs={3} className='align-center-vertically middle' style={{ textAlign: 'center' }}>
                                                        <div>
                                                            <p className='m-0'>{b.day}</p>
                                                        </div>
                                                    </Col>


                                                    <Col xs={3} className='align-center-vertically middle'>
                                                        <div>
                                                            <p className='m-0'>${pn(b.totalAmount)}</p>
                                                        </div>
                                                    </Col>


                                                    <Col xs={2} className='align-center-vertically' style={{ textAlign: 'right' }}>
                                                        <div>
                                                            {b.action}
                                                        </div>
                                                    </Col>
                                                </Row>

                                            ))}
                                        </div>
                                        :
                                        <div>
                                            <Row style={{ height: 300, textAlign: "center" }}>
                                                <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faFile}></FontAwesomeIcon></p>
                                                <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se han cargado documentos</p>
                                            </Row>
                                        </div>
                                    }


                                </Container>
                            </Card>
                        </Row>

                    </Col>
                    <Col xs={12} md={6} className='ps-md-4'>
                        <Row>
                            <Card className='cards content mb-5' style={{ minHeight: 420 }}>

                                <Row className='content-options content'>
                                    <Col xs={5} className='d-none d-sm-block align-center-vertically-v2 ps-5'>
                                        <span>Obleas</span>
                                    </Col>
                                    <Col className='d-none d-sm-block align-center-vertically-v2 pe-5' style={{ textAlign: 'right' }}>
                                    
                                    </Col>

                                    <Col xs={6} className='d-block d-sm-none align-center-vertically-v2 ps-4'>
                                        <span>Obleas</span>
                                    </Col>
                                    <Col xs={6} className='d-block d-sm-none align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>
                                    
                                    </Col>
                                </Row>

                                <Container className='mt-3 mb-3'>
                                    {certificates.length > 0 ?
                                        <div>
                                            {certificates.map((c, i) => (
                                                <Row className='p-2 mt-1 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                    <Col xs={1} md={1} className='align-center-vertically'>
                                                        <FontAwesomeIcon icon={faFile} style={{ fontSize: 30 }}></FontAwesomeIcon>
                                                    </Col>
                                                    <Col xs={8} md={9} className='align-center-vertically'>
                                                        <div>
                                                            <p className='m-0'>{c.description}</p>
                                                            <p className='m-0' style={{ fontSize: 12 }}>{moment(c.date).format("DD MMM YYYY").replace(".", "").toUpperCase()} a {moment(c.dateExpiration).format("DD MMM YYYY").replace(".", "").toUpperCase()}</p>
                                                        </div>

                                                    </Col>
                                                    <Col xs={3} md={2} className='align-center-vertically middle'>
                                                        {c.action}
                                                    </Col>
                                                </Row>
                                            ))}
                                        </div>
                                        :
                                        <div>
                                            <Row style={{ height: 300, textAlign: "center" }}>
                                                <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faFile}></FontAwesomeIcon></p>
                                                <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se han cargado obleas</p>
                                            </Row>
                                        </div>
                                    }



                                </Container>
                            </Card>
                        </Row>

                    </Col>
                </Row>

            </Container>

            <Modal show={showNewAppointmentHistoryModal} onHide={handleCloseNewAppointmentHistoryModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nuevo movimiento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Descripción</Form.Text>
                        <Form.Control as="textarea" rows={3} id="textHistoryDescription" placeholder="" />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewAppointmentHistoryModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={createAppointmentHistory}>Confirmar</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditStatusModal} onHide={handleCloseEditStatusModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Modificar estado</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Text >Si se modifica el estado a "Realizada", se creará el movimiento en la cuenta corriente del cliente</Form.Text>
                    <Form.Select className='cover content rows element mt-2' id="selectStatus" >
                        {status.map((p, i) => (
                            <option key={i} id={i} value={p.id}>{p.name}</option>
                        ))}
                    </Form.Select>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditStatusModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={updateStatus}>Confirmar</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showNewCertificateModal} onHide={handleCloseNewCertificateModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva oblea</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Descripción</Form.Text>
                        <Form.Control type="text" id="textNewCertificateDescription" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Fecha de emisión</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newCertificateDate} onChange={(d) => handleNewCertificateDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Fecha de vencimiento</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newCertificateDateExpiration} onChange={(d) => handleNewCertificateDateExpiration(d)} />
                    </Form.Group>

                    <Form.Group className="mt-2">
                        <Form.Text>Archivo</Form.Text>
                        <Form.Control accept='.png, .jpg, .jpeg, .pdf, .docx, .doc' onChange={(event) => handleNewCertificateFile(event)} type="file" />
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewCertificateModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={createCertificate}>Confirmar</Button>
                </Modal.Footer>
            </Modal>

            <Modal size='lg' show={showViewFileModal} onHide={handleCloseViewFileModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Archivo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PDFViewer document={{
                        base64: actualViewFile
                    }} />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseViewFileModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditAppointmentModal} onHide={handleCloseEditAppointmentModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Modificar cita</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Fecha</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' showTimeSelect dateFormat="dd/MM/yyyy HH:mm" selected={date} onChange={(d) => handleDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Duración (minutos)</Form.Text>
                        <Form.Control type='text' id="textEditDuration" placeholder="" defaultValue={rawData.duration} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Monto a cobrar</Form.Text>
                        <Form.Control type='text' rows={3} id="textEditAmount" defaultValue={rawData.amount} placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Facturación automática</Form.Text>
                        <Form.Select className='cover content rows element' defaultValue={rawData.autoBill} id="selectEditAutoBill" >
                            <option value={false}>No</option>
                            <option value={true}>Si</option>
                        </Form.Select>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditAppointmentModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={updateAppointment}>Confirmar</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showNewBillModal} onHide={handleCloseNewBillModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva factura</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Fecha del servicio</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newBillDate} onChange={(d) => handleNewBillDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Descripción</Form.Text>
                        <Form.Control type="text" id="newBillService" placeholder="" defaultValue={basicData.appointmentType} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Monto</Form.Text>
                        <Form.Control type="text" id="newBillAmount" placeholder="" defaultValue={rawData.amount} />
                    </Form.Group>

                    {error}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewBillModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={prepareBillResume}>Siguiente</Button>


                </Modal.Footer>
            </Modal>

            <Modal show={showNewBillConfirmationModal} onHide={handleCloseNewBillConfirmationModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva factura</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>¿Está seguro de querer generar una factura con estos datos?</p>

                    <Form.Group className=''>
                        <Form.Text>Fecha del servicio</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newBillDate} readOnly />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Descripción</Form.Text>
                        <Form.Control type="text" value={newBillService} readOnly placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Monto</Form.Text>
                        <Form.Control type="text" value={`$${pn(newBillAmount)}`} readOnly placeholder="" />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewBillConfirmationModal}>
                        Cancelar
                    </Button>

                    <Button variant="danger" onClick={createBill}>Confirmar</Button>


                </Modal.Footer>
            </Modal>
        </div>
    )
}