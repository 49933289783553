import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Table, Dropdown, DropdownButton, Form, Col, Modal, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faGavel, faEye, faSearch, faFilter, faDownload, faEllipsisV, faCoins, faCalendar, faCalendarAlt, faFilePdf, faTrash, faCheck, faCross, faTimes, faFile } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import { del, get, post, put } from '../../../controllers/endpoints.controller';
import { getUserId, getUserToken, logout } from '../../../controllers/user.controller';
import { timeSince, convert2Base64, downloadFile } from '../../../tools/tools';
import { triggerBase64Download } from 'common-base64-downloader-react';
import { pn } from '../../../tools/chains';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import PDFViewer from 'mgr-pdf-viewer-react';
import { colors } from '../../../tools/constants';

export default function CourtFiles() {

    const PAGE_TITLE = "Comprobantes";
    const PAGE_ICON = faFile;
    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableRows, setTableRows] = React.useState([]);
    const [tableRows2, setTableRows2] = React.useState([]);

    const [pendingCurrentAccount, setPendingCurrentAccount] = React.useState([]);
    const [currentAccount, setCurrentAccount] = React.useState([]);
    const [currentAccountCol, setCurrentAccountCol] = React.useState([]);

    const [orderTypes, setOrderTypes] = React.useState(["Ordenar por cliente (A -> Z)", "Ordenar por cliente (Z -> A)", "Ordenar por encargado", "Ordenar por fecha"]);
    const [selectedOrderType, setSelectedOrderType] = React.useState("Ordenar por fecha");
    const [selectedOnlyMine, setSelectedOnlyMine] = React.useState(false);

    const [showViewFileModal, setShowViewFileModal] = React.useState(false);
    const [actualViewFile, setActualViewFile] = React.useState("");
    const handleCloseViewFileModal = () => { setShowViewFileModal(false); };
    const handleShowViewFileModal = () => {
        setShowViewFileModal(true);
    };

    const [error, setError] = React.useState(<div></div>);
    const [newOperationDate, setNewOperationDate] = React.useState(new Date());
    const [newOperationFile, setNewOperationFile] = React.useState(null);
    const [newOperationCategory, setNewOperationCategory] = React.useState("0");
    const [showNewOperationModal, setShowNewOperationModal] = React.useState(false);
    const handleCloseNewOperationModal = () => { setShowNewOperationModal(false); };
    const handleShowNewOperationModal = () => {
        setError()
        setShowNewOperationModal(true);
    };

    const [showDeleteOperationModal, setShowDeleteOperationModal] = React.useState(false);
    const [deleteOperationId, setDeleteOperationId] = React.useState(0);
    const handleCloseDeleteOperationModal = () => { setShowDeleteOperationModal(false); };
    const handleShowDeleteOperationModal = (id) => {
        setDeleteOperationId(id)
        setShowDeleteOperationModal(true);
    };

    const [showFilterModal, setShowFilterModal] = React.useState(false);
    const handleCloseFilterModal = () => { setShowFilterModal(false); };
    const handleShowFilterModal = () => {
        setShowFilterModal(true);
    };

    const handleOrderType = (event) => {
        setSelectedOrderType(event.target.value);
    };

    const handleOnlyMine = (event) => {
        setSelectedOnlyMine(event.target.checked)
    };

    const handleNewOperationDate = (event) => {
        setNewOperationDate(event);
    };



    const handleNewOperationFile = async (event) => {
        const file = event.target.files[0]
        setNewOperationFile(file)
    }

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        registerLocale('es', es);

        document.title = `Plagamix - ${PAGE_TITLE}`;

        getCurrentAccount();
        getPendingCurrentAccount();
    }, []);

    const getParamId = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('id')) {
            return params.get('id');
        } else {
            return getUserId();
        }
    }

    const getPendingCurrentAccount = async function () {
        const req = await get(`/administration/currentAccount/pending/byUserId/${getParamId()}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processPendingCurrentAccount(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processPendingCurrentAccount = (data) => {
        let result = [];

        for (let d of data) {
            d.icon = d.File ? faFile : null;
            d.action = (
                <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setPendingCurrentAccountAsExecuted(d.id)}><FontAwesomeIcon icon={faCheck} style={{ marginRight: 10, marginLeft: 2 }} />Marcar como rendida</Dropdown.Item>
                        {d.File ? <Dropdown.Item onClick={() => viewFile(d.fileId, d.File.name)}><FontAwesomeIcon icon={faEye} style={{ marginRight: 10, marginLeft: 2 }} />Ver archivo</Dropdown.Item> : <div></div>}
                        {d.File ? <Dropdown.Item onClick={() => downloadFile(d.fileId, d.File.name)}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 10, marginLeft: 2 }} />Descargar archivo</Dropdown.Item> : <div></div>}
                        <Dropdown.Item onClick={() => handleShowDeleteOperationModal(d.id)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )
            result.push(d)
        }

        setPendingCurrentAccount(result)
    }

    const getCurrentAccount = async function () {
        const req = await get(`/administration/currentAccount/byUserId/${getParamId()}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processCurrentAccount(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processCurrentAccount = (data) => {
        let result = [];

        for (let d of data) {
            d.icon = d.File ? faFile : null;

            d.action = (<Dropdown>
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                    <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {d.File ? <Dropdown.Item onClick={() => viewFile(d.fileId, d.File.name)}><FontAwesomeIcon icon={faEye} style={{ marginRight: 10, marginLeft: 2 }} />Ver archivo</Dropdown.Item> : <div></div>}
                    {d.File ? <Dropdown.Item onClick={() => downloadFile(d.fileId, d.File.name)}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 10, marginLeft: 2 }} />Descargar archivo</Dropdown.Item> : <div></div>}
                    <Dropdown.Item onClick={() => handleShowDeleteOperationModal(d.id)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>);
            if (d.PaymentCategory != null) {
                d.category = <Badge bg='' className='' style={{ backgroundColor: `${d.PaymentCategory.color}`, fontSize: 10 }}>{d.PaymentCategory.name}</Badge>;
            } else {
                d.category = <div></div>
            }

            if (d.PaymentMethod != null) {
                d.method = <Badge bg='' className='' style={{ backgroundColor: `${d.PaymentMethod.color}`, fontSize: 10 }}>{d.PaymentMethod.name}</Badge>;
            } else {
                d.method = <div></div>
            }


            result.push(d)
        }

        setCurrentAccount(result)

        setCurrentAccountCol(['Fecha', 'Descripción', 'Importe', 'Método', '', '']);
    }

    const createOperation = async function () {
        let credit = null;

        credit = parseFloat(document.getElementById('newOperationAmount').value.replace(',', '.'));

        let currentAccount = credit;

        let file = null;
        let fileName = null;
        let fileExtension = null;
        let toUserId = getParamId();

        if (newOperationFile != null) {
            file = await convert2Base64(newOperationFile);
            const aux = newOperationFile.name.split('.')
            fileExtension = `.${aux[aux.length - 1]}`
            fileName = newOperationFile.name;
        }

        let body = {
            date: newOperationDate,
            description: document.getElementById('newOperationDescription').value,
            debit: null,
            credit: credit,
            currentAccount: currentAccount,
            paymentMethodId: null,
            categoryId: null,
            toUserId: toUserId,
            fromCustomerId: null,
            file: file,
            extension: fileExtension,
            executed: false
        }

        const req = await post(`/administration/currentAccount`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const deleteOperation = async function () {
        const body = {
            operationId: deleteOperationId,
        }
        const req = await del(`/administration/currentAccount/`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const setPendingCurrentAccountAsExecuted = async function (id) {
        const body = {
            id: id
        }

        const req = await put(`/administration/currentAccount/executed`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            getPendingCurrentAccount();
            getCurrentAccount();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const viewFile = async (fileId, name) => {
        const req = await get(`/files/byIdNoHeader/${fileId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setActualViewFile(res.file)
            handleShowViewFileModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }} className='d-none d-sm-block'>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 45, fontWeight: 300 }}>{PAGE_TITLE}</p>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>

                    <div className='d-block d-sm-none'>
                        <Row style={{ paddingTop: 10, height: 200 }} >
                            <Col xs={8} className='align-center-vertically-v2'>
                                <p className='m-0' style={{ color: '#A0A0A0', fontSize: 35, fontWeight: 300 }}>{PAGE_TITLE}</p>
                            </Col>
                            <Col xs={4} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 70, color: "A0A0A0" }} />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <Row className='content-options content'>
                            <Col className='d-none d-sm-block align-center-vertically-v2 ps-5'>
                                Facturas a rendir
                            </Col>
                            <Col className='d-none d-sm-block align-center-vertically-v2 pe-5' style={{ textAlign: 'right' }}>
                                <Button variant="success" onClick={handleShowNewOperationModal}><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nuevo comprobante</Button>
                            </Col>

                            <Col xs={8} className='d-block d-sm-none align-center-vertically-v2 ps-3'>
                                Facturas a rendir
                            </Col>
                            <Col xs={4} className='d-block d-sm-none align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>
                                <Button variant="success" onClick={handleShowNewOperationModal}><FontAwesomeIcon icon={faPlusCircle} /></Button>
                            </Col>
                        </Row>

                        <Container className='pt-3 d-none d-sm-block'>
                            {pendingCurrentAccount.length > 0 ?
                                <div>
                                    {pendingCurrentAccount.map((r, i) => (
                                        <Row className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                            <Col xs={8} className='align-center-vertically-v2'>
                                                <div>
                                                    <p className='m-0' style={{ fontSize: 10 }}>{<Moment format="DD/MM/YYYY">{r.date}</Moment>}</p>
                                                    <p className='m-0'>{r.description}</p>
                                                </div>
                                            </Col>

                                            <Col xs={2} className='align-center-vertically-v2'>
                                                <div style={{ textAlign: 'center' }}>
                                                    <p className='m-0'>${pn(r.credit)}</p>
                                                </div>
                                            </Col>

                                            <Col xs={1} className='align-center-vertically-v2'>
                                                <div style={{ textAlign: 'right' }}>
                                                    <p className='m-0'>{r.icon != null ? <FontAwesomeIcon style={{ fontSize: 30 }} icon={r.icon}></FontAwesomeIcon> : ""}</p>
                                                </div>
                                            </Col>

                                            <Col xs={1} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                                <div style={{ textAlign: 'right' }}>
                                                    {r.action}
                                                </div>
                                            </Col>
                                        </Row>
                                    ))}

                                </div>

                                :

                                <Row style={{ height: 300, textAlign: "center" }}>
                                    <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faFile}></FontAwesomeIcon></p>
                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se encontraron comprobantes</p>
                                </Row>
                            }
                        </Container>

                        {pendingCurrentAccount.length > 0 ?
                            <Container className='pt-3'>
                                {pendingCurrentAccount.map((r, i) => (
                                    <div>
                                        <div className='d-block d-sm-none'>
                                            <Row className=' p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                <Col xs={9} className='align-center-vertically'>
                                                    <div>
                                                        <p className='m-0' style={{ fontSize: 10 }}>{<Moment format="DD/MM/YYYY">{r.date}</Moment>}</p>
                                                        <p className='m-0'>{r.description}</p>
                                                        <p className='m-0' style={{ fontSize: 10 }}>${pn(r.credit)}</p>
                                                    </div>
                                                </Col>

                                               

                                                <Col xs={3} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                                    <div style={{ textAlign: 'right' }}>
                                                        {r.action}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                ))}
                            </Container>
                            :
                            <Container className='mt-5 mb-5 d-block d-sm-none'>

                                <Row style={{ height: 300, textAlign: "center" }}>
                                    <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faFile}></FontAwesomeIcon></p>
                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se encontraron comprobantes</p>
                                </Row>
                            </Container>
                        }
                    </Card>
                </Row>

                <Row>
                    <Card className='cards content mb-5 pb-2'>
                        <Row className='content-options content'>
                            <Col className='d-none d-sm-block align-center-vertically-v2 ps-5'>
                                Cuenta corriente
                            </Col>
                            <Col className='d-none d-sm-block align-center-vertically-v2 pe-5' style={{ textAlign: 'right' }}>

                            </Col>

                            <Col xs={8} className='d-block d-sm-none align-center-vertically-v2 ps-3'>
                                Cuenta corriente
                            </Col>
                            <Col xs={4} className='d-block d-sm-none align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>

                            </Col>
                        </Row>

                        {currentAccount.length > 0 ?
                            <Container className='pt-3'>
                                <div className='d-none d-sm-block'>
                                    <Row className=' p-2 mb-2' style={{ background: `${colors().brand_primary}`, color: 'black', borderRadius: 8 }}>
                                        <Col sm={1} className='align-center-vertically-v2'>
                                            <div>
                                                Fecha
                                            </div>
                                        </Col>

                                        <Col sm={7} className='align-center-vertically-v2' >
                                            <div>
                                                Descripción
                                            </div>
                                        </Col>

                                        <Col sm={2} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>
                                                Importe
                                            </div>
                                        </Col>
                                        <Col sm={2} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>

                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                {currentAccount.map((r, i) => (
                                    <div>
                                        <div className='d-none d-sm-block'>
                                            <Row className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                <Col xs={1} className='align-center-vertically'>
                                                    <Moment format="DD/MM">{r.date}</Moment>

                                                </Col>
                                                <Col xs={7} className='align-center-vertically-v2'>
                                                    <div>
                                                        <p className='m-0'>{r.category} {r.method}</p>
                                                        <p className='m-0'>{r.description}</p>
                                                    </div>

                                                </Col>
                                                <Col xs={2} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'center' }}>
                                                        {r.credit != null ? `$${pn(r.credit)}` : "---"}
                                                    </div>
                                                </Col>

                                                <Col xs={1} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                                    <div style={{ textAlign: 'right' }}>
                                                        {r.icon != null ? <FontAwesomeIcon style={{ fontSize: 30 }} icon={r.icon}></FontAwesomeIcon> : ""}
                                                    </div>
                                                </Col>
                                                <Col xs={1} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                                    <div style={{ textAlign: 'right' }}>
                                                        {r.action}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className='d-block d-sm-none'>
                                            <Row className=' p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>

                                                <Col xs={6} className='align-center-vertically'>
                                                    <div>
                                                        <p className='m-0'><span style={{ fontSize: 10 }}><Moment format="DD/MM">{r.date}</Moment></span> {r.method}</p>
                                                        <p className='m-0'>{r.description}</p>
                                                    </div>

                                                </Col>

                                                <Col xs={4} className='align-center-vertically'>
                                                    <div>
                                                        {r.balance}
                                                    </div>
                                                </Col>

                                                <Col xs={2} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                                    <div style={{ textAlign: 'right' }}>
                                                        {r.action}
                                                    </div>
                                                </Col>
                                            </Row>

                                        </div>


                                    </div>

                                ))}
                            </Container>
                            :
                            <Container className='mt-5 mb-5'>

                                <Row style={{ height: 300, textAlign: "center" }}>
                                    <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faCoins}></FontAwesomeIcon></p>
                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se encontraron movimientos</p>
                                </Row>
                            </Container>
                        }
                    </Card>
                </Row>
            </Container>

            <Modal show={showNewOperationModal} onHide={handleCloseNewOperationModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva operación</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <Form.Group className=''>
                        <Form.Text>Monto</Form.Text>
                        <Form.Control type="text" id="newOperationAmount" placeholder="" />
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>Fecha</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newOperationDate} onChange={(d) => handleNewOperationDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Descripción</Form.Text>
                        <Form.Control type="text" id="newOperationDescription" placeholder="" />
                    </Form.Group>



                    <Form.Group className="mt-2">
                        <Form.Text>Archivo</Form.Text>
                        <Form.Control accept='.pdf, .docx, .xlsx, .png, .jpg, .jpeg, .zip' onChange={(event) => handleNewOperationFile(event)} type="file" />
                    </Form.Group>

                    {error}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewOperationModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={createOperation}>Crear</Button>


                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteOperationModal} onHide={handleCloseDeleteOperationModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Desea eliminar la operación?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteOperationModal}>
                        Cerrar
                    </Button>
                    <Button variant="danger" onClick={deleteOperation}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size='lg' show={showViewFileModal} onHide={handleCloseViewFileModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Archivo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PDFViewer document={{
                        base64: actualViewFile
                    }} />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseViewFileModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}