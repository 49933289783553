import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Table, Dropdown, Badge, Modal, Form, ListGroup, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faCoins, faEye, faIndustry, faPlusCircle, faSchool, faSearch, faAddressBook, faTrash, faPen, faUser, faCheck, faIdCard, faPhoneAlt, faEnvelope, faMapMarkerAlt, faInfoCircle, faAddressCard, faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import { get, post, del, put } from '../../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../../controllers/user.controller';
import { pn } from '../../../tools/chains';

export default function BusinessDetails(props) {

    const PAGE_TITLE = "Nuevo cliente";
    const PAGE_ICON = faIndustry;
    const [provinces, setProvinces] = React.useState([]);
    const [customerBalanceCol, setCustomerBalanceCol] = React.useState([]);
    const [taxConditions, setTaxConditions] = React.useState([]);

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `Plagamix - ${PAGE_TITLE}`;


        setupTable();
        getTaxConditions();
        getProvinces();
    }, []);

    const getTaxConditions = async function () {
        const req = await get('/administration/taxConditions', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setTaxConditions(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getProvinces = async function () {
        const req = await get('/others/provinces', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setProvinces(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const setupTable = function () {
        setCustomerBalanceCol(['Fecha', 'Carátula', 'A transferir', 'Transferido', ''])
    }


    const createCustomer = async () => {
        let body = {
            name: document.getElementById('textName').value,
            legalName: document.getElementById('textLegalName').value,
            legalIdType: parseInt(document.getElementById('selectLegalIdType').value),
            legalId: document.getElementById('textLegalId').value,
            taxConditionId: parseInt(document.getElementById('selectTaxCondition').value),
            address: document.getElementById('textAddress').value,
            city: document.getElementById('textCity').value,
            province: document.getElementById('selectProvince').value
        }

        console.log(body)

        const req = await post(`/customers`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            window.location.href = '/clientes'
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }


    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }}>
                        <Row style={{ paddingTop: 10 }} className='d-none d-sm-block'>
                            <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                <tr>
                                    <td style={{ fontSize: 45, fontWeight: 300 }}>
                                        <p className='m-0' style={{ color: '#A0A0A0', fontSize: 45, fontWeight: 300 }}>{PAGE_TITLE}</p>
                                    </td>
                                    <td style={{ fontSize: 140, width: 150 }}>
                                        <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                    </td>
                                </tr>
                            </table>
                        </Row>

                        <div className='d-block d-sm-none'>
                            <Row style={{ paddingTop: 10, height: 200 }} >
                                <Col xs={8} className='align-center-vertically-v2'>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 35, fontWeight: 300 }}>{PAGE_TITLE}</p>
                                </Col>
                                <Col xs={4} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 70, color: "A0A0A0" }} />
                                </Col>
                            </Row>
                        </div>
                    </Row>
                </Container>
            </div>

            <Container className='mb-5'>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <Row className='content-options content'>
                            <Col className='d-none d-sm-block align-center-vertically-v2 ps-4'>
                                <Link to={'/clientes'} className='link-primary'>
                                    <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver a Clientes
                                </Link>
                            </Col>
                            <Col className='d-none d-sm-block align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>

                            </Col>

                            <Col xs={8} className='d-block d-sm-none align-center-vertically-v2 ps-4'>
                                <Link to={'/clientes'} className='link-primary'>
                                    <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver a Clientes
                                </Link>
                            </Col>
                            <Col xs={4} className='d-block d-sm-none align-center-vertically-v2 pe-4' style={{ textAlign: 'right' }}>

                            </Col>
                        </Row>

                        <Container className='mt-4 mb-4'>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Row className='mt-2'>
                                        <Col md={4} className='d-none d-sm-block align-center-vertically-v2'>
                                            Nombre
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Text className='d-block d-sm-none'>Nombre</Form.Text>
                                                <Form.Control type="text" id="textName" placeholder="" />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mt-2'>
                                        <Col md={4} className='d-none d-sm-block align-center-vertically-v2'>
                                            Razón social
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Text className='d-block d-sm-none'>Razón social</Form.Text>
                                                <Form.Control type="text" id="textLegalName" placeholder="" />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mt-2'>
                                        <Col md={4} className='d-none d-sm-block align-center-vertically-v2'>
                                            Tipo de documento
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Text className='d-block d-sm-none'>Tipo de documento</Form.Text>
                                                <Form.Select className='cover content rows element' id="selectLegalIdType">
                                                    <option value="0">Seleccionar...</option>
                                                    <option value={80}>CUIT</option>
                                                    <option value={86}>CUIL</option>
                                                    <option value={96}>DNI</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mt-2'>
                                        <Col md={4} className='d-none d-sm-block align-center-vertically-v2'>
                                            CUIT / CUIL / DNI
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Text className='d-block d-sm-none'>CUIT / CUIL / DNI</Form.Text>
                                                <Form.Control type="text" id="textLegalId" placeholder="" />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mt-2'>
                                        <Col md={4} className='d-none d-sm-block align-center-vertically-v2'>
                                            Condición IVA
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Text className='d-block d-sm-none'>Condición IVA</Form.Text>
                                                <Form.Select className='cover content rows element' id="selectTaxCondition">
                                                    <option value="0">Seleccionar...</option>
                                                    {taxConditions.map((p, i) => (
                                                        <option key={i} id={i} value={p.id}>{p.name}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mt-2'>
                                        <Col md={4} className='d-none d-sm-block align-center-vertically-v2'>
                                            Dirección
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Text className='d-block d-sm-none'>Dirección</Form.Text>
                                                <Form.Control type="text" id="textAddress" placeholder="" />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mt-2'>
                                        <Col md={4} className='d-none d-sm-block align-center-vertically-v2'>
                                            Localidad
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Text className='d-block d-sm-none'>Localidad</Form.Text>
                                                <Form.Control type="text" id="textCity" placeholder="" />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mt-2'>
                                        <Col md={4} className='d-none d-sm-block align-center-vertically-v2'>
                                            Provincia
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Text className='d-block d-sm-none'>Provincia</Form.Text>
                                                <Form.Select className='cover content rows element' id="selectProvince">
                                                    <option value="0">Seleccionar...</option>
                                                    {provinces.map((p, i) => (
                                                        <option key={i} id={i} value={p.name}>{p.name}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col xs={12} md={6}>

                                </Col>
                            </Row>



                            <div className='mt-5' style={{ textAlign: 'right' }}>
                                <Button variant="success" onClick={createCustomer}><FontAwesomeIcon icon={faCheck} style={{ marginRight: 8 }} />Confirmar</Button>
                            </div>

                        </Container>
                    </Card>
                </Row>

            </Container>


        </div>
    )
}