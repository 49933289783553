import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Table, Dropdown, Badge, Modal, Form, ListGroup, Col, ButtonGroup, ToggleButton, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faCalendarAlt, faCheck, faCheckCircle, faCoins, faEye, faGavel, faPlusCircle, faSchool, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import NavBar from "../navbar/Navbar";
import { get, post } from '../../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../../controllers/user.controller';
import { removeTildes } from '../../../tools/chains';
import { params } from '../../../tools/constants';



export default function NewDebtStep1() {

    const PAGE_TITLE = "Confirmar cita";
    const PAGE_ICON = faCalendarAlt;

    const [availableDates, setAvailableDates] = React.useState([]);
    const [availableHours, setAvailableHours] = React.useState([]);

    const [selectedDate, setSelectedDate] = React.useState(null);
    const [selectedHour, setSelectedHour] = React.useState(null);
    const [selectedDateKey, setSelectedDateKey] = React.useState(-1);
    const [selectedHourKey, setSelectedHourKey] = React.useState(null);

    const [appointmentOk, setAppointmentOk] = React.useState(false);

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `Plagamix - ${PAGE_TITLE}`;
        registerLocale('es', es);

        getAvailableDates();
    }, []);

    const handleSelectDate = (date, key) => {
        setSelectedDate(date);
        setSelectedDateKey(key);

        getAvailableHours(date)
    }

    const handleSelectHour = (date, key) => {
        setSelectedHour(date);
        setSelectedHourKey(key);
    }

    const getParamId = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('id')) {
            return params.get('id');
        } else {
            return -1;
        }
    }

    const getAvailableDates = async function () {
        const body = {
            appointmentId: getParamId()
        }

        const req = await post(`/appointments/dates`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setAvailableDates(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getAvailableHours = async function (date, key) {
        const body = {
            date: date
        }

        const req = await post(`/appointments/hours`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            let result = [];
            const ob = Object.keys(res)

            for (let h of ob) {
                result.push(res[h])
            }
            setAvailableHours(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const confirmAppointment = async function () {
        let d = selectedDate;
        const hh = selectedHour.format('HH')
        const mm = selectedHour.format('mm')
        d.set('hour', parseInt(hh))
        d.set('minute', parseInt(mm))
        d.set('second', 0)

        const body = {
            dateStart: d,
            id: getParamId()
        }

        const req = await post(`/appointments/confirm`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            setAppointmentOk(true)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }





    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }} className='d-none d-sm-block'>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 45, fontWeight: 300 }}>{PAGE_TITLE}</p>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>

                    <div className='d-block d-sm-none'>
                        <Row style={{ paddingTop: 10, height: 200 }} >
                            <Col xs={8} className='align-center-vertically-v2'>
                                <p className='m-0' style={{ color: '#A0A0A0', fontSize: 35, fontWeight: 300 }}>{PAGE_TITLE}</p>
                            </Col>
                            <Col xs={4} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 70, color: "A0A0A0" }} />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>


                        <Row className='content-options content'>
                            <Col className='align-center-vertically-v2 ps-4'>
                                Seleccioná un turno disponible
                            </Col>
                        </Row>

                        <Container className='mt-4 mb-4'>
                            {appointmentOk ?
                                <Row className='mt-5 mb-5' style={{ textAlign: 'center' }}>
                                    <p style={{ fontSize: 70, color: '#28A745' }}><FontAwesomeIcon icon={faCheckCircle} style={{ marginRight: 8 }} /></p>
                                    <p className='m-0' style={{ fontSize: 20 }}>¡Listo!</p>
                                    <p className='m-0' style={{ fontSize: 20 }}>Cita confirmada correctamente</p>

                                </Row>
                                :
                                <div>
                                    <Alert variant='secondary'>PASO 1: Seleccioná el día que prefieras</Alert>

                                    <Row>
                                        {availableDates.map((d, i) => (
                                            <Col md={4} className='mb-2'>
                                                <ListGroup.Item variant={selectedDateKey == i ? 'primary' : 'success'} key={i} className='p-4' onClick={() => handleSelectDate(moment(d), i)} >
                                                    <Row>
                                                        <Col style={{ textAlign: 'center' }}>
                                                            {moment(d).format('dddd DD MMMM').toUpperCase()}
                                                        </Col>

                                                    </Row>
                                                </ListGroup.Item>
                                            </Col>
                                        ))}
                                    </Row>

                                    {selectedDate != null ?
                                        <div>
                                            <Alert variant='secondary' className='mt-4'>PASO 2: Seleccioná el horario para el {moment(selectedDate).format('dddd DD MMMM')}</Alert>

                                            <Row>
                                                {availableHours.map((d, i) => (
                                                    <Col md={2} className='mb-2'>
                                                        <ListGroup.Item variant={d.available ? `${selectedHourKey == i ? 'primary' : 'success'}` : 'danger'} key={i} className='p-4' onClick={() => handleSelectHour(d.available ? moment(d) : null, d.available ? i : null)} >
                                                            <Row>
                                                                <Col style={{ textAlign: 'center' }}>
                                                                    {d.hour}
                                                                </Col>

                                                            </Row>
                                                        </ListGroup.Item>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </div>
                                        :
                                        <div></div>
                                    }

                                    {selectedHour ?
                                        <Button className='w-100 mt-3' onClick={confirmAppointment}><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> Confirmar cita</Button>
                                        :
                                        <div></div>
                                    }
                                </div>
                            }




                        </Container>
                    </Card>
                </Row>
            </Container>




        </div>
    )
}