import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Table, Dropdown, Badge, Modal, Form, ListGroup, Col, ButtonGroup, ToggleButton, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faCalendarAlt, faCheck, faCoins, faEye, faGavel, faPlusCircle, faSchool, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import NavBar from "../navbar/Navbar";
import { get, post } from '../../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../../controllers/user.controller';
import { removeTildes } from '../../../tools/chains';
import { params } from '../../../tools/constants';



export default function NewDebtStep1() {

    const PAGE_TITLE = "Nueva cita";
    const PAGE_ICON = faCalendarAlt;

    const [appointmentsDate, setAppointmentsDate] = React.useState([]);
    const [customers, setCustomers] = React.useState([]);
    const [contacts, setContacts] = React.useState([]);
    const [employees, setEmployees] = React.useState([]);
    const [selectedEmployees, setSelectedEmployees] = React.useState([]);
    const [selectedCustomer, setSelectedCustomer] = React.useState(null);
    const [canNext, setCanNext] = React.useState(false);
    const [date, setDate] = React.useState(new Date());
    const [selectedByPlagamix, setSelectedByPlagamix] = React.useState(true);
    const [appointmentTypes, setAppointmentTypes] = React.useState([]);

    const [showSearchCustomerModal, setShowSearchCustomerModal] = React.useState(false);

    const [showSelectEmployeeModal, setShowSelectEmployeeModal] = React.useState(false);
    const handleCloseSelectEmployeeModal = () => { setShowSelectEmployeeModal(false); };
    const handleShowSelectEmployeeModal = () => { setShowSelectEmployeeModal(true); };

    const [canNotify, setCanNotify] = React.useState(false);
    const [canCustomerDate, setCanCustomerDate] = React.useState(false);


    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `Plagamix - ${PAGE_TITLE}`;
        registerLocale('es', es);

        getAppointmentTypes();
        getEmployees();
    }, []);



    const handleDate = (event) => {
        setDate(event);
        getAppointmentsByDate(event);
    };

    const handleCloseSearchCustomerModal = () => {
        setShowSearchCustomerModal(false);
    };

    const handleSelectedByPlagamix = (event) => {
        setSelectedByPlagamix(event == "Plagamix");
    };

    const handleOpenSearchCustomerModal = () => {
        setShowSearchCustomerModal(true);
    };


    const handleCustomerSelected = (index) => {
        for (let c of customers) {
            if (c.id == index) {
                setSelectedCustomer(c);
                document.getElementById('textCustomer').value = c.name
                setCustomers([]);
                handleCloseSearchCustomerModal();
                getContacts(c.id)
                setCanNext(true);
                customerCanSelectDate(c.id)
            }
        }
    };

    const handleEmployeeSelected = (index) => {
        let aux = selectedEmployees;
        for (let e of employees) {
            if (e.id == index) {
                if (!aux.includes(e)) {
                    aux.push(e)
                }
                handleCloseSelectEmployeeModal();
            }
        }
    };

    const deleteSelectedEmployee = (e) => {
        let aux = selectedEmployees;

        var index = aux.indexOf(e);
        if (index !== -1) {
            aux.splice(index, 1);
        }

        setSelectedEmployees([...aux])
    };

    const checkStep = (employee, customer, defendant, student) => {
        /*if (employee) {
            if (selectedCustomer) {
                if (selectedStudent1) {
                    return true;
                }
            }
        } else if (customer) {
            if (selectedLawer) {
                if (selectedStudent1) {
                    return true;
                }
            }
        } else if (student) {
            if (selectedLawer) {
                if (selectedCustomer) {
                    return true;
                }
            }
        }
        return false;*/
    }

    const handleSearchCustomers = async (event) => {
        if (event.target.value.length > 2) {
            const chain = {
                chain: event.target.value
            };
            const req = await post(`/customers/search`, getUserToken(), chain);
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setCustomers(res)
            } else {
                alert(res.message);
                console.log(res)
            }
        } else {
            setCustomers([])
        }
    };

    const getAppointmentTypes = async function () {
        const req = await get(`/appointments/types`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setAppointmentTypes(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getAppointmentsByDate = async function (date) {
        const body = {
            date: date
        }
        const req = await post(`/appointments/byDate`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            processAppointments(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const contactHasWhatsApp = async function (contactId) {
        const req = await get(`/customers/contacts/whatsapp/${contactId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setCanNotify(res.whatsapp)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const customerCanSelectDate = async function (id) {
        const req = await get(`/customers/selectDate/${id}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setCanCustomerDate(res.canSelect)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processAppointments = (data) => {
        let result = [];

        let users;

        for (let a of data) {
            users = [];

            for (let u of a.AppointmentEmployees) {
                users.push(`${u.User.name} ${u.User.surname}`)
            }

            result.push({
                type: a.AppointmentType.name,
                icon: a.AppointmentType.icon,
                day: (moment(a.dateStart).format('dddd DD MMM')).toString().toUpperCase().replace('.', ''),
                hour: `${moment(a.dateStart).format('HH:mm')} a ${moment(a.dateStart).add(a.duration, 'minute').format('HH:mm')}`,
                duration: a.duration,
                customerName: a.Customer.name,
                customerAddress: a.Customer.address,
                customerCity: a.Customer.city,
                status: <Badge bg='' className='me-2 content-table status' style={{ background: `${a.Status.color}` }} >{a.Status.name}</Badge>,
                users: users,
                action: <Link to={`/citas/detalles/?id=${a.id}`}><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye}></FontAwesomeIcon></Button></Link>
            })
        }

        setAppointmentsDate(result)
    }

    const getContacts = async function (id) {
        const req = await get(`/customers/contacts/byCustomerId/${id}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setContacts(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getEmployees = async function () {
        const req = await get(`/users/employees`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setEmployees(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const createAppointment = async function () {
        let users = [];

        for (let e of selectedEmployees) {
            users.push(e.id)
        }

        let notify = false;
        if (canNotify == true) {
            notify = document.getElementById('checkNotificateCustomer').checked;
        }

        const body = {
            customerId: selectedCustomer.id,
            contactId: document.getElementById('selectContactId').value,
            notificateCustomer: document.getElementById('checkNotificateCustomer').checked,
            typeId: document.getElementById('selectAppointmentTypeId').value,
            customerSelectDate: !selectedByPlagamix,
            dateStart: selectedByPlagamix ? date : null,
            duration: document.getElementById('textDuration').value,
            users: users,
            amount: document.getElementById('textAmount').value,
            automaticInvoice: document.getElementById('chechAutomaticInvoice').checked,
            notificate: notify
        }

        const req = await post(`/appointments`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            window.location.href = "/citas";
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }} className='d-none d-sm-block'>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 45, fontWeight: 300 }}>{PAGE_TITLE}</p>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>

                    <div className='d-block d-sm-none'>
                        <Row style={{ paddingTop: 10, height: 200 }} >
                            <Col xs={8} className='align-center-vertically-v2'>
                                <p className='m-0' style={{ color: '#A0A0A0', fontSize: 35, fontWeight: 300 }}>{PAGE_TITLE}</p>
                            </Col>
                            <Col xs={4} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 70, color: "A0A0A0" }} />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>


                        <Row className='content-options content'>
                            <Col className='align-center-vertically-v2 ps-4'>
                                <Link to={'/citas'} className='link-primary'>
                                    <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver a Citas
                                </Link>
                            </Col>
                        </Row>

                        <Container className='mt-4 mb-4'>
                            <Form>
                                <Row className='d-block d-sm-none'>
                                    <Col>
                                        <Form.Text>Cliente</Form.Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={2} className='d-none d-sm-block align-center-vertically-v2'>
                                        Cliente
                                    </Col>
                                    <Col xs={9} md={5}>
                                        <Form.Group>
                                            <Form.Control type="text" id="textCustomer" disabled placeholder="" />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Button variant="primary" onClick={handleOpenSearchCustomerModal}><FontAwesomeIcon icon={faSearch} /></Button>
                                    </Col>
                                </Row>

                                <Row className='d-block d-sm-none mt-3'>
                                    <Col>
                                        <Form.Text>Contacto</Form.Text>
                                    </Col>
                                </Row>
                                <Row className='mt-md-3'>
                                    <Col md={2} className='d-none d-sm-block align-center-vertically-v2'>
                                        Contacto
                                    </Col>
                                    <Col xs={8} md={5}>
                                        <Form.Select className='cover content rows element' id="selectContactId" onChange={(event) => contactHasWhatsApp(event.target.value)}>
                                            <option value="0">Seleccionar...</option>
                                            {contacts.map((p, i) => (
                                                <option key={i} id={i} value={p.id}>{p.name}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                    <Col className='align-center-vertically'>
                                        <Form.Check
                                            type='checkbox'
                                            id='checkNotificateCustomer'
                                            defaultChecked
                                            disabled={!canNotify}
                                            label='Enviar notificaciones por WhatsApp'
                                        />
                                    </Col>
                                </Row>

                                <Row className='d-block d-sm-none mt-3'>
                                    <Col>
                                        <Form.Text>Tipo de servicio</Form.Text>
                                    </Col>
                                </Row>
                                <Row className='mt-md-3'>
                                    <Col md={2} className='d-none d-sm-block align-center-vertically-v2'>
                                        Tipo de servicio
                                    </Col>
                                    <Col xs={12} md={5}>
                                        <Form.Select className='cover content rows element' id="selectAppointmentTypeId" >
                                            {appointmentTypes.map((p, i) => (
                                                <option key={i} id={i} value={p.id}>{p.name}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                    <Col>

                                    </Col>
                                </Row>


                                <Row className='d-block d-sm-none mt-3'>
                                    <Col>
                                        <Form.Text>Duración</Form.Text>
                                    </Col>
                                </Row>
                                <Row className='mt-md-3'>
                                    <Col md={2} className='d-none d-sm-block align-center-vertically-v2'>
                                        Duración
                                    </Col>
                                    <Col xs={4} md={1}>
                                        <Form.Group>
                                            <Form.Control type="text" id="textDuration" defaultValue='0' placeholder="" />
                                        </Form.Group>
                                    </Col>
                                    <Col className='align-center-vertically'>
                                        minutos
                                    </Col>
                                </Row>



                                <Row className='d-block d-sm-none mt-3'>
                                    <Col>
                                        <Form.Text>Monto a cobrar</Form.Text>
                                    </Col>
                                </Row>
                                <Row className='mt-md-3'>
                                    <Col md={2} className='d-none d-sm-block align-center-vertically-v2'>
                                        Monto a cobrar
                                    </Col>
                                    <Col xs={5} md={2} className='align-center-vertically'>
                                        $<Form.Control className='ms-2' type="text" id="textAmount" defaultValue='0' placeholder="" />
                                    </Col>
                                    <Col className='align-center-vertically'>
                                        <Form.Check
                                            type='checkbox'
                                            id='chechAutomaticInvoice'
                                            label='Facturar automáticamente'
                                        />
                                    </Col>
                                </Row>

                                <Row className='d-block d-sm-none mt-3'>
                                    <Col>
                                        <Form.Text>Encargados</Form.Text>
                                    </Col>
                                </Row>
                                <Row className='mt-md-3'>
                                    <Col md={2} className='d-none d-sm-block align-center-vertically-v2'>
                                        Encargados
                                    </Col>
                                    <Col xs={9} md={5} className='align-center-vertically-v2'>
                                        {selectedEmployees.length > 0 ?
                                            <div className='w-100'>
                                                {selectedEmployees.map((r, i) => (
                                                    <Row className='p-2 mt-1 mb-1' style={{ background: '#F0F0F0', borderRadius: 8 }}>

                                                        <Col xs={2} className='d-none d-sm-block align-center-vertically-v2 middle'>
                                                            <div>
                                                                <p className='m-0'><img className='avatar-10' src={`${params().dataUri}${r.avatar}`}></img></p>
                                                            </div>
                                                        </Col>

                                                        <Col xs={9} md={8} className='align-center-vertically middle'>
                                                            <div>
                                                                <p className='m-0'>{r.name} {r.surname}</p>
                                                            </div>
                                                        </Col>

                                                        <Col xs={2} className='align-center-vertically' style={{ textAlign: 'right' }}>
                                                            <Button variant="danger" onClick={() => deleteSelectedEmployee(r)}><FontAwesomeIcon icon={faTrash} /></Button>
                                                        </Col>
                                                    </Row>
                                                ))}
                                            </div>
                                            :
                                            <Alert variant='warning' className='m-0'>Seleccioná un encargado</Alert>
                                        }
                                    </Col>
                                    <Col className='align-center-vertically-v2'>
                                        <Button variant="primary" onClick={handleShowSelectEmployeeModal}><FontAwesomeIcon icon={faSearch} /></Button>
                                    </Col>
                                </Row>

                                <Row className='d-block d-sm-none mt-3'>
                                    <Col>
                                        <Form.Text>Selección de fecha</Form.Text>
                                    </Col>
                                </Row>
                                <Row className='mt-md-3'>
                                    <Col md={2} className='d-none d-sm-block align-center-vertically-v2'>
                                        Selección de fecha
                                    </Col>
                                    <Col className='align-center-vertically-v2'>

                                        {!canCustomerDate ?
                                            <Alert variant='warning' className='m-0'>Plagamix deberá seleccionar la fecha</Alert>
                                            
                                            :
                                            <ButtonGroup>

                                                <ToggleButton
                                                    key={1}
                                                    id={1}
                                                    type="radio"
                                                    variant='outline-warning'
                                                    name="radio"
                                                    disabled={!canCustomerDate}
                                                    checked={selectedByPlagamix === true}
                                                    onClick={(e) => handleSelectedByPlagamix("Plagamix")}
                                                >
                                                    Seleccionada por Plagamix
                                                </ToggleButton>

                                                <ToggleButton
                                                    key={2}
                                                    id={2}
                                                    type="radio"
                                                    variant='outline-primary'
                                                    name="radio"
                                                    disabled={!canCustomerDate}
                                                    checked={selectedByPlagamix === false}
                                                    onClick={(e) => handleSelectedByPlagamix("Cliente")}

                                                >
                                                    Seleccionada por el cliente
                                                </ToggleButton>
                                            </ButtonGroup>
                                        }

                                    </Col>

                                </Row>

                                {selectedByPlagamix ?
                                    <div>
                                        <Row className='d-block d-sm-none mt-3'>
                                            <Col>
                                                <Form.Text>Fecha</Form.Text>
                                            </Col>
                                        </Row>
                                        <Row className='mt-md-3'>
                                            <Col md={2} className='d-none d-sm-block align-center-vertically-v2'>
                                                Fecha
                                            </Col>
                                            <Col className='align-center-vertically'>
                                                <div>
                                                    <p className='m-0'><DatePicker className='form-control cover content rows element' locale="es" id='' showTimeSelect dateFormat="dd/MM/yyyy HH:mm" selected={date} onChange={(d) => handleDate(d)} /></p>


                                                </div>
                                            </Col>

                                        </Row>
                                        <Row className='mt-3'>
                                            <Col xs={2}>

                                            </Col>
                                            <Col>


                                                {appointmentsDate.length > 0 ?
                                                    <div style={{ border: '2px solid #F0F0F0', borderRadius: 8 }} className='w-100 pt-2'>
                                                        <span className='ps-2'>Citas del día</span>
                                                        <Container className='pt-3'>
                                                            {appointmentsDate.map((r, i) => (
                                                                <Row className='p-2 mb-3' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                                    <Col xs={1}>
                                                                        <img src={`${params().dataUri}${r.icon}`} width='70'></img>
                                                                    </Col>
                                                                    <Col xs={4} className='align-center-vertically'>
                                                                        <div>
                                                                            <p className='m-0' style={{ fontSize: 12 }}>{r.type}</p>
                                                                            <p className='m-0'>{r.customerName}</p>
                                                                            <p className='m-0' style={{ fontSize: 12 }}>{r.customerAddress} - {r.customerCity}</p>
                                                                        </div>

                                                                    </Col>
                                                                    <Col xs={2} className='align-center-vertically middle'>
                                                                        <div>
                                                                            <p className='m-0'>{r.day}</p>
                                                                            <p className='m-0'>{r.hour}</p>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs={2} className='align-center-vertically middle'>
                                                                        <div className='middle'>
                                                                            <p className='m-0'>{r.status}</p>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs={2} className='align-center-vertically middle'>
                                                                        <div>
                                                                            {r.users.map((u, i) => (
                                                                                <p className='m-0'>{u}</p>
                                                                            ))}
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs={1} className='align-center-vertically' style={{ textAlign: 'right' }}>
                                                                        <div>
                                                                            {r.action}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            ))}
                                                        </Container>
                                                    </div>
                                                    :
                                                    <div></div>

                                                }

                                            </Col>
                                        </Row>
                                    </div>
                                    :
                                    <Row className='mt-2'>
                                        <Col xs={2} className='d-none d-sm-block align-center-vertically-v2'>

                                        </Col>
                                        <Col>
                                            Al crear la cita, quedará pendiente de confirmación hasta que el cliente confirme la fecha de visita.
                                        </Col>
                                    </Row>
                                }

                            </Form>

                            <div className='mt-5' style={{ textAlign: 'right' }}>
                                <Button variant="success" onClick={createAppointment} disabled={!canNext}><FontAwesomeIcon icon={faCheck} style={{ marginRight: 8 }} />Crear cita</Button>

                            </div>

                        </Container>
                    </Card>
                </Row>
            </Container>


            <Modal show={showSearchCustomerModal} size='lg' onHide={handleCloseSearchCustomerModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Buscar cliente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" id="" autoFocus placeholder="Buscar..." onChange={(event) => handleSearchCustomers(event)} />
                    {customers.length > 0 ? <div></div> : <span></span>}
                    <ListGroup className='mt-2'>
                        {customers.map((c, i) => (
                            <ListGroup.Item key={i} onClick={() => handleCustomerSelected(c.id)}>{<div><p className='m-0' style={{ fontSize: 12 }}>{c.legalId ? `CUIT ${c.legalId} - ` : ""}{c.legalName}</p><p className='m-0'></p>{c.name}</div>}</ListGroup.Item>
                        ))}
                    </ListGroup>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSearchCustomerModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showSelectEmployeeModal} size='lg' onHide={handleCloseSelectEmployeeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Añadir encargado</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup className='mt-2'>
                        {employees.map((e, i) => (
                            <ListGroup.Item key={i} onClick={() => handleEmployeeSelected(e.id)}>
                                <Row>
                                    <Col xs={2} md={1}>
                                        <img className='avatar-10' src={`${params().dataUri}${e.avatar}`}></img>
                                    </Col>
                                    <Col className='align-center-vertically'>
                                        {e.name} {e.surname}
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSelectEmployeeModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>


        </div>
    )
}