import React, { useEffect } from 'react'
// eslint-disable-next-line
import { Routes, Route, Link } from "react-router-dom";
import { Container, Dropdown, Nav, Badge, Navbar, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faBell, faBurger, faCog, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import { colors } from '../../../tools/constants';
import { get, put } from '../../../controllers/endpoints.controller';
import { getUserRole, getUserToken, logout, forceLogout } from '../../../controllers/user.controller';
import { timeSince } from '../../../tools/tools';
import { params } from '../../../tools/constants'

export default function NavBar() {
    const [notifications, setNotifications] = React.useState([]);
    const [menus, setMenus] = React.useState([]);
    const [menusMobile, setMenusMobile] = React.useState([]);
    const [isMenuOpened, setMenuOpened] = React.useState(false);

    useEffect(() => {
        processMenus()
        // getMyNotifications()

    }, []);

    const handleMobileMenu = () => {
        setMenuOpened(!isMenuOpened);
    }

    const getMyNotifications = async function () {
        const req = await get(`/notifications`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setNotifications(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const setNotificationAsSeen = async function (notificationId, url) {
        const req = await put(`/notifications/seen`, getUserToken(), { notificationId: notificationId });
        const res = await req.json();

        if (req.status === 200) {
            window.location.href = url;
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processMenus = () => {
        let m = [];
        let mm = [];
        const role = getUserRole();

        if (role == "Administrador") {
            m = [{ text: "Inicio", link: "/inicio" },
            { text: "Clientes", link: "/clientes" },
            { text: "Calendario", link: "/calendario" },
            { text: "Citas", link: "/citas" },
            { text: "Hoja de ruta", link: "/hojaderuta" },
            { text: "Administración", link: "/administracion" },
            { text: "Comprobantes", link: "/usuarios/cuentaCorriente" },
            { text: "Configuración", link: "/sistema" },];

            mm = [{ text: "Inicio", link: "/inicio" },
            { text: "Clientes", link: "/clientes" },
            { text: "Calendario", link: "/calendario" },
            { text: "Citas", link: "/citas" },
            { text: "Hoja de ruta", link: "/hojaderuta" },
            { text: "Administración", link: "/administracion" },
            { text: "Comprobantes", link: "/usuarios/cuentaCorriente" },];
        } else if (role == "Empleado") {
            m = [{ text: "Inicio", link: "/inicio" },
            { text: "Calendario", link: "/calendario" },
            { text: "Citas", link: "/citas" },
            { text: "Hoja de ruta", link: "/hojaderuta" },
            { text: "Comprobantes", link: "/usuarios/cuentaCorriente" },]

            mm = [{ text: "Inicio", link: "/inicio" },
            { text: "Calendario", link: "/calendario" },
            { text: "Citas", link: "/citas" },
            { text: "Hoja de ruta", link: "/hojaderuta" },
            { text: "Comprobantes", link: "/usuarios/cuentaCorriente" },];
        } else if (role == "Cliente") {
            m = [{ text: "Inicio", link: "/inicio" },
            { text: "Obleas", link: "/obleas" },
            { text: "Citas", link: "/citas" }]

            mm = [{ text: "Inicio", link: "/inicio" },
            { text: "Obleas", link: "/obleas" },
            { text: "Citas", link: "/citas" }]
        }

        setMenus(m)
        setMenusMobile(mm)
    }

    const checkPage = function (key) {
        const actualUri = window.location.pathname.split('/')[1];
        const actualMenu = menus[key].link.split('/')[1];

        if (actualUri === actualMenu) {
            return (
                <Link to={menus[key].link} className="navbar-menu-active pe-3 ps-3">{menus[key].text}</Link>
            )
        } else {
            return (
                <Link to={menus[key].link} className="navbar-menu-not-active pe-3 ps-3">{menus[key].text}</Link>
            )
        }
    };

    const checkMobilePage = function (key) {
        const actualUri = window.location.pathname.split('/')[1];
        const actualMenu = menus[key].link.split('/')[1];

        if (actualUri === actualMenu) {
            return (
                <Link to={menus[key].link} className="navbar-menu-active w-100 pe-3 ps-3">{menus[key].text}</Link>
            )
        } else {
            return (
                <Link to={menus[key].link} className="navbar-menu-not-active w-100 pe-3 ps-3">{menus[key].text}</Link>
            )
        }
    };

    const goToMyProfile = function () {
        window.location.href = "/perfil"
    };

    return (
        <div style={{ marginBottom: 125 }}>
            <Navbar fixed="top" className="pb-2" style={{ background: "white" }}>
                <Container>
                    <Navbar.Brand>
                        <img
                            src="/logo_black.png"
                            height="40"
                            className="d-inline-block align-top"
                            alt="Plagamix"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                        

                        <Navbar.Text className='d-none d-sm-block'>
                            <Dropdown>
                                <Dropdown.Toggle variant="info" id="dropdown-basic" style={{ background: `${colors().brand_primary}`, borderColor: `${colors().brand_primary}`, zIndex: '9' }}>
                                    <img className='avatar-10' src={`${params().dataUri}${localStorage.getItem('userAvatar')}`} alt=""></img>
                                    <span className="ms-3 me-3">{`${localStorage.getItem('userName')} ${localStorage.getItem('userSurname')}`}</span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={goToMyProfile}><FontAwesomeIcon icon={faUser} style={{ marginRight: 10 }} />Mi perfil</Dropdown.Item>
                                    {getUserRole() != "Cliente" ? <Dropdown.Item ><FontAwesomeIcon icon={faCog} style={{ marginRight: 8 }} />Configuración</Dropdown.Item> : ""}
                                    <Dropdown.Item onClick={logout}><FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: 9 }} />Salir</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Navbar.Text>

                        <Navbar.Text className='d-block d-sm-none'>

                           <Button variant='warning' onClick={handleMobileMenu}><FontAwesomeIcon style={{ fontSize:25 }} icon={faBars}></FontAwesomeIcon></Button>


                        </Navbar.Text>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Navbar className='d-none d-sm-block' fixed="top" style={{ background: `${colors().brand_primary}`, marginTop: 85, zIndex: '10', height: 41 }}>
                <Container>
                    <Nav className="flex-grow-1 justify-content-center pb-0">
                        {menus.map((menu, i) => (
                            <div key={i}>{checkPage(i)}</div>
                        ))}
                    </Nav>
                </Container>
            </Navbar>

            {isMenuOpened ?
                <div className='d-block d-sm-none' style={{position: 'fixed', top: '74px', width: '100%', background: `${colors().brand_primary}`, zIndex: '10' }}>
                <Container className='pt-3 pb-3'>
                    {menusMobile.map((menu, i) => (
                        <Row>{checkPage(i)}</Row>
                    ))}
                </Container>
            </div>
            :
                <div className='d-block d-sm-none' style={{marginBottom: -50}}></div>
            }
        </div>
    )
}